import styled from 'styled-components';
/*
  KeynoteContElements.js 컴포넌트 사용 중, 클래스명 중복주의
*/
export const SessionTopWrap = styled.div`
  .KeynoteContLink {
    display: none;
  }
`;

export const SessionTopUtil = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 144px auto 24px;
  padding: 10px 40px;

  .link-prev {
    position: relative;
    padding-left: 32px;
    font-family: 'samsungOne-700';
    font-size: 20px;
    line-height: 1.3;
    color: #000;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-previous.svg`});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 80px auto 12px;
    padding: 4px 24px;

    .link-prev {
      display: flex;
      align-items: center;
      padding-left: 26px;
      font-size: 16px;
      line-height: 20px;
      &:before {
        content: '';
        top: 2px;
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const SessionReserve = styled.button`
  display: flex;
  align-items: center;
  font-family: 'samsungOne-700';
  font-size: 20px;
  line-height: 1.3;
  color: #2088fd;

  span {
    padding-left: 8px;
  }

  img {
    dipslay: block;
  }

  @media screen and (max-width: 768px) {
    span {
      display: none;
    }
  }
`;

export const SessionDetailTopInner = styled.div`
  /* KeynoteContElement */
  .KeynoteCont {
    padding-top: 6px;
  }

  .KeynoteContInner {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px) {
    }
  }

  /* video */
  .KeynoteContVideo {
    width: 100%;
    height: 100%;

    @media screen and (max-width: 768px) {
      margin-top: 0;
      margin-bottom: 12px;
    }
  }

  /* text */
  .KeynoteContText {
    margin-top: 40px;

    &.is-session {
      .bookmark_tooltip {
        right: 50px;
      }
    }
    @media screen and (max-width: 1370px) {
      &.is-session {
        .bookmark_tooltip {
          right: 10px;
          transform: none;
          &:after {
            left: auto;
            right: 15px;
            transform: none;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      /* session page */
      &.is-session {
        .bookmark_tooltip {
          right: auto;
          left: 40px;
          &:after {
            left: 40px;
            right: auto;
            transform: none;
          }
        }
      }
    }
  }

  /* title */
  .KeynoteContTitle {
    flex-wrap: wrap;
    justify-content: space-between;
    .title {
      max-width: 90%;
      font-size: 42px;
    }
    .btn {
      display: flex;
      align-self: flex-start;
      margin-top: 0;
      button {
        margin-left: 10px;
      }
    }
    @media screen and (max-width: 768px) {
      .btn {
        margin-top: 17px;
        padding-right: 10px;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0px;
          right: 1px;
          width: 1px;
          height: 26px;
          background-color: #efefef;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: -7px;
          right: -21px;
          width: 16px;
          height: 50px;
          background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
          );
          z-index: 1;
        }
        button {
          width: 26px;
          height: 26px;
          margin-left: 6px;
          &:first-of-type {
            margin-left: 0;
          }
        }
      }

      .title {
        width: 100%;
        max-width: 100%;
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  /* desc */
  .KeynoteContDesc {
    display: none;
  }
  .SessionDesc {
    margin-top: 24px;
    // update more button
    // display: -webkit-box;
    // -webkit-line-clamp: 3;
    // -webkit-box-orient: vertical;
    // text-overflow: ellipsis;
    // overflow: hidden;
    display: block;
    line-height: 24px;
    color: #1e1e1e;

    &.is-more {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &.is-appended {
      height: auto;
      overflow: initial;
      text-overflow: initial;
      display: block;
      -webkit-line-clamp: none;
      -webkit-box-orient: vertical;
    }

    @media screen and (max-width: 768px) {
      // update more button
      // -webkit-line-clamp: 9;
      line-height: 20px;
      margin-top: 20px;
    }
  }

  .KeynoteContTag {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    padding: 8px 16px;
    font-family: 'SamsungOne-600';
    color: #7c7c7c;
    background-color: #efefef;
    border-radius: 18px;
    @media screen and (max-width: 768px) {
      white-space: nowrap;
      -webkit-flex: 0 1 auto;
      margin-top: 0;
      margin-bottom: 0;
      padding: 6px 12px;
      line-height: 20px;
    }
  }
`;

export const SessionRoomLink = styled.div`
  a {
    display: inline-block;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 36px;
    padding: 16px 36px;
    font-family: 'SamsungOne-600';
    font-size: 18px;
    color: #1e1e1e;
    line-height: 24px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 32px;
    backdrop-filter: blur(16px);
    transition: 0.2s;

    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }
  @media screen and (max-width: 768px) {
    a {
      margin-top: 16px;
      padding: 8px 16px;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const SessionEventInfo = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 5px;
  .info {
    font-family: 'SamsungOne-700';
    color: #7c7c7c;
    line-height: 20px;

    &.time {
      position: relative;
      padding-right: 17px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        right: 8px;
        width: 1px;
        height: 12px;
        background-color: #ddd;
      }
    }
  }

  @media screen and (max-width: 768px) {
    right: auto;
    left: 0;
    bottom: 50px;
  }
`;
