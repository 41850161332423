import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  FilterContainer,
  FilterDim,
  FilterInner,
  FilterTop,
  FilterTitle,
  FilterClose,
  FilterClearWrap,
  FilterClear,
  FilterCont,
  FilterItem,
  FilterItemTitle,
  FilterItemCont,
} from './FilterElements';
import ButtonFilter from '../ButtonFilter';

import {
  CLICK_FILTER_CLEAR,
  GET_SESSION_LIST_REQUEST,
} from '../../redux/ActionTypes';

const SessionTypeFilter = {
  Mega: 'Mega Session',
  Tech: 'Tech Session',
  'Open Theater': 'Open Theater',
};

const SessionMainFilter = {
  'Platform Innovation': 'Platform Innovation',
  'Mobile Experience': 'Mobile Experience',
  'Screen Experience': 'Screen Experience',
  'Home Experience': 'Home Experience',
  'Health Experience': 'Health Experience',
  'Sustainability / Innovation': 'Sustainability / Innovation',
};

const SessionTopicFilter = {
  AI: 'AI',
  Android: 'Android',
  // AR: 'AR',
  // Connectivity: 'Connectivity',
  'Contents & Service': 'Contents&Service',
  // Data: 'Data',
  Design: 'Design',
  'Developer Program': 'Developer Program',
  'Digital Appliances': 'Digital Appliances',
  ESG: 'ESG',
  Enterprise: 'Enterprise',
  Game: 'Game',
  Health: 'Health',
  IoT: 'IoT',
  Mobile: 'Mobile',
  Monetization: 'Monetization',
  // 'Open Innovation': 'Open Innovation',
  'Open Source': 'Open Source',
  Productivity: 'Productivity',
  // 'Security & Privacy': 'Security & Privacy',
  // 'Smart Appliances': 'Smart Appliances',
  SmartTV: 'SmartTV',
  Tizen: 'Tizen',
  'UI/UX': 'UI/UX',
  // UWB: 'UWB',
  Wearable: 'Wearable',
  // Web: 'Web',
};

const Filter = ({ setOpen }) => {
  const [sessionTypeOpen, setSessionTypeOpen] = useState(true);
  const [topicsOpen, setTopicsOpen] = useState(true);
  const [MainCateOpen, setMainCateOpen] = useState(true);

  const closeFilter = () => {
    setOpen(false);
    document.body.style.overflow = 'auto';
  };

  const toggleSessionType = () => {
    setSessionTypeOpen(!sessionTypeOpen);
  };

  const toggleTopics = () => {
    setTopicsOpen(!topicsOpen);
  };

  const toggleMainCategories = () => {
    setMainCateOpen(!MainCateOpen);
  };

  const checkedTypes = useSelector((state) => state.sessionFilter.checkedTypes);
  const checkedTopics = useSelector(
    (state) => state.sessionFilter.checkedTopics
  );
  const checkedMainCategories = useSelector(
    (state) => state.sessionFilter.checkedMainCategories
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const clearHandler = () => {
    setSearchParams();
    dispatch({ type: CLICK_FILTER_CLEAR });
    dispatch({ type: GET_SESSION_LIST_REQUEST, query: [] });
  };

  useEffect(() => {
    // componentWillUnmount
    return () => {
      closeFilter();
    };
  }, []);

  return (
    <FilterContainer>
      <FilterDim />
      <FilterInner>
        <FilterTop>
          <FilterTitle>Filter</FilterTitle>
          <FilterClose onClick={closeFilter} />
        </FilterTop>
        <FilterCont>
          <FilterItem>
            <FilterItemTitle>
              Session Type
              <span className="count">
                <em>{checkedTypes.length}</em>
                {`/${Object.keys(SessionTypeFilter).length}`}
              </span>
              <button
                className={`more-btn ${sessionTypeOpen ? '' : 'close'}`}
                type="button"
                onClick={toggleSessionType}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/ico-arrow-black.svg`}
                  alt="arr"
                />
              </button>
            </FilterItemTitle>
            <FilterItemCont className={sessionTypeOpen ? '' : 'close'}>
              {Object.keys(SessionTypeFilter).map((item) => (
                <ButtonFilter
                  key={item}
                  item={item}
                  title={SessionTypeFilter[item]}
                />
              ))}
            </FilterItemCont>
          </FilterItem>
          <FilterItem>
            <FilterItemTitle>
              Main Categories
              <span className="count">
                <em>{checkedMainCategories.length}</em>
                {`/${Object.keys(SessionMainFilter).length}`}
              </span>
              <button
                className={`more-btn ${MainCateOpen ? '' : 'close'}`}
                type="button"
                onClick={toggleMainCategories}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/ico-arrow-black.svg`}
                  alt="arr"
                />
              </button>
            </FilterItemTitle>
            <FilterItemCont className={MainCateOpen ? '' : 'close'}>
              {Object.entries(SessionMainFilter).map(([key, value]) => (
                <ButtonFilter
                  key={key}
                  item={key}
                  title={SessionMainFilter[value]}
                />
              ))}
            </FilterItemCont>
          </FilterItem>
          <FilterItem>
            <FilterItemTitle>
              Topics
              <span className="count">
                <em>{checkedTopics.length}</em>
                {`/${Object.keys(SessionTopicFilter).length}`}
              </span>
              <button
                className={`more-btn ${topicsOpen ? '' : 'close'}`}
                type="button"
                onClick={toggleTopics}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/ico-arrow-black.svg`}
                  alt="arr"
                />
              </button>
            </FilterItemTitle>
            <FilterItemCont
              className={`topic-filter ${topicsOpen ? '' : 'close'}`}
            >
              {Object.keys(SessionTopicFilter).map((item) => (
                <ButtonFilter
                  key={item}
                  item={item}
                  title={SessionTopicFilter[item]}
                />
              ))}
            </FilterItemCont>
          </FilterItem>
        </FilterCont>
        <FilterClearWrap>
          <FilterClear
            className={
              checkedTypes.length +
                checkedTopics.length +
                checkedMainCategories.length ===
              0
                ? 'disabled'
                : null
            }
            onClick={
              checkedTypes.length +
                checkedTopics.length +
                checkedMainCategories.length ===
              0
                ? null
                : clearHandler
            }
          >
            Clear All
            {checkedTypes.length +
              checkedTopics.length +
              checkedMainCategories.length !==
            0 ? (
              <span className="count">
                (
                {checkedTypes.length +
                  checkedTopics.length +
                  checkedMainCategories.length}
                )
              </span>
            ) : null}
          </FilterClear>
        </FilterClearWrap>
      </FilterInner>
    </FilterContainer>
  );
};

export default Filter;
