import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import {
  MainJoinusCard,
  MainJoinusCardDesc,
  MainJoinusCardTitle,
  MainJoinusCardWrap,
  MainJoinusContainer,
  MainJoinusInner,
} from './MainJoinusElement';
import MainSectionTitle from '../MainSectionTitle';

const MainJoinusCardItem = (props) => {
  const { className, title, desc, bgUrl } = props;
  return (
    <MainJoinusCard className={`MainJoinusCard ${className}`}>
      <MainJoinusCardTitle>{title}</MainJoinusCardTitle>
      <MainJoinusCardDesc className="desc">{desc}</MainJoinusCardDesc>
    </MainJoinusCard>
  );
};

const MainJoinus = () => {
  const mainRef = useRef();
  const q = gsap.utils.selector(mainRef);
  const tl = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      '(min-width: 769px)': function () {
        tl.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: mainRef.current,
              scrub: 0.5,
              start: '35% 75%',
              end: '40% center',
            },
          })
          .fromTo(
            q('.item-01'),
            { opacity: 0, y: 50 },
            { opacity: 1, y: 0, duration: 0.5 }
          )
          .fromTo(
            q('.item-02'),
            { opacity: 0, y: 50 },
            { opacity: 1, y: 0, duration: 0.5 },
            '-=0.2'
          )
          .fromTo(
            q('.item-03'),
            { opacity: 0, y: 50 },
            { opacity: 1, y: 0 },
            '-=0.2'
          );
      },
      '(max-width: 768px)': function () {
        tl.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: mainRef.current,
              scrub: 0.5,
              start: '35% 75%',
              end: '40% center',
            },
          })
          .fromTo(q('.item-01'), { opacity: 0 }, { opacity: 1 })
          .fromTo(q('.item-02'), { opacity: 0 }, { opacity: 1 })
          .fromTo(q('.item-03'), { opacity: 0 }, { opacity: 1 });
      },
    });

    return () => {
      tl.current.kill();
    };
  }, [q]);
  return (
    <MainJoinusContainer ref={mainRef}>
      <MainSectionTitle
        title={
          <>
            Join Us at SDC 2024: <br />
            Unleash Innovation and Connect!
          </>
        }
        desc={
          <div>
            Experience the latest in Samsung's tech, including AI topics, at SDC
            2024 at the San Jose <br />
            Convention Center. Enhance your skills with exhibits and sessions,
            and seize networking <br />
            opportunities through engagement programs with industry leaders.
          </div>
        }
      />
      <MainJoinusInner>
        <MainJoinusCardWrap>
          <MainJoinusCardItem
            className="item-01"
            title={
              <>
                Discover Samsung's <br />
                Latest Tech
              </>
            }
            desc="Explore impactful topics, including cutting-edge AI innovations."
          />
          <MainJoinusCardItem
            className="item-02"
            title={
              <>
                Learning <br className="hidden-m" />
                Opportunity
              </>
            }
            desc="Level up your skills and learn something new through interactive exhibits and sessions with experts."
          />
          <MainJoinusCardItem
            className="item-03"
            title={
              <>
                Powerful <br className="hidden-m" />
                Networking
              </>
            }
            desc="Connect with industry professionals through diverse day-and-night engagement programs."
          />
        </MainJoinusCardWrap>
      </MainJoinusInner>
    </MainJoinusContainer>
  );
};

export default MainJoinus;
