import * as types from '../ActionTypes';

const initialState = {
  data: null,
  motion: null,
};

const BookmarkReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BOOKMARK_LIST: {
      return { ...state, data: [...action.data], motion: null };
    }
    case types.ADD_BOOKMARK_REQUEST: {
      return { ...state, error: null, motion: null };
    }
    case types.ADD_BOOKMARK_SUCCESS: {
      return {
        ...state,
        data: [...action.data, ...(state.data || [])],
        motion: 'add',
      };
    }
    case types.ADD_BOOKMARK_ERROR: {
      return { ...state, error: action.error };
    }
    case types.DELETE_BOOKMARK_REQUEST: {
      return { ...state, error: null, motion: null };
    }
    case types.DELETE_BOOKMARK_SUCCESS: {
      return { ...state, data: [...action.data], motion: 'delete' };
    }
    case types.DELETE_BOOKMARK_ERROR: {
      return { ...state, error: action.error };
    }
    case types.RESET_BOOKMARK_MOTION: {
      return { ...state, motion: null };
    }
    default:
      return state;
  }
};

export default BookmarkReducer;
