import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import DetailHero from '../../components/DetailHero';
import SupportFaq from '../../components/SupportCont';

const Support = () => {
  return (
    <>
      <DetailContainer>
        <DetailHero
          bgPc="sdc23-bg-support.png"
          bgMo="sdc23-bg-support-mo.png"
          bgColor="#2088FD"
          title="Support"
          path="Support"
        />
        <SupportFaq />
      </DetailContainer>
    </>
  );
};

export default Support;
