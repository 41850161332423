import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const MainKeynoteArea = styled.div`
  padding: 0 0 148px 0;
  font-size: 14px;
  @media screen and (max-width: 768px) {
    padding: 0 0 72px;
  }
`;

export const LinkArea = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  &.is-DesktopSlide {
    margin-top: 20px;
  }
  @media screen and (max-width: 1024px) {
    margin-top: 40px;
    &.is-DesktopSlide {
      margin-top: 40px;
    }
  }
`;

export const CardWrap = styled.div`
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const CardInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &.is-DesktopSlide {
    display: block;
    .slick-slide {
      /* padding: 0 0 40px 0; */
    }
    .slick-slider {
      padding: 0;
    }
  }
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
