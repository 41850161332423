import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
  }
`;

export const RegisterBtnContainer = styled.div`
  position: fixed;
  bottom: 24px;
  right: 32px;
  z-index: 90;
  opacity: 1;
  transition: 0.3s;

  &.none {
    opacity: 0;
    transition: 0.3s;
  }

  &.active {
    .RegisterLink {
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.20);
      transition: 4.1s;

      &.is-LiveLink {
        &.has-dot {
          &:before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            position: absolute;
            top: 50%;
            left: 36px;
            transform: translateY(-50%);
            background-color: #FA5347;
            border-radius: 100%;
            visibility: hidden;
            z-index: 2;
            animation: ${blink} 2s infinite;
            animation-delay: 1s;
          }
        }

      }
    }
  }

  &.complete {
    transition: all 0.3s;
    .RegisterLink {
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.20);
      &.is-LiveLink {
        &.no-dot {
          height: 67.9px;
          border-radius: 35px;

          .registerBtnSVGBase {
            top: -26px;
          }
        }
      }

      &.is-LiveLink {
        &.has-dot {
          &:before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            position: absolute;
            top: 50%;
            left: 36px;
            transform: translateY(-50%);
            background-color: #FA5347;
            border-radius: 100%;
            visibility: hidden;
            z-index: 4;
            animation: ${blink} 2s infinite;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    &.active {
      .RegisterLink {
        &.is-LiveLink {
          &:before {
            margin-top: -1px;
            left: 32px;
            width: 6px;
            height: 6px;
          } 
        }
      }
    }
    &.complete {
      .RegisterLink {
        &.is-LiveLink {
          &.no-dot {
            width: 154px;
            height: 56px;
            .registerBtnSVGBase {
              top: -18px;
            }
          }
        }
      }
    }
  }

    &.complete {
      .RegisterLink {
        &.is-LiveLink {
          &:before {
            margin-top: -1px;
            left: 32px;
            width: 8px;
            height: 8px;
          } 
        }
      }
    }

  }
  /* live 전 - Register Link / live 시 - Watch Live Link */
  .RegisterLink {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 174px;
    height: 64px;
    margin-top: 22px;
    font-family: 'samsungsharpsans-bold';
    font-size: 20px;
    color: #fff;
    border-radius: 32px;
    svg{
      margin-top: 20px;
    }

    &.is-LiveLink {
      width: 194px;
    }

    @media screen and (min-width: 769px) {
      &:hover {
        svg  g  g  g:nth-of-type(1) {
            g  path {
              fill: #2088FD;
              transition: 0.2s;
            }
          }
          svg  g  g  g:nth-of-type(2) {
            g  path {
              fill: #fff;
              transition: 0.2s;
            }
          }
        }

        &.is-LiveLink {
          &:hover {
            svg  g  g  g:nth-of-type(1) {
                g  path {
                  fill: #FA5347;
                  transition: 0.2s;
                }
              }
              svg  g  g  g:nth-of-type(2) {
                g  path {
                  fill: #fff;
                  transition: 0.2s;
                }
              }

              &:before {
                content: '';
                background-color: #fff;
              }
            }
        }
    }

      .registerBtnSVG {
        position: relative;
        margin-top: 17px;
        z-index: 3;
      }

      .registerBtnSVGBase {
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        top: -23px;
        z-index: 0;
      }

      @media screen and (max-width: 768px) {
        width: 154px;
        height: 56px;

        &.is-LiveLink {
          width: 174px;
        }
      }
  }

    .registerBtnSVG  {
      display: block;
      width: 100%;
      transition: 0.3s;
      opacity: 0; /* 이미지 숨기기 */
      transition: opacity 0.3s ease;

      @media screen and (min-width: 769px) {
        &:hover{
          opacity: 1;
        }
      }
    }

  }

  @media screen and (max-width: 1024px) {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    bottom: 16px;
  }
`;
