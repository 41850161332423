import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import {
  SessionTopWrap,
  SessionDetailTopInner,
  SessionTopUtil,
  SessionReserve,
} from './SessionDetailTopContElement';
import ButtonLink from '../ButtonLink';
import DetailContainer from '../DetailContainer';
import KeynoteCont from '../KeynoteCont';
import Popup from '../Popup';
import Button from '../Button';

import {
  SESSION_READY,
  SESSION_OPEN,
  SESSION_CLOSED,
  SESSION_ON_DEMAND,
  SESSION_REPLAY,
} from '../../data/status';

import { ChapterData } from '../../data/ChapterData';

// function diffTime(current, date) {
//   const startTime = moment(date);
//   if (startTime.isAfter(current) || startTime.isSame(current)) {
//     return true;
//   }
//   return false;
// }

const SessionDetailTop = () => {
  const { data } = useSelector((state) => state.sessionDetail);

  const [modal, setModal] = useState(false);
  // confirm popup
  const popupHandler = (target) => {
    setModal(true);
  };

  const mega = useSelector((state) => state.meta.data.mega);
  const onDemand = useSelector((state) => state.meta.data['on-demand']);
  const phase = useSelector((state) => state.meta.data.phase);
  const megaChapter = useSelector((state) => state.meta.data.megaChapter);

  const {
    PresentationTitle,
    AbstractText,
    PresentationDateTimeStart,
    PresentationDateTimeEnd,
    SessionType,
    category,
    mainCategory,
    image,
    videoId,
    PresentationID,
    onDemandURL,
    PresentationRoom,
    status,
  } = data;

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <SessionTopWrap>
        <SessionDetailTopInner>
          <DetailContainer>
            <SessionTopUtil>
              <button type="button" className="link-prev" onClick={goBack}>
                Back to Previous Page
              </button>
            </SessionTopUtil>
            {/*
            desc use <p></p> tag
          */}
            {/*
            if released true, type=VOD
          */}
            <KeynoteCont
              title={PresentationTitle}
              desc={AbstractText?.replace(
                /(?:\\[rn]|[\r\n]+)+/g,
                '<br/>'
              ).replace(/\u00a0/gi, ' ')}
              tag={
                category && mainCategory
                  ? [mainCategory, ...category]
                  : mainCategory
                  ? [mainCategory]
                  : null
              }
              session
              type={SessionType}
              startDate={PresentationDateTimeStart}
              // picture={image}
              url={onDemandURL}
              sessionData={data}
              beforeLive={SessionType === 'Mega' && status === 'prepare live'}
              released={
                mega?.videoStatus === SESSION_OPEN &&
                SessionType === 'Mega' &&
                status === 'live'
              }
              // 세션 스케줄 확정 이후 해당 시작 시각으로 변경
              // time="14:10 PM PT"
              /* if it's after the live and before uploading the video -> true */
              // uploadedBefore={
              //   SessionType && SessionType === 'Mega'
              //     ? mega?.videoStatus === SESSION_CLOSED ||
              //       mega?.videoStatus === SESSION_REPLAY
              //     : onDemand?.videoStatus === SESSION_READY
              // }
              uploadedBefore={
                SessionType && SessionType === 'Mega'
                  ? status === 'done' &&
                    !(mega?.videoStatus === SESSION_ON_DEMAND && onDemandURL)
                  : phase && phase === 'phase4'
                  ? onDemand?.videoStatus === SESSION_OPEN && !onDemandURL
                  : phase === 'phase3' &&
                    (!onDemand?.videoStatus ||
                      onDemand?.videoStatus === SESSION_READY)
              }
              onDemand={
                SessionType && SessionType === 'Mega'
                  ? mega?.videoStatus === SESSION_ON_DEMAND && onDemandURL
                  : onDemand?.videoStatus === SESSION_OPEN
                  ? onDemandURL
                  : ''
              }
              infoTime={`${moment(PresentationDateTimeStart).format('HH:mm')} - 
                ${moment(PresentationDateTimeEnd).format('HH:mm')}`}
              infoPlace={
                SessionType && SessionType.toLowerCase().indexOf('open') > -1
                  ? 'Open Theater'
                  : PresentationRoom
              }
              status={status}
              room="<a href=/mega-session>Go to Live Room</a>"
              live={status === 'live'}
              sessionStatus={mega?.videoStatus}
              chapterData={
                megaChapter && SessionType === 'Mega'
                  ? megaChapter
                      .filter((x) => Object.keys(x)[0] === PresentationID)
                      .map((x) => x[PresentationID])[0]
                  : undefined
              }
            />
          </DetailContainer>
        </SessionDetailTopInner>
      </SessionTopWrap>
      {/*
       * Session Reservation popup
       */}
      {/* Confirmed popup */}
      {modal ? (
        <Popup
          modal={modal}
          setModal={setModal}
          title="Confirmed"
          desc="Added to session reservation history."
          linkText="Go to Reservation Details"
          link="/#/mypage"
          linkBlock
          btnText="Confirm"
        />
      ) : null}
      {/* Alert Registration */}
      {/*
          modal
          ? <Popup
              modal={modal}
              setModal={setModal}
              title="Alert Registration"
              desc="You can reserve a session after completing the registration form."
              linkText="Go to Registration"
              link="/#/Registration"
              linkBlock
              btnText="Confirm"
            />
          : null
          */}
      {/* Alert Sign in popup */}
      {/*
              {
                modal
                ? <Popup
                    modal={modal}
                    setModal={setModal}
                    title="Alert Sign in"
                    desc="You'll have to sign in to do that."
                    linkText="Sign in"
                    link="https://developer.samsung.com/dashboard/support"
                    linkBlock
                    btnText="Confirm"
                  />
                : null
              }
           */}
      {/* after resevationp */}
      {/*
          modal
          ? <Popup
              modal={modal}
              setModal={setModal}
              title="Confirmed"
              desc="When you select the Confirm button, The scheduled session history is deleted."
              link=""
              linkBlock="false"
              btnText="Confirm"
            />
          : null
          */}
    </>
  );
};

export default SessionDetailTop;
