import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import moment from 'moment-timezone';
import * as types from '../ActionTypes';
import { Get } from '../../utils/Requester';
import { diffTime } from '../../utils/Functions';
import { getMetaLive } from './MetaSaga';
import meta from '../../sample/p3_meta.json';

export function* getSessionRoomInfo(action) {
  try {
    // get gapTime
    yield call(getMetaLive);

    const { phase, mega } = yield select((state) => state.meta.data);

    const gapTime = yield select((state) => state.meta.gapTime);
    const d =
      gapTime === 0 ? moment.utc().valueOf() : moment.utc().valueOf() - gapTime;

    // get current time
    const currentTime = moment
      .tz(d, 'America/Los_Angeles')
      .format('YYYY-MM-DD[T]HH:mm:ss');

    if (mega?.liveBadgeStart && mega?.liveBadgeEnd) {
      const { status, diff } = diffTime(
        currentTime,
        mega?.liveBadgeStart,
        mega?.liveBadgeEnd
      );
      yield put({
        type: types.SESSION_ROOM_STATUS_UPDATE,
        roomStatus: status,
      });

      if (phase === 'phase3' && diff !== -1) {
        yield delay(diff);
        yield call(getSessionRoomInfo);
      }
    }
  } catch (error) {
    if (error) {
      console.warn('error', error);
    }
  }
}

export function* mainStatusUpdate() {
  // CHECK: NEED TO THIS CODE
  // const { phase } = yield select((state) => state.meta.data);
  // if (phase === 'phase2') {
  //   const data = yield call(getMetaLive);
  // }

  if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
    return yield put({
      type: types.MAIN_STATUS_UPDATE,
      data: {
        keynoteLive: meta.keynoteLive || 'prepare live',
        sessionLive: meta.sessionLive || 'prepare live',
      },
    });
  }

  const { phase, keynote, mega } = yield select((state) => state.meta.data);

  const gapTime = yield select((state) => state.meta.gapTime);

  const d = gapTime === 0 ? new Date() : new Date().getTime() - gapTime;

  const currentTime = moment
    .tz(d, 'America/Los_Angeles')
    .format('YYYY-MM-DD[T]HH:mm:ss');

  const statusData = [
    {
      startTime: keynote?.liveBadgeStart,
      endTime: keynote?.liveBadgeEnd,
    },
    {
      startTime: mega?.liveBadgeStart,
      endTime: mega?.liveBadgeEnd,
    },
  ];

  let keynoteLive;
  let sessionLive;
  const diffAry = [];

  if (phase === 'phase3') {
    statusData.forEach((x, i) => {
      const { status, diff } = diffTime(currentTime, x.startTime, x.endTime);
      if (i === 0) {
        keynoteLive = status;
      } else if (i === 1) {
        sessionLive = status;
      }

      if (diff !== -1) {
        diffAry.push(diff);
      }
    });
  }

  yield put({
    type: types.MAIN_STATUS_UPDATE,
    data: {
      keynoteLive: keynoteLive || 'prepare live',
      sessionLive: sessionLive || 'prepare live',
    },
  });

  if (diffAry.length > 0) {
    yield delay(Math.min(...diffAry));
    yield call(mainStatusUpdate);
  }
}

export default function* rootSaga() {
  // yield takeLatest(types.GET_SESSION_ROOM_REQUEST, getSessionRoomInfo);
  yield takeLatest(types.GET_MAIN_STATUS_REQUEST, mainStatusUpdate);
}
