import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const MapContainer = styled.div`
  position: relative;
  max-width: 1200px;
  padding: 100px 0 110px 0;

  @media screen and (max-width: 768px) {
    padding: 36px 0 76px;
    overflow-x: hidden;
  }
`;

export const MapWrap = styled.div`
  position: relative;
  display: flex;
  border-radius: 36px;
  background: #f6f6f6;
  height: auto;
  max-height: 675px;
  padding: 17px 61px 18px 61px;

  @media screen and (max-width: 768px) {
    display: block;
    padding: 25px 16px 16px 16px;
    height: auto;
    border-radius: 16px;
    max-height: none;
  }
`;

export const MapTabList = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
    margin-bottom: 16px;
    border-bottom: 1px solid #eaeaea;
    margin-top: 0;
  }
`;
export const MapTab = styled.div`
  width: 100%;
  margin: 16px 0;
  font-family: 'samsungsharpsans-bold';
  font-size: 42px;
  line-height: 1.28;
  color: #adadad;
  text-align: center;
  cursor: pointer;
  padding-top: 2px;

  &:last-child {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background-color: #c4c4c4;
      width: 100%;
      height: 1px;
      left: 0;
      top: -16px;
    }
  }

  &.is-selected {
    font-family: 'samsungsharpsans-bold';
    color: #000;
  }
  &:hover:not(&.is-selected) {
    border-radius: 8px;
    background-color: #eaeaea;
  }

  @media screen and (max-width: 768px) {
    width: auto;
    margin: 0px 14px;
    font-size: 24px;
    line-height: 1.33;
    padding-top: 0;

    &:last-child {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        background-color: #c4c4c4;
        width: 1px;
        height: 20px;
        left: -14px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &:hover:not(&.is-selected) {
      background-color: transparent;
    }
  }
`;

export const MapImage = styled.div`
  height: auto;
  padding: 0 59px;
  margin: 0 auto;

  .MapImageBox {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 800px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 1200px) {
    height: 90%;
  }

  @media screen and (max-width: 768px) {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 0;

    .MapImageBox {
      max-width: none;
    }

    .reset-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      display: inline-block;
      width: 40px;
      height: 40px;
      background: rgba(0, 0, 0, 0.06);
      backdrop-filter: blur(9px);
      border-radius: 40px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 40px;
        height: 40px;
        background-image: url(${`${process.env.PUBLIC_URL}/images/ico-reset.svg`});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      &.is-active {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
`;

export const MapImageBox = styled.div`
  .react-transform-wrapper {
    width: 100% !important;
    height: 100% !important;
  }

  @media screen and (max-width: 768px) {
    .map-img {
      width: 100%;
      height: 100%;
      padding-top: 80px;
      padding-bottom: 144px;
    }
  }
`;

export const MapListWrap = styled.div`
  width: calc(100% - 48px) !important;
  padding: 8px 24px;
  background: #f6f6f6;
  border-radius: 16px;
  margin-top: 28px;

  @media screen and (max-width: 768px) {
    padding: 16px 24px;
    border-radius: 10px;
    margin-top: 20px;
  }
`;

export const MapListCont = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .baseDesc {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  ul {
    width: calc(50% - 12px);

    // Common & Basement Floor
    .map-item {
      position: relative;
      padding: 24px 0;
      color: #535353;
      font-family: 'SamsungOne-700';
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
      padding-left: 52px;
      text-align: left;

      &:before {
        content: '';
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 6px;
        background: #1259aa;
        left: 16px;
        text-align: center;
        font-family: 'SamsungOne-700';
        font-size: 12px;
        line-height: 100%;
        color: #fff;
      }
    }
    li + li {
      border-top: 1px solid rgba(0, 0, 0, 0.08);
    }

    &.le > .map-item {
      &.ic-01 {
        &:before {
          content: '1';
        }
      }
      &.ic-02 {
        &:before {
          content: '2-1';
        }
      }
      &.ic-03 {
        &:before {
          content: '2-2';
        }
      }
      &.ic-04 {
        &:before {
          content: '2-3';
        }
      }
      &.ic-05 {
        &:before {
          content: '2-4';
        }
      }
      &.ic-06 {
        &:before {
          content: '3';
          background: #ffae34;
        }
      }
      &.ic-07 {
        &:before {
          content: '4';
          background: #756bf1;
        }
      }
      &.ic-08 {
        &:before {
          content: '5';
          background: #96d452;
        }
      }
      &.ic-09 {
        &:before {
          content: '6';
          background: #2088fd;
        }
      }
      &.ic-10 {
        &:before {
          content: '7';
          background: #2088fd;
        }
      }
    }

    &.ri > .map-item {
      &:before {
        background: #535353;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      &.ic-01 {
        &:before {
          content: '';
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-eventMap-food.svg`});
        }
      }
      &.ic-02 {
        &:before {
          content: '';
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-eventMap-info.svg`});
        }
      }
      &.ic-03 {
        &:before {
          content: '';
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-eventMap-bingo.svg`});
        }
      }
      &.ic-04 {
        &:before {
          content: '';
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-eventMap-elevator.svg`});
        }
      }
      &.ic-05 {
        &:before {
          content: '';
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-eventMap-escalator.svg`});
        }
      }
      &.ic-06 {
        &:before {
          content: '';
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-eventMap-networking.svg`});
        }
      }
      &.ic-07 {
        &:before {
          content: '';
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-eventMap-restroom.svg`});
        }
      }
      &.ic-08 {
        &:before {
          content: '';
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-eventMap-press.svg`});
        }
      }
    }
  }

  // First Floor
  &.fiDesc {
    .le > .map-item {
      &:nth-child(1) {
        &:before {
          content: '1';
        }
      }
      &:nth-child(2) {
        &:before {
          content: '2';
          background: #ffae34;
        }
      }
      &:nth-child(3) {
        &:before {
          content: '';
          background: #535353;
        }
      }
      &:nth-child(3) {
        &:before {
          content: '';
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-eventMap-photo.svg`});
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }
    }

    .ri > .map-item {
      &:before {
        background: #535353;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      &:nth-child(1) {
        &:before {
          content: '';
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-eventMap-elevator.svg`});
        }
      }
      &:nth-child(2) {
        &:before {
          content: '';
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-eventMap-escalator.svg`});
        }
      }
      &:nth-child(3) {
        &:before {
          content: '';
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-eventMap-restroom.svg`});
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    ul {
      width: 100%;
      .map-item {
        font-size: 14px;
        padding: 16px 0 16px 52px;
        text-align: left;
      }
    }
    &.fiDesc {
      .item-wrap {
        &.ri {
          border-top: 1px solid rgba(0, 0, 0, 0.08);
        }
      }
    }
  }
  @media screen and (max-width: 838px) {
    &.baseDesc {
      .item-wrap.ri {
        height: calc(400px - 12px);
      }
    }
  }
  @media screen and (max-width: 825px) {
    &.baseDesc {
      .item-wrap.ri {
        height: calc(424px - 12px);
      }
    }
  }
  @media screen and (max-width: 768px) {
    &.baseDesc {
      .item-wrap.ri {
        height: calc(571px - 32px);
      }
    }
  }
  @media screen and (max-width: 496px) {
    &.baseDesc {
      .item-wrap.ri {
        height: calc(571px - 10px);
      }
    }
  }
  @media screen and (max-width: 399px) {
    &.baseDesc {
      .item-wrap.ri {
        height: calc(633px - 52px);
      }
    }
  }
  @media screen and (max-width: 358px) {
    &.baseDesc {
      .item-wrap.ri {
        height: calc(633px - 32px);
      }
    }
  }
`;
