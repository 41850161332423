import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DetailContainer from '../../components/DetailContainer';
import EventTechCont from '../../components/EventTechCont';
import { GET_META_TECH_SQUARE_REQUEST } from '../../redux/ActionTypes';

const EventTechSquare = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_META_TECH_SQUARE_REQUEST });
  }, [dispatch]);

  return (
    <DetailContainer>
      <EventTechCont />
    </DetailContainer>
  );
};

export default EventTechSquare;
