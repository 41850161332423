/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useRef } from 'react';
import {
  TransformWrapper,
  TransformComponent,
  useControls,
  useTransformEffect,
} from 'react-zoom-pan-pinch';
import Slider from 'react-slick';
import {
  MapContainer,
  MapWrap,
  MapImage,
  MapListWrap,
  MapListCont,
  MapImageBox,
  MapTabList,
  MapTab,
} from './EventMapElements';
import Section from '../Section';
import './style.scss';

// EventMapData
const EventMapData = [
  {
    id: 1,
    title: 'B1F',
    bg: `${process.env.PUBLIC_URL}/images/eventMap-b1.png`,
  },
  {
    id: 2,
    title: '1F',
    bg: `${process.env.PUBLIC_URL}/images/eventMap-1f.png`,
  },
];

// slide ArrowBtn
function SlideNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none' }}
      onClick={onClick}
    />
  );
}

function SlidePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none' }}
      onClick={onClick}
    />
  );
}

// show reset button only on mobile
const Controls = () => {
  const { resetTransform } = useControls();

  useTransformEffect(({ state, instance }) => {
    // console.log(state.scale); // { previousScale: 1, scale: 1, positionX: 0, positionY: 0 }
    const resetBtn = document.querySelector('.reset-btn');
    if (state.scale > 1) {
      resetBtn.classList.add('is-active');
    } else {
      resetBtn.classList.remove('is-active');
    }
    return () => {};
  });

  return (
    <>
      <button className="reset-btn" onClick={() => resetTransform()} />
    </>
  );
};

const EventMap = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 769);

  const [isBaseFloor, setIsBaseFloor] = useState(true);

  // Handle window resize event
  useEffect(() => {
    const handleResize = () => {
      setDesktop(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // slick slider
  const settings = {
    dots: true,
    speed: 800,
    centerMode: false,
    infinite: false,
    nextArrow: <SlideNextArrow />,
    prevArrow: <SlidePrevArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <MapContainer>
      <Section title="Event Map">
        {/* map */}
        <MapWrap>
          <MapTabList>
            <MapTab
              className={isBaseFloor ? 'is-selected' : ''}
              onClick={(e) => {
                e.preventDefault();
                setIsBaseFloor(!isBaseFloor);
              }}
            >
              B1F
            </MapTab>
            <MapTab
              className={!isBaseFloor ? 'is-selected' : ''}
              onClick={(e) => {
                e.preventDefault();
                setIsBaseFloor(!isBaseFloor);
              }}
            >
              1F
            </MapTab>
          </MapTabList>

          <MapImage>
            {isBaseFloor ? (
              <MapImageBox className="MapImageBox b1f">
                {isDesktop ? (
                  <img className="map-img" src={EventMapData[0].bg} alt="" />
                ) : (
                  <TransformWrapper>
                    <TransformComponent>
                      <img
                        className="map-img"
                        src={EventMapData[0].bg}
                        alt=""
                      />
                    </TransformComponent>
                    <Controls />
                  </TransformWrapper>
                )}
              </MapImageBox>
            ) : (
              <MapImageBox className="MapImageBox fif">
                {isDesktop ? (
                  <img className="map-img" src={EventMapData[1].bg} alt="" />
                ) : (
                  <TransformWrapper>
                    <TransformComponent>
                      <img
                        className="map-img"
                        src={EventMapData[1].bg}
                        alt=""
                      />
                    </TransformComponent>
                    <Controls />
                  </TransformWrapper>
                )}
              </MapImageBox>
            )}
          </MapImage>
        </MapWrap>

        {/* mapList */}
        {isBaseFloor ? (
          <>
            {isDesktop ? (
              <div className="eventmap_slide">
                <Slider {...settings}>
                  <MapListWrap>
                    <MapListCont className="baseDesc">
                      <ul className="item-wrap le">
                        <li className="map-item ic-01">Keynote</li>
                        <li className="map-item ic-02">
                          Tech Square - Home & Health Experience
                        </li>
                        <li className="map-item ic-03">
                          Tech Square - Platform Innovation
                        </li>
                        <li className="map-item ic-04">
                          Tech Square - Mobile & Screen Experience
                        </li>
                        <li className="map-item ic-05">
                          Tech Square - Sustainability
                        </li>
                      </ul>
                      <ul className="item-wrap le">
                        <li className="map-item ic-06">Hacker’s Playground</li>
                        <li className="map-item ic-07">Code Lab</li>
                        <li className="map-item ic-08">C-Lab</li>
                        <li className="map-item ic-09">Open Theater</li>
                        <li className="map-item ic-10">
                          Tech Sessions: Session 1, 2, 3
                        </li>
                      </ul>
                    </MapListCont>
                  </MapListWrap>
                  <MapListWrap>
                    <MapListCont className="baseDesc">
                      <ul className="item-wrap ri">
                        <li className="map-item ic-01">Food & Beverage</li>
                        <li className="map-item ic-02">Info Desk</li>
                        <li className="map-item ic-03">Bingo Station</li>
                        <li className="map-item ic-04">Elevator</li>
                        <li className="map-item ic-05">Escalator</li>
                      </ul>
                      <ul className="item-wrap ri">
                        <li className="map-item ic-06">Networking Lounge</li>
                        <li className="map-item ic-07">Restroom</li>
                        <li className="map-item ic-08">Press Lounge</li>
                      </ul>
                    </MapListCont>
                  </MapListWrap>
                </Slider>
              </div>
            ) : (
              <div className="eventmap_slide">
                <Slider {...settings}>
                  <MapListWrap>
                    <MapListCont className="baseDesc">
                      <div>
                        <ul className="item-wrap le">
                          <li className="map-item ic-01">Keynote</li>
                          <li className="map-item ic-02">
                            Tech Square - Home & Health Experience
                          </li>
                          <li className="map-item ic-03">
                            Tech Square - Platform Innovation
                          </li>
                          <li className="map-item ic-04">
                            Tech Square - Mobile & Screen Experience
                          </li>
                          <li className="map-item ic-05">
                            Tech Square - Sustainability
                          </li>
                          <li className="map-item ic-06">
                            Hacker’s Playground
                          </li>
                          <li className="map-item ic-07">Code Lab</li>
                          <li className="map-item ic-08">C-Lab</li>
                          <li className="map-item ic-09">Open Theater</li>
                          <li className="map-item ic-10">
                            Tech Sessions: Session 1, 2, 3
                          </li>
                        </ul>
                      </div>
                    </MapListCont>
                  </MapListWrap>
                  <MapListWrap>
                    <MapListCont className="baseDesc">
                      <div>
                        <ul className="item-wrap ri">
                          <li className="map-item ic-01">Food & Beverage</li>
                          <li className="map-item ic-02">Info Desk</li>
                          <li className="map-item ic-03">Bingo Station</li>
                          <li className="map-item ic-04">Elevator</li>
                          <li className="map-item ic-05">Escalator</li>
                          <li className="map-item ic-06">Networking Lounge</li>
                          <li className="map-item ic-07">Restroom</li>
                          <li className="map-item ic-08">Press Lounge</li>
                        </ul>
                      </div>
                    </MapListCont>
                  </MapListWrap>
                </Slider>
              </div>
            )}
          </>
        ) : (
          <>
            {isDesktop ? (
              <MapListWrap>
                <MapListCont className="fiDesc">
                  <ul className="item-wrap le">
                    <li className="map-item">Registration</li>
                    <li className="map-item">Main Entrance</li>
                    <li className="map-item">Photo Zone</li>
                  </ul>
                  <ul className="item-wrap ri">
                    <li className="map-item">Elevator</li>
                    <li className="map-item">Escalator</li>
                    <li className="map-item">Restroom</li>
                  </ul>
                </MapListCont>
              </MapListWrap>
            ) : (
              <MapListWrap>
                <MapListCont className="fiDesc">
                  <div>
                    <ul className="item-wrap le">
                      <li className="map-item">Registration</li>
                      <li className="map-item">Main Entrance</li>
                      <li className="map-item">Photo Zone</li>
                    </ul>
                    <ul className="item-wrap ri">
                      <li className="map-item">Elevator</li>
                      <li className="map-item">Escalator</li>
                      <li className="map-item">Restroom</li>
                    </ul>
                  </div>
                </MapListCont>
              </MapListWrap>
            )}
          </>
        )}
      </Section>
    </MapContainer>
  );
};

export default EventMap;
