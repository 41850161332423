// Session Detail Page: mega session
export const megaSessionDetail = {
  SessionBuilderCode: 'M',
  PresentationTitle:
    'Google and Samsung strengthen enterprise ecosystem together',
  AbstractTextShort:
    "The new Galaxy Watch Ecosystem is here! Are you curious to learn about the platform we created with Google? By attending this insightful session, you’ll discover why Samsung took the leap away from a legacy OS and joined a new unified platform for our line of Galaxy Watches. This highlight clip will uncover the benefits that can be realized by our developers, designers and end consumers. This informative presentation will guide the participant on how Samsung has simplified app development and watch face creation for our app community, while providing platform familiarity, maximum exposure and app distribution to an extensive user base. Samsung's push for excellence in health and wellness is enhanced by innovative multi-sensors and optimized APIs. Come and learn about the next phase of our Galaxy Watch Ecosystem! timized APIs. Come and l",
  category: ['Developer Program', 'Program', 'Design'],
  mainCategory: 'Platform Innovation',
  SessionType: 'Mega',
  PresentationDateTimeStart: '2023-10-05T14:50:00',
  PresentationDateTimeEnd: '2023-10-05T15:20:00',
  status: 'prepare live',
  onDemandURL: '',
  PresentationRoom: 'Open Theater',
  Presenters: [
    {
      PresenterID: '01',
      PresenterFirstName: 'JH',
      PresenterLastName: 'Han',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_1_23.png`,
      hoverTitle: '',
      subject: 'Keynote',
      blBg: false,
    },
    {
      PresenterID: '02',
      PresenterFirstName: 'Jaeyeon',
      PresenterLastName: 'Jung',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_2_23.png`,
      hoverTitle:
        'Building meaningful home experiences with enhanced partnerships',
      subject: 'Keynote',
      blBg: false,
    },
    {
      PresenterID: '03',
      PresenterFirstName: 'Cathy',
      PresenterLastName: 'You',
      PresenterOrganization: 'Aqara',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_13_23.png`,
      hoverTitle:
        'Creating a Seamless, Future-Ready Smart Home with Aqara & Samsung SmartThings',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '04',
      PresenterFirstName: 'Anil',
      PresenterLastName: 'Yadav',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_8_23.png`,
      hoverTitle:
        'Bixby: The best, optimal voice platform for the Samsung ecosystem',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '05',
      PresenterFirstName: 'Shin-Chul',
      PresenterLastName: 'Baik',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_6_23.png`,
      hoverTitle:
        'ProvPresenterIDing a safe, secure & convenient cross-device environment',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '06',
      PresenterFirstName: 'Hobum',
      PresenterLastName: 'Kwon',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_11_23.png`,
      hoverTitle: 'Tizen Reboot - for the next 10 years',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '07',
      PresenterFirstName: 'Bongjun',
      PresenterLastName: 'Ko',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_3_23.png`,
      hoverTitle:
        'Tizen Screen -\nthe window connecting you to the world in every way',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '08',
      PresenterFirstName: 'Sally Hyesoon',
      PresenterLastName: 'Jeong',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_5_23.png`,
      hoverTitle: 'Expanding the Galaxy experience with the new One UI',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '09',
      PresenterFirstName: 'Hon',
      PresenterLastName: 'Pak',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_12_23.png`,
      hoverTitle:
        'Building a digital health ecosystem with home-centric connected care',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '10',
      PresenterFirstName: 'Pattie',
      PresenterLastName: 'Maes',
      PresenterOrganization: 'MIT',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_15_23.png`,
      hoverTitle: '',
      subject: 'Keynote',
      blBg: false,
    },
    {
      PresenterID: '11',
      PresenterFirstName: 'Nick',
      PresenterLastName: 'Holzherr',
      PresenterOrganization: 'Whisk, part of Samsung',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_14_23.png`,
      hoverTitle: 'Delivering the joy of cooking and eating with Food AI',
      subject: 'Keynote',
      blBg: true,
    },
  ],
  relatedSession: [
    {
      PresentationID: '01',
      PresentationTitle:
        'Remote Test Lab: \n What’s new in TV \n development environment',
      AbstractTextShort:
        "One UI 4 is the next phase in the evolution of One UI. In this session, we'll see One UI 4 innovations that help you focus on what matters, feel comfortable while using the interface and express yourself and your style — watch for the exploding emoji! And you'll get a look at how we're using design to unify our ecosystem, from phones and foldables to tablets, Galaxy Books and watches.",
      PresentationImage: `${process.env.PUBLIC_URL}/images/img-mega-session-01.png`,
      MainCategory: 'Platform Innovation',
      PresentationTag: ['Design', 'IoT'],
      SessionType: 'Mega Session',
      status: 'live',
    },
    {
      PresenterID: '02',
      PresentationTitle:
        'Creating spectacular \n Galaxy Game Audio Experiences with Dolby Atmos',
      AbstractTextShort:
        'Usage of foldable devices is rapidly growing. This session will walk through the design process behind foldable devices, their multitasking capabilities as well as Flex mode. Understanding these topics will help developers maximize the foldable experience with their own apps across a growing number of foldable devices. We will also cover how to use the Jetpack WindowManager library, and how to optimize web pages for Flex mode using web standard Device Posture API for unique and differentiated experiences.',
      PresentationImage: `${process.env.PUBLIC_URL}/images/img-mega-session-02.png`,
      PresentationTag: ['Design', 'IoT'],
      SessionType: 'Mega Session',
      status: 'done',
    },
    {
      PresenterID: '03',
      PresentationTitle:
        'SmartThings Find: \n Our achievement and partnershipse',
      AbstractTextShort:
        "The new Galaxy Watch Ecosystem is here! Are you curious to learn about the platform we created with Google? By attending this insightful session, you’ll discover why Samsung took the leap away from a legacy OS and joined a new unified platform for our line of Galaxy Watches. This highlight clip will uncover the benefits that can be realized by our developers, designers and end consumers. This informative presentation will guide the participant on how Samsung has simplified app development and watch face creation for our app community, while providing platform familiarity, maximum exposure and app distribution to an extensive user base. Samsung's push for excellence in health and wellness is enhanced by innovative multi-sensors and optimized APIs. Come and learn about the next phase of our Galaxy Watch Ecosystem!",
      PresentationImage: `${process.env.PUBLIC_URL}/images/img-mega-session-03.png`,
      PresentationTag: ['Design', 'IoT'],
      SessionType: 'Mega Session',
      status: 'prepare status',
    },
  ],
  relatedVideo: [
    {
      id: 1,
      title: 'Game-Focused, Developer-Focused: The Galaxy Store SDC 2023',
      description: '',
      picture: `${process.env.PUBLIC_URL}/images/thumb-related-video-1.png`,
      url: 'https://www.youtube.com/c/SamsungDevelopers/videos',
    },
    {
      id: 2,
      title:
        'SmartThings Find: Our Special Achievement and Partnerships Top 10',
      description: '',
      picture: `${process.env.PUBLIC_URL}/images/thumb-related-video-2.png`,
      url: 'https://www.youtube.com/c/SamsungDevelopers/videos',
    },
    {
      id: 3,
      title: 'Game-Focused, Developer-Focused: The Galaxy Store and Health',
      description: '',
      picture: `${process.env.PUBLIC_URL}/images/thumb-related-video-3.png`,
      url: 'https://www.youtube.com/c/SamsungDevelopers/videos',
    },
  ],
  codelab: [
    {
      id: 1,
      title: 'Create a Daily Step Counter on Galaxy Watch',
      description:
        'Create a native app for Galaxy Watch4, operating on Wear OS powered by Samsung, using Health Platform to using Health Platform to using Health Platform to samsung',
      picture: `${process.env.PUBLIC_URL}/images/img-codelab.png`,
      link: 'https://developer.samsung.com/codelab',
    },
    {
      id: 2,
      title: 'Create a Daily Step Counter on Galaxy Watch',
      description:
        'Create a native app for Galaxy Watch4, operating on Wear OS powered by Samsung',
      picture: `${process.env.PUBLIC_URL}/images/img-codelab.png`,
      link: 'https://developer.samsung.com/codelab',
    },
  ],
  resources: [
    {
      title: 'Getting Started',
      description:
        'Learn how to extend Bixby that enables users to get things done using natural language.',
      linkUrl: 'https://developer.samsung.com/dashboard/support',
      linkText: 'View More',
    },
    {
      title: 'Guides',
      description:
        'Learn how to extend Bixby that enables users to get things done using natural language..',
      linkUrl: 'https://developer.samsung.com/dashboard/support',
    },
  ],
  technical: {
    title: 'Samsung Developer Forum',
    description:
      'These forums are powered by you and thousands of other passionate developers. We’re excited that you’ve decided to join us. We want to help you create the next awesome app or design, so click and find your place.',
    url: [
      {
        text: 'Announcing our testing partnership with BrowserStack',
        url: 'https://developer.samsung.com/codelab',
      },
      {
        text: 'Greetings Web Developers',
        url: 'https://www.youtube.com/c/SamsungDevelopers/videos',
      },
      {
        text: 'Webfont rendering in canvas issue',
        url: 'https://developer.samsung.com/codelab',
      },
    ],
  },
  subSessions: [
    {
      PresentationTitle:
        'Google and Samsung strengthen enterprise ecosystem together',
      Presenters: [
        {
          PresenterID: '09',
          PresenterFirstName: 'Hon',
          PresenterLastName: 'Pak',
          PresenterOrganization: 'Samsung Electronics',
          PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_12_23.png`,
          hoverTitle:
            'Building a digital health ecosystem with home-centric connected care',
          subject: 'Keynote',
          blBg: true,
        },
      ],
    },
    {
      PresentationTitle:
        'Google and Samsung strengthen enterprise ecosystem together',
      Presenters: [
        {
          PresenterID: '10',
          PresenterFirstName: 'Pattie',
          PresenterLastName: 'Maes',
          PresenterOrganization: 'MIT',
          PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_15_23.png`,
          hoverTitle: '',
          subject: 'Keynote',
          blBg: false,
        },
        {
          PresenterID: '11',
          PresenterFirstName: 'Nick',
          PresenterLastName: 'Holzherr',
          PresenterOrganization: 'Whisk, part of Samsung',
          PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_14_23.png`,
          hoverTitle: 'Delivering the joy of cooking and eating with Food AI',
          subject: 'Keynote',
          blBg: true,
        },
      ],
    },
  ],
};
