import styled from 'styled-components';

export const SpeakersContainer = styled.div`
  padding: 100px 0 148px 0;
  background-color: #ffffff;
  @media screen and (max-width: 768px) {
    padding: 60px 0 60px 0;
  }
`;

export const SpeakersInner = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const SpeakersWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap : wrap;
  margin: -12px;

  
	@media screen and (max-width: 1295px){
		.Card{
			&.hover {
				.Text {
					.Text-title {
						font-size: 20px;
					}
				}
			}

			.cardHover {
				.text {
					font-size: 18px;
					line-height: 24px;
				}
			}
		}
	}

	@media screen and (max-width: 1149px){
		.Card{
			&.hover {
				.Text {
					.Text-title {
						font-size: 18px;
					}
				}
			}

		}
	}

  @media screen and (max-width: 1140px){
		.Card {
			width: calc(33% - 24px)
		}

		.Card{
			&.hover {
				.Text {
					.Text-title {
						font-size: 20px;
					}
				}
			}
		}

		.Card{
			.cardHover {
				.text {
					font-size: 20px;
					line-height: 24px;
				}
			}
		}
	}

	@media screen and (max-width: 930px){
		.Card {
			width: calc(50% - 24px)
		}
	}

	@media screen and (max-width: 768px){
		margin: 0;
    display: flex;
    flex-flow: wrap;
		.Card {
			width: calc(50% - 4px);
			margin: 0;

			&.hover {
				.Text {
					.Text-desc {
						margin-top: 0;
						margin-bottom: 2px;
						font-size: 12px;
						line-height: 16px;
					}
					.Text-title {
						font-size: 14px;
						line-height: 20px;
					}
				}
			}

			+.Card {
				margin-top: 16px;
			}
			&:nth-of-type(2n-1) {
				margin-right: 8px;
			}
			&:nth-of-type(2) {
				margin-top: 0;
			}

			.Text {
					position: absolute;
					z-index: 1;
					top: 0;
					padding: 16px;
				}

				.card_img {
          height: 60vw;
				}
			}
		}
	}
`;

export const SpeakersSection = styled.div`
  & + & {
    margin-top: 160px;
  }
  @media screen and (max-width: 768px) {
    & + & {
      margin-top: 120px;
    }
  }
`;

export const SpeakersTitle = styled.div`
  margin-bottom: 40px;
  font-family: 'samsungSharpSans-bold';
  font-size: 32px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 1.4;
  }
`;
