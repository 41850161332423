import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const MyProfileWrap = styled.div`
  padding: 24px 0 128px 0;
  @media screen and (max-width: 768px) {
    padding: 16px 0 0 60px 0;
  }
`;

export const MyProfileWrapInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  @media screen and (max-width: 768px) {
    padding: 0px 24px;
    overflow: hidden;
  }
`;

export const ProfileSlider = styled.div`
  @media screen and (max-width: 768px) {
  }
`;

export const MyProfileDetail = styled.div`
  position: relative;
  background-color: #f6f6f6;
  border-radius: 24px;
  padding: 40px 40px 20px;

  &.profile_slider {
    width: calc(100% - 80px) !important;
  }

  &.unregister {
    padding: 40px 40px 100px 40px;
  }

  .btn-edit {
    position: absolute;
    top: 32px;
    right: 40px;
    min-width: 144px;
    min-height: 48px;
    padding: 14px 0;
    background-color: transparent;
  }
  .btn-unregister {
    position: absolute;
    top: 32px;
    right: 194px;
    min-width: 144px;
    min-height: 48px;
    padding: 14px 0;
    background-color: transparent;
  }

  table {
    thead {
      tr {
        display: inline-block;
        margin-bottom: 28px;
        text-align: left;
        min-width: 330px;
      }
      th {
        font-size: 24px;
        line-height: 1.34;
        font-family: 'samsungsharpsans-bold';
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #ddd;

        &:nth-child(2) {
          th {
            padding: 28px;
            padding-left: 0;
          }
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
      th {
        padding: 20px;
        text-align: left;
        vertical-align: top;
        font-family: 'SamsungOne-700';
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        color: #363636;
        padding-left: 0;
      }
      td {
        padding: 20px;
        font-family: 'SamsungOne-400';
        font-size: 16px;
        line-height: 1.5;
        color: #535353;
        text-align: left;
        span {
          display: block;
          & + span {
            margin-top: 8px;
          }
        }
      }
      .confirm-badge {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        padding-left: 17px;
        span {
          display: flex;
          align-items: center;
          padding: 6px 16px 6px 18px;
          font-family: 'SamsungOne-700';
          font-size: 16px;
          line-height: 24px;
          color: #2088fd;
          background: #fff;
          border-radius: 8px;
          box-sizing: border-box;

          svg {
            margin-right: 4px;
          }
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 10px;
          left: 8px;
          width: 1px;
          height: 16px;
          background-color: #ddd;
        }
      }

      .atten-tag {
        display: inline-block;
        background: #fff;
        max-width: 70px;
        justify-content: space-between;
        align-items: center;
        padding: 6px 16px;
        border-radius: 8px;

        &.virtual {
          max-width: 50px;
        }

        span {
          width: auto;
          text-align: center;
          color: #535353;
          font-family: 'SamsungOne-700';
          font-size: 16px;
          line-height: 1.5;
        }
        span + span {
          margin-top: 0;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 24px 24px 162px;
    background-color: #f6f6f6;
    border-radius: 16px;

    &.is-phase3 {
      padding-bottom: 104px !important;
    }

    &.profile_slider {
      width: auto !important;

      &.virtual {
        padding: 24px 24px 202px;
      }
    }

    &.unregister {
      padding: 24px;
    }

    .btn-edit {
      top: auto;
      left: 50%;
      bottom: 24px;
      width: calc(100% - 48px);
      transform: translateX(-50%);
      margin-left: 0;
    }
    .btn-unregister {
      top: auto;
      left: 50%;
      bottom: 82px;
      width: calc(100% - 48px);
      transform: translateX(-50%);
    }

    .col {
      width: 100%;
      col {
        display: block;
        width: 100%;
      }
    }
    table {
      thead {
        tr {
          display: block;
          font-size: 16px;
          margin-bottom: 16px;
        }
        th {
          font-size: 16px;
          text-align: left;
        }
      }

      tbody {
        tr {
          &:nth-child(2) {
            th {
              padding: 0;
            }
          }
        }

        tr {
          display: block;
          border: none;
          &:first-of-type {
            padding-top: 16px;
            border-top: 1px solid #ddd;
          }
          & + tr {
            margin-top: 24px;
          }
        }
        th {
          display: block;
          width: 100%;
          padding: 0;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.43;
        }
        td {
          display: block;
          width: 100%;
          padding: 0;
          margin-top: 4px;
          font-size: 14px;
          line-height: 1.43;
          span {
            & + span {
              margin-top: 4px;
            }
          }
        }

        .confirm-badge {
          display: block;
          padding-left: 0;
          span {
            max-width: 185px;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 20px;
            svg {
              width: 16px;
            }
          }
          &:before {
            display: none;
          }
        }

        .atten-tag {
          display: block;
          max-width: 61px;
          margin-top: 10px;
          padding: 6px 12px;
          + .confirm-badge {
            margin-top: 8px;
          }

          &.virtual {
            max-width: 44px;
          }

          span {
            font-size: 14px;
            line-height: 1.43;
          }
        }
      }
    }
  }
`;

export const RegisterTitle = styled.div`
  color: #000;
  font-family: 'samsungsharpsans-bold';
  font-size: 24px;
  line-height: 1.34;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 1.3;
  }
`;
export const RegisterContent = styled.div`
  p {
    color: #535353;
    text-align: center;
    font-family: 'SamsungOne-400';
    font-size: 16px;
    line-height: 1.5;
    margin-top: 48px;
  }
  .register-btn {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    line-height: 1.35;
    margin-top: 32px;
  }

  @media screen and (max-width: 768px) {
    p {
      margin-top: 32px;
      font-size: 14px;
      line-height: 1.45;
    }

    .register-btn {
      margin-top: 24px;
      font-size: 16px;
      line-height: 1.3;
      padding: 0 22px;
    }
  }
`;
