import styled from 'styled-components';
// color vaiable
const colors = {
  green: '#10B77B',
  purple: '#756BF1',
  lightPurple: '#CA6CEB',
  lightGreen: '#96D452',
  yellow: '#FFAE34',
  sBlue: '#0F42CF',
  darkBlue: '#1259AA',
  seaBlue: '#00AFE7',
  cobaltBlue: '#2088FD',
};

export const ScheduleContainer = styled.div`
  padding: 0 40px;

  .MainSectionTitleArea {
    br {
      display: block;
    }
  }

  @media (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const ScheduleInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0 126px 0;

  @media (max-width: 768px) {
    padding: 0 0 60px 0;
  }
`;

export const ScheduleCont = styled.div``;

export const ScheduleContInner = styled.div`
  display: flex;
  padding: 0 24px;
  border-radius: 20px;
  background-color: #ffffff;

  @media (max-width: 768px) {
    padding: 0;
    border-radius: 0;
  }
`;

export const ScheduleIndex = styled.div`
  display: grid;
  grid-template-rows: 158px 158px 158px 506px 158px;
  grid-template-columns: 77px;
  grid-gap: 10px;
  gap: 10px;
  margin-right: 20px;
  border-right: 1px solid #efefef;
  .item {
    font-family: 'SamsungOne-700';
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    color: #9e9e9e;
    padding-right: 15px;
    margin-top: 4px;
    &:nth-of-type(5) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 20px;
    }
    &:nth-of-type(6) {
      margin-top: 0;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ScheduleCalendar = styled.div`
  display: grid;
  grid-template-rows: 158px 158px 158px 158px 158px 158px 158px;
  grid-template-columns: calc(50% + 16px) calc(50% + 16px);
  grid-gap: 16px;
  gap: 16px;
  width: 100%;

  .item {
    position: relative;
    display: block;
    padding: 20px 24px 20px 46px;
    border-radius: 16px;
    background-color: #f6f6f6;
    transition: 0.3s;
    &:after {
      content: '';
      position: absolute;
      top: 16px;
      left: 20px;
      width: 6px;
      height: calc(100% - 32px);
      border-radius: 3px;
    }

    @media (min-width: 768px) {
      &.is-active {
        background-color: #eaeaea;
      }
    }

    .title {
      display: inline-block;
      font-family: 'SamsungOne-700';
      font-size: 24px;
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: normal;
      color: #1e1e1e;
    }
    .time {
      margin-top: 6px;
      font-family: 'SamsungOne-600';
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: normal;
      color: #7c7c7c;
    }
    .desc {
      margin-top: 14px;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #7e7e7e;
    }
    .tag {
      display: inline-block;
      margin-top: 16px;
      padding: 0 12px;
      color: #7c7c7c;
      font-family: 'SamsungOne-600';
      font-size: 14px;
      line-height: 32px;
      border-radius: 18px;
      background: #efefef;
      + .tag {
        margin-left: 8px;
      }
    }

    /*item*/
    &.item-00 {
      grid-column: span 2 / auto;
      &:after {
        background-color: ${colors.cobaltBlue};
      }
    }
    &.item-01 {
      grid-column: span 2 / auto;
      &:after {
        background-color: ${colors.darkBlue};
      }
      .title {
        display: flex;
        align-items: center;
        img {
          margin-left: 6px;
        }
      }
    }
    &.item-02 {
      grid-column: span 2 / auto;
      &:after {
        background-color: ${colors.purple};
      }
      .title {
        display: flex;
        align-items: center;
        img {
          margin-left: 6px;
        }
        br {
          display: none;
        }
      }
    }
    &.item-04 {
      grid-column: span 2 / auto;
      &:after {
        background-color: ${colors.purple};
      }
      .title {
        display: flex;
        align-items: center;
        img {
          margin-left: 6px;
        }
      }
    }
    &.item-05 {
      grid-column: span 2 / auto;
      &:after {
        background-color: #ffae34;
      }
      .title {
        display: flex;
        align-items: center;
        img {
          margin-left: 6px;
        }
      }
    }
    &.item-06 {
      grid-column: span 2 / auto;
      &:after {
        background-color: ${colors.sBlue};
      }
      .title {
        display: flex;
        align-items: center;
        img {
          margin-left: 6px;
        }
      }
    }
    &.item-07 {
      grid-column: span 2 / auto;
      br {
        display: none;
      }
      &:after {
        background-color: #96d452;
      }
      .title {
        display: flex;
        align-items: center;
        img {
          margin-left: 6px;
        }
      }
    }
    /* //item */
  }

  @media (max-width: 800px) {
    .item {
      .title {
        font-size: 22px;
      }
    }
  }

  @media (max-width: 768px) {
    display: block;
    .item {
      padding: 16px 24px 16px 27px;
      background-color: #f6f6f6;
      border-radius: 8px;
      &:after {
        top: 10px;
        left: 10px;
        width: 5px;
        height: calc(100% - 20px);
      }
      + .item {
        margin-top: 12px;
      }
      .title {
        display: block;
        width: 100%;
        font-size: 18px;
        line-height: 24px;
      }
      .time {
        margin-top: 2px;
        font-size: 14px;
        line-height: 1.43;
      }
      .desc {
        max-width: 85%;
        font-size: 14px;
        line-height: 1.43;
      }
      .tag {
        margin-top: 10px;
        + .tag {
          display: inline-block;
          margin-left: 8px;
        }
      }
      .link {
        bottom: 20px;
        left: 28px;
      }
      &.item-01 {
        .title {
          img {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      &.item-02 {
        grid-column: span 2;
        .title {
          padding-right: 0;
          background-position: top right;
          background-size: 8px 15px;
          br {
            display: block;
          }
          img {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      &.item-03 {
        display: none;
      }
      &.item-04 {
        .title {
          img {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      &.item-05 {
        .title {
          img {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      &.item-06 {
        .title {
          img {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      &.item-07 {
        .title {
          img {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      &.item-04,
      &.item-05,
      &.item-06,
      &.item-07,
      &.item-08 {
        grid-column: span 2;
      }

      &.item-07 {
        br {
          display: block;
        }
      }
    }
  }
`;

export const itemClassName = styled.div`
  display: block;

  &.item.hovered {
    background-color: red;
  }
`;
