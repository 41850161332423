import styled, { keyframes } from 'styled-components';

export const MainWelcome = styled.div`
  position: relative;
  padding: ${(props) =>
    props.Phase3 || props.Phase4 ? '328px 40px 424px' : '248px 40px 330px'};
  @media (max-width: 768px) {
    padding: ${(props) =>
      props.Phase3 || props.Phase4
        ? ' 30.811vh 24px 20.811vh'
        : ' 11.333vh 24px 13.167vh'};
  }
`;

export const WelcomInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1240px;
  margin: 0 auto;

  .macaron1 {
    display: block;
    position: absolute;
    top: 0;
    left: -10px;
    width: 285px;
    transform: translateY(-50%);
  }

  .macaron2 {
    display: block;
    position: absolute;
    top: 0;
    right: -2%;
    width: 380px;
    transform: translateY(8%);
  }

  .macaron3 {
    display: block;
    position: absolute;
    bottom: 0;
    left: 16%;
    width: 300px;
    transform: translateY(110%);
  }

  @media (max-width: 768px) {
    .macaron1 {
      left: -17vw;
      width: 36vw;
    }

    .macaron2 {
      right: -25vw;
      width: 50vw;
      transform: translateY(2%);
    }

    .macaron3 {
      left: -16vw;
      width: 38vw;
      transform: translateY(90%);
    }
  }
`;

export const WelcomeIbnerBox = styled.div`
  position: relative;
  text-align: center;

  .Button {
    position: relative;
    z-index: 2;
  }
`;

export const WelcomeTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'samsungsharpsans-bold';
  font-size: 92px;
  line-height: 1.3;
  z-index: 1;

  span {
    padding-left: 18px;
    line-height: 86px;
  }

  br {
    display: none;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    font-size: 48px;

    br {
      display: block;
    }

    span {
      padding-top: 8px;
      padding-left: 0;
      padding-bottom: 4px;
      line-height: 37px;

      img {
        width: 161px;
      }
    }
  }
`;

export const WelcomeTime = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  font-family: 'samsungsharpsans-bold';
  font-size: 92px;
  line-height: 1.3;
  color: #2088fd;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'clig' off, 'liga' off;

  .colon {
    padding: 0 23px;
  }

  .daycolon {
    padding: 0 8px 0 10px;
  }

  @media (max-width: 768px) {
    margin-bottom: 16px;
    font-size: 48px;

    .colon {
      padding: 0 14px;
    }

    .daycolon {
      padding: 0 4px;
    }
  }
`;

export const WelcomeDay = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  font-family: 'samsungsharpsans-bold';
  font-size: 92px;
  line-height: 1.3;
  color: #2088fd;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'clig' off, 'liga' off;

  .dash {
    position: relative;
    padding-left: 71px;
    &:after {
      content: '';
      width: 37px;
      height: 11px;
      background-color: #2088fd;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 19px;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 16px;
    font-size: 48px;

    .dash {
      padding-left: 32px;
      &:after {
        content: '';
        width: 20px;
        height: 8px;
        background-color: #2088fd;
        position: absolute;
        left: 8px;
      }
    }
  }
`;

export const WelcomeText = styled.div`
  position: relative;
  margin-bottom: 56px;
  margin-top: 24px;
  font-size: 24px;
  color: #7c7c7c;
  line-height: 32px;
  text-align: center;
  z-index: 2;

  @media (max-width: 768px) {
    margin-bottom: 40px;
    margin-top: 16px;
    padding: 0 4px;
    font-size: 16px;
    line-height: 24px;
    color: #535353;
  }
`;

/*
 MainText Anu
*/
const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
`;
export const MainTextAni = styled.div`
  position: relative;
  display: block;
  padding: 0 40px 160px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(246, 246, 246, 0.8) 100%
  );

  .macaron4 {
    display: block;
    position: absolute;
    top: 0;
    left: 57%;
    width: 298px;
    z-index: 1;
    transform: translateY(-10%);
  }

  .macaron5 {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: 270px;
    z-index: 1;
    transform: translate(-50%, 100%);

    .img1 {
      position: relative;
      display: block;
      animation: ${bounceAnimation} 2.3s ease-in-out infinite;
    }
  }

  @media (max-width: 768px) {
    .macaron5 {
      width: 250px;
    }
  }

  @media (max-width: 768px) {
    padding: ${(props) =>
      props.Phase3 || props.Phase4 ? '90px 20px 72px' : '96px 20px 72px'};
    .macaron4 {
      width: 38vw;
    }

    .macaron5 {
      width: 32.333vw;
    }
  }
`;

export const MainTextAniInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  height: 675px;
  margin: 0 auto;
  background-color: #ffae34;
  border-radius: 36px;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 372px;
    border-radius: 16px;
  }
`;

export const IntroMotionText = styled.div`
  display: block;

  .item-01,
  .item-02,
  .item-03 {
    font-family: 'samsungsharpsans-bold';
    font-size: 120px;
    line-height: 158px;
    color: #fff;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    opacity: 0.5;
  }

  .item-01 {
    transform: translateX(-3.555vw);
  }

  .item-02 {
    position: relative;
    transform: translateX(7.555vw);
    z-index: 2;
  }

  .item-03 {
    transform: translateX(-1.555vw);
  }

  @media (max-width: 768px) {
    .item-01,
    .item-02,
    .item-03 {
      font-size: 11.599vw;
      line-height: 14.155vw;
    }

    .item-01 {
      transform: translateX(-1.23vw);
    }

    .item-02 {
      margin-top: 16px;
      transform: translateX(7.555vw);
    }

    .item-03 {
      margin-top: 16px;
      transform: translateX(-1.23vw);
    }
  }

  @media (max-width: 380px) {
    .item-01,
    .item-02,
    .item-03 {
      font-size: 48px;
      line-height: 58px;
    }
  }
`;
