import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DetailContainer from '../../components/DetailContainer';
import SessionRoomCont from '../../components/SessionRoomCont';
import Loading from '../../components/Loading';

import { GET_SESSION_ROOM_REQUEST } from '../../redux/ActionTypes';
import {
  SESSION_ON_DEMAND,
  SESSION_OPEN,
  SESSION_ERROR,
  SESSION_CLOSED,
  SESSION_REPLAY,
} from '../../data/status';

const SessionRoom = () => {
  const { isLoading, data, gapTime } = useSelector((state) => state.meta);
  const megaRoomStatus = useSelector(
    (state) => state.sessionList.megaRoomStatus
  );
  const megaSession = data?.mega || {};

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_SESSION_ROOM_REQUEST });
  }, []);

  const [noPlayable, setNoPlayable] = useState(false);

  const noPlayerHandler = () => {
    setNoPlayable(true);
  };

  return (
    <>
      <DetailContainer>
        {isLoading ? (
          <Loading />
        ) : (
          <SessionRoomCont
            title="Mega Session"
            desc="<p>Discover the latest in tech with SDC23's livestream! Explore Digital Health innovations, Gamepad tips for Tizen TV, gaming on Galaxy devices, and the magic of HDR10+ Gaming. Dive into the future with us.</p>"
            released={
              (megaSession?.videoStatus === SESSION_OPEN ||
                megaSession?.videoStatus === SESSION_REPLAY) &&
              megaSession?.url &&
              !noPlayable
            }
            uploadedBefore={megaSession?.videoStatus === SESSION_CLOSED}
            url={
              megaSession?.videoStatus === SESSION_OPEN ||
              megaSession?.videoStatus === SESSION_REPLAY
                ? megaSession?.url
                : null
            }
            vodOpen={false}
            emergency={megaSession?.videoStatus === SESSION_ERROR}
            noPlayable={noPlayable}
            setNoPlayable={noPlayerHandler}
            // 세션 스케줄 확정 이후 해당 시작 시각으로 변경
            time="11:30 PT"
            notiTime={megaSession?.notiTime}
            gapTime={gapTime}
            live={megaRoomStatus === 'live'}
            sessionStatus={megaSession?.videoStatus}
          />
        )}
      </DetailContainer>
    </>
  );
};

export default SessionRoom;
