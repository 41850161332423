import React from 'react';
import { Helmet } from 'react-helmet';

function SEO({ description, lang, meta, title, url }) {
  return (
    <Helmet>
      <title>{`${
        title ? `${title} | ` : ''
      }Samsung Developer Conference`}</title>
      <meta
        name="description"
        content={description || 'The world runs on you.'}
      />
      <meta name="og:site_name" content="Samsung Developers" />
      <meta
        property="og:title"
        content={`${title ? `${title} | ` : ''}Samsung Developer Conference`}
      />
      <meta
        property="og:description"
        content={description || 'The world runs on you.'}
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta
        property="og:image"
        content="https://d3unf4s5rp9dfh.cloudfront.net/SDP/SNS_1280x630.jpg"
      />
      <meta name="twitter:site" content="@samsung_dev" />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:title"
        content={`${title ? `${title} | ` : ''}Samsung Developer Conference`}
      />
      <meta
        name="twitter:description"
        content={description || 'The world runs on you.'}
      />
      <meta
        name="twitter:domain"
        content="https://www.samsungdeveloperconference.com"
      />
      <meta
        name="twitter:image"
        content="https://d3unf4s5rp9dfh.cloudfront.net/SDP/SNS_1280x630.jpg"
      />
    </Helmet>
  );
}

export default SEO;
