import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DetailContainer from '../DetailContainer';
import MyProfile from '../Myprofile';
import MyInfo from '../MyInfo';
import MyDownload from '../MyDownload';
import MypageActivity from '../MypageActivity';
import { RegiContainer } from './MypageContElement';
import MypageOnsiteActivity from '../MypageOnsiteActivity';

const MypageCont = (props) => {
  const { userData, likeData } = props;

  const [isRegistered, setIsRegistered] = useState(false); // TODO: handling isRegistered true: register / false: unregister
  const [isOnline, setIsOnline] = useState(false); // TODO: handling isOnline true: image / false: ticket image
  const phase = useSelector((state) => state.meta.data.phase);

  useLayoutEffect(() => {
    if (!userData) {
      return;
    }
    if (userData.formData.length > 0) {
      setIsRegistered(true);
    }
  }, [userData]);

  return (
    <>
      {userData &&
        (isRegistered ? (
          <DetailContainer>
            <MyProfile
              name={`${userData.FirstName} ${userData.LastName}`}
              mail={userData.Email}
              url={userData.ProfileImage && userData.ProfileImage}
            />
            <MyInfo
              formData={userData.formData.length > 0 ? userData.formData : []}
              isRegistered={isRegistered}
              isOnline={isOnline}
            />
            {/*
            {phase && phase !== 'phase2' && (
              <>
                <MyDownload title="Download Wallpaper" isOnline />
                <MyDownload
                  title="Download Commemorative Ticket"
                  isOnline={false}
                  userData={userData}
                />
              </>
            )}
            */}
            {/* {phase && phase === 'phase4' ? <MypageOnsiteActivity /> : null} */}
            {/* <MypageActivity isRegistered={false} likeData={likeData} /> */}
          </DetailContainer>
        ) : (
          <RegiContainer>
            <DetailContainer>
              <MyProfile
                name={`${userData.FirstName} ${userData.LastName}`}
                mail={userData.Email}
                url={userData.ProfileImage && userData.ProfileImage}
              />
              <MyInfo isRegistered={isRegistered} />
              {/* <MypageActivity isRegistered likeData={likeData} /> */}
            </DetailContainer>
          </RegiContainer>
        ))}
    </>
  );
};

export default MypageCont;
