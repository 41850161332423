import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
  Support,
  SupportWrap,
  SupportInner,
  SupportTitle,
  SupportBanner,
  SupportBannerTitle,
  SupportBannerInner,
  SupportItem,
  SupportItemTitle,
  SectionWrap,
  SectionInner,
  SectionCont,
} from './SupportContElements';
import Accordion from '../Accordion';
import Banner from '../Banner';
import CardLink from '../CardLink';
import Section from '../Section';

const CodelabData = [
  {
    id: 1,
    title: 'Visit Samsung Developer Portal',
    discription: '',
    picture: `${process.env.PUBLIC_URL}/images/support-thumb-video-01.png`,
    link: 'https://developer.samsung.com',
    className: 'no-video',
  },
  {
    id: 2,
    title: 'Introduction to Samsung Developer Portal',
    discription: '',
    picture: `${process.env.PUBLIC_URL}/images/support-thumb-video-02.png`,
    link: 'https://www.youtube.com/watch?v=Ccm2L_hmVGQ',
  },
  {
    id: 3,
    title: 'Introduction to Remote Test Lab',
    discription: '',
    picture: `${process.env.PUBLIC_URL}/images/support-thumb-video-03.png`,
    link: 'https://www.youtube.com/watch?v=6xwyuXw0P6E&t=11s',
  },
];

const BannerData = [
  {
    id: 1,
    title: 'Technical Q&A Forum',
    desc: 'For more technical questions, take a scroll through the forum, or post your own question to get suggestions straight from the community.',
    url: 'https://developer.samsung.com/dashboard/support',
    buttonLengthOne: true,
    buttonUrl: 'https://forum.developer.samsung.com/',
    buttonText: 'Go to Q&A Forum',
    popupRight: true, // 로그인 전일 경우 true,
  },
  {
    id: 2,
    title: 'Watch SDC22',
    desc: 'See the highlights, revisit your favorite sessions, or catch what you missed from SDC22.',
    url: 'https://developer.samsung.com/dashboard/support',
    buttonLengthOne: true,
    buttonUrl: 'https://developer.samsung.com/conference/sdc22',
    buttonText: 'Go to see SDC22',
    // popupRight: false //로그인 전일 경우 true,
  },
];

const SupportCont = () => {
  const [index, setIndex] = useState(0);
  const { isLogin } = useSelector((state) => state.userSession);
  const phase = useSelector((state) => state.meta.data.phase);
  const [phase4, setPhase4] = useState(false);

  return (
    <Support>
      <Tabs>
        <SupportBanner>
          <SupportBannerTitle>SDC23 Support</SupportBannerTitle>
          <SupportBannerInner>
            {BannerData.map((item, idx) => (
              <Banner
                key={idx}
                id={item.id}
                title={item.title}
                desc={item.desc}
                url={item.url}
                buttonLengthOne={item.buttonLengthOne}
                buttonUrl={item.buttonUrl}
                buttonText={item.buttonText}
              />
            ))}
          </SupportBannerInner>
        </SupportBanner>
        <SupportTitle>FAQs</SupportTitle>
        {/*
          * Phse2.5 탭 숨김처리
             <TabList>
              <Tab>Online</Tab>
              <Tab>On-site</Tab>
            </TabList>
            <TabPanel>
              <SupportWrap>
                <SupportInner>
                  <SupportItem>
                  </SupportItem>
                </SupportInner>
              </SupportWrap>
            </TabPanel>
            <TabPanel>
              <SupportWrap>
                <SupportInner>
                </SupportInner>

              </SupportWrap>
            </TabPanel>
        */}

        <SupportWrap>
          <SupportInner>
            <SupportItem>
              <Accordion
                defaultActive
                title="What is Samsung Developer Conference?"
                content="Samsung Developer Conference brings thousands of developers, creators and designers together to build connections and shape the future of customer experiences. Discover the latest tech innovations enabling the design of simple, convenient, and seamless experiences between devices, and be empowered to create cutting-edge solutions within an expansive ecosystem. SDC features lectures by industry experts and opportunities for hands-on learning with our newest tech — so you’ll get first-hand experience of the latest innovations shaping a new era of experiences."
              />
              <Accordion
                title="What can I expect at SDC23?"
                content="You’ll find out about the latest technologies from Samsung, hear compelling presentations by industry experts, attend technical sessions offering insights into SmartThings, Bixby, C-Lab, Knox, and a whole lot more."
              />
              <Accordion
                title="Where and when does SDC23 take place?"
                content="SDC23 will be held on October 5, 2023, and will take place both online and on-site. The on-site event will take place at Moscone North in San Francisco and all content and sessions will be made available for free virtually, so anyone can connect to SDC23 from anywhere in the world."
              />
              <Accordion
                title="How much does it cost to attend SDC23?"
                content="Both online and on-site attendance is completely free of charge."
              />
              <Accordion
                title="How can I attend SDC23 on-site?"
                content="To attend SDC23 on-site, you must register on the website (<a href=https://www.samsungdeveloperconference.com/ target='_blank' rel='noopener noreferrer'>developer.samsung.com/sdc</a>). <br />To register, you need to have a Samsung account, or you can quickly and easily create a new one using your existing Google account."
              />
              <Accordion
                title="What if I miss the keynote or sessions? Can I watch them again?"
                content="Yes, you will be able to access recordings on-demand on the SDC site starting on the last day of the event."
              />
              <Accordion
                title="Where can I find the agenda in more detail?"
                content="Head to the SDC23 agenda page <a href='/schedule'>here</a>"
              />
              <Accordion
                title="How can I keep updated on the latest announcements from SDC23?"
                content="Updates and other relevant news released before SDC23 can be found on the Samsung Developer Facebook <a href=https://www.facebook.com/samsungdev target='_blank' rel='noopener noreferrer'>(https://www.facebook.com/samsungdev)</a>, Instagram <a href=https://instagram.com/samsung_dev target='_blank' rel='noopener noreferrer'>(https://instagram.com/samsung_dev)</a>, Twitter <a href=https://twitter.com/samsung_dev  target='_blank' rel='noopener noreferrer'>(https://twitter.com/samsung_dev)</a> and LinkedIn <a href=https://www.linkedin.com/company/samsungdevelopers  target='_blank' rel='noopener noreferrer'>(https://www.linkedin.com/company/samsungdevelopers)</a> accounts. You can also subscribe to the newsletter at the bottom of the main event page so you don’t miss out on a single SDC23 update!"
              />
              <Accordion
                title="What should I do if I have other questions?"
                content="If you have any further questions, feel free to contact us at <a href=mailto:sdcsupport@samsungdevelopers.com target='_blank' rel='noopener noreferrer'>sdcsupport@samsungdevelopers.com</a>."
              />
            </SupportItem>

            {/*

          <SupportItem>
            <SupportItemTitle>General</SupportItemTitle>
            <Accordion
            title="When and where is the conference?"
            content="SDC23 will be held both online and offline on Oct 12, 2023. Taking place in Moscone North, San Francisco, the event will be attended on-site by a limited audience."
            />
            <Accordion
            title="Who is invited to attend the event on-site?"
            content="The list of SDC23 offline attendees includes Samsung Electronics partners, Samsung employees and invited members of the media. All attendees will be able to explore the exhibition, as well as enjoy the networking opportunities with fellow attendees, allowing for insight sharing and the discussion of future technologies."
            />
            <Accordion
            title="What can I expect to see at SDC23 offline event?"
            content="Presented by industry-leading experts, the Keynote announcement is the most engaging way to experience Samsung's latest technology. The reveals are then further explained in a variety of Tech Sessions that cover topics like SmartThings, Bixby, Security/Privacy, Tizen and more. The exhibition is where you can experience the tech and software for yourself. Additionally, there will be networking opportunities where ideas can be shared and inspiration awoken with fellow attendees, plus other fun activities to enjoy, too!"
            />
            <Accordion
            title="What time should I arrive on SDC Day?"
            content="We will be kicking off SDC23 with the Keynote at 10am sharp, and open registration desk at 8am, so please make sure you make it to Moscone North with a little time to spare, so you don’t miss out on the exciting news! "
            />
            <Accordion
            title="Will the event be recorded or live streamed?"
            content="Both! The Keynote and Tech Sessions will all be live streamed via SDC23 website, so that people can tune in globally to hear the news, too. All content presented at SDC23 will be recorded and made available once the event has ended through the SDC23 website."
            />
            <Accordion
            title="Where can I see the full schedule of events?"
            content="To take a look at the full SDC23 schedule, <a href='#/schedule'>Go to schedule</a>"
            />
            <Accordion
            title="Could a photo of me at the event be used in promotional materials?"
            content="Yes, absolutely! Now and then, the shots our SDC photographers capture are used in our promotional materials."
            />
            <Accordion
            title="What should I do if I don’t want my photo taken?"
            content="We understand that not everyone wants to have their photo taken, and we fully respect the privacy of our attendees. Please help us to do that by informing our Registration Team of your preferences when checking in."
            />
            <Accordion
            title="Help! I’ve lost something. What do I do?"
            content=": If you think you might have misplaced something and the event is still running, head straight to the Registration Desk. Your item may have been found and handed in by someone else. And if you only realize that something’s gone AWOL after SDC23 ends, please send over a description of it by email to <a href=mailto:sdcsupport@samsungdevelopers.com target='_blank' rel='noopener noreferrer'>sdcsupport@samsungdevelopers.com</a>."
            />
            <Accordion
            title="I have more questions that haven’t been mentioned here. What should I do?"
            content="No problem! For any further questions, feel free to contact us at <a href=mailto:sdcsupport@samsungdevelopers.com target='_blank' rel='noopener noreferrer'>sdcsupport@samsungdevelopers.com</a>."
            />
          </SupportItem>
          <SupportItem>
            <SupportItemTitle>Registration</SupportItemTitle>
            <Accordion
            title="How can I pick up my badge?"
            content="Please bring your badge confirmation email (with QR code) to the Registration Desk to pick up your badge. We do not allow bulk pick up or picking up for others. You may not pick up your badge without a QR code."
            />
            <Accordion
            title="I can’t remember my Samsung account and/or password for my registration. How can I retrieve this information?"
            content="We can help with that! On the login page, please select “find ID.” We will send you an email prompting you to reset your login information. If you have any trouble, let us know by emailing <a href=mailto:sdcsupport@samsungdevelopers.com target='_blank' rel='noopener noreferrer'>sdcsupport@samsungdevelopers.com</a>."
            />
            <Accordion
            title="Can I cancel my registration?"
            content="We really hope to see you at SDC23, but if something comes up, please contact us to request a cancel at <a href=mailto:sdcsupport@samsungdevelopers.com target='_blank' rel='noopener noreferrer'>sdcsupport@samsungdevelopers.com</a>."
            />
            <Accordion
            title="Can I bring a guest?"
            content="If you would like to bring a guest along with you to SDC23, they will also need to be registered as an attendee. The best thing to do for this, or any other special request you may have, is to reach out to the Samsung personnel who invited you, or send an email to <a href=mailto:sdcsupport@samsungdevelopers.com target='_blank' rel='noopener noreferrer'>sdcsupport@samsungdevelopers.com</a>."
            />
          </SupportItem>
          <SupportItem>
            <SupportItemTitle>COVID & Vaccination Policy</SupportItemTitle>
            <Accordion
            title="Do I need to submit a proof of vaccination to attend?"
            content="As of March 11, 2023 (current San Francisco Public Health Requirements), the vaccination or proof of negative test requirement will no longer apply in most of indoors and it is not required to submit for SDC23. However, we continue to have safety measures in place to ensure that we have a safe and healthy environment for our visitors and staff. The measures include frequent cleaning of high-touch areas and providing sanitizing stations."
            />
            <Accordion
            title="Are masks required?"
            content="In accordance with City and Country of San Francisco guidance, masking is strongly recommended and we encourage you to wear mask indoors if you are more comfortable. If local health conditions change, masking requirements and other health precautions may be re-imposed."
            />
          </SupportItem>
          {
            !phase4?               <SupportItem>
            <SupportItemTitle>Hotel Reservations</SupportItemTitle>
            <Accordion
            title="Could you help me with a hotel reservation for SDC23?"
            content="We are pleased to help you for a hotel reservation. Please note that all rooms are available on a first-come, first-served basis and we encourage you to act quickly if you are still in need of accommodations. <a href=mailto:test@test.com target='_blank' rel='noopener noreferrer'>Request hotel reservations now.</a>"
            />
          </SupportItem>
          : null

          }
        */}
          </SupportInner>
        </SupportWrap>
      </Tabs>

      {phase === 'phase3' || phase === 'phase4' ? (
        <SectionWrap>
          <SectionInner>
            <Section title="developer.samsung.com">
              <SectionCont>
                {CodelabData.map((item, idx) => (
                  <CardLink
                    key={idx}
                    id={item.id}
                    col="col-3"
                    title={item.title}
                    discription={item.discription}
                    image={item.picture}
                    video
                    link={item.link}
                    className={item.className}
                  />
                ))}
              </SectionCont>
            </Section>
          </SectionInner>
        </SectionWrap>
      ) : null}
    </Support>
  );
};

export default SupportCont;
