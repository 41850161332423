import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import {
  TechContainer,
  TechInner,
  CardWrap,
  CardInner,
} from './CodeLabContElements';
import { CodeLabData } from '../../sample/CodeLabData';
import Button from '../Button';
import CardCodeLab from '../CardCodeLab';

const CodeLab = () => {
  // PC, MO 체크
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 1024);
  };

  // card interaction
  const tl = useRef();
  const cardRef = useRef();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      '(min-width: 768px)': function () {
        const cardArray = gsap.utils.toArray(cardRef.current.children);
        cardArray.forEach((el) => {
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              start: '0 95%',
              onEnter: () => {
                el.classList.add('is-active');
              },
            },
          });
        });
      },
      '(max-width: 767px)': function () {
        const cardArray = gsap.utils.toArray(cardRef.current.children);
        cardArray.forEach((el) => {
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              start: '0 100%',
              onEnter: () => {
                el.classList.add('is-active');
              },
            },
          });
        });
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
      tl.current.kill();

      ScrollTrigger.clearMatchMedia();
    };
  }, []);

  const [modal, setModal] = useState(false);
  const [modalFalse, setModalFalse] = useState(false);

  return (
    <TechContainer>
      <TechInner>
        <CardWrap>
          <CardInner ref={cardRef}>
            {CodeLabData.sort((a, b) => a.title.localeCompare(b.title)).map(
              (item, idx) => (
                <CardCodeLab
                  key={idx}
                  id={item.id}
                  listYn
                  col="col-3"
                  bookmark
                  title={item.title}
                  url={item.url}
                  description={item.description}
                  timeTaken={item.timeTaken}
                  image={item.image}
                  tag={item.tag}
                  type={item.type}
                  live={item.live}
                  setModal={setModal}
                  setModalFalse={setModalFalse}
                  // 로딩중일 경우 true로 전송
                  // loading={true}
                />
              )
            )}
          </CardInner>
        </CardWrap>
      </TechInner>
    </TechContainer>
  );
};

export default CodeLab;
