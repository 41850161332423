/* eslint-disable import/named */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import {
  TransformWrapper,
  TransformComponent,
  useControls,
  useTransformEffect,
} from 'react-zoom-pan-pinch';
import { useSelector } from 'react-redux';
import Button from '../Button';
import {
  TechSquare,
  TechSquareTabCon,
  TechSquareTabConInner,
  CardMoreBtn,
  EventTechSquareMap,
  MapContainer,
  MapWrap,
  MapImage,
  MapImageBox,
  EventTopUtil,
} from './EventTechContElement';
import EventTechSquareCard from '../EventTechCard';
import { EventTechSquareData } from '../../sample/EventTechSquareData';
import Section from '../Section';

// show reset button only on mobile
const Controls = () => {
  const { resetTransform } = useControls();

  useTransformEffect(({ state, instance }) => {
    // console.log(state.scale); // { previousScale: 1, scale: 1, positionX: 0, positionY: 0 }
    const resetBtn = document.querySelector('.reset-btn');
    if (state.scale > 1) {
      resetBtn.classList.add('is-active');
    } else {
      resetBtn.classList.remove('is-active');
    }
    return () => {};
  });

  return (
    <>
      <button className="reset-btn" onClick={() => resetTransform()} />
    </>
  );
};

const EventTechCont = () => {
  // Handle window resize event
  const [isDesktop, setDesktop] = useState(window.innerWidth > 769);

  const { isLoading, data } = useSelector((state) => state.metaTechSquare);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 769);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  // card interaction
  const tlArray = useRef([]);
  const tl = useRef();
  const cardRef = useRef([]);

  const [activeTab, setActiveTab] = useState(0);
  // more button
  const [count, setCount] = useState(0);
  const [more, setMoreBtn] = useState(false);

  const handleTabChange = (index) => {
    // 이전 탭에 대한 애니메이션을 종료
    if (tlArray.current[activeTab]) {
      tlArray.current[activeTab].kill();
    }
    setActiveTab(index);
  };

  useEffect(() => {
    // tlArray를 빈 타임라인으로 초기화
    tlArray.current = EventTechSquareData.map(() => null);
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      '(min-width: 768px)': function () {
        if (cardRef.current) {
          const cardArray = gsap.utils.toArray(cardRef.current.children);
          cardArray.forEach((el) => {
            tl.current = gsap.timeline({
              scrollTrigger: {
                trigger: el,
                start: '0 95%',
                onEnter: () => {
                  el.classList.add('is-active');
                },
              },
            });
          });
        }
      },
      '(max-width: 767px)': function () {
        if (cardRef.current) {
          const cardArray = gsap.utils.toArray(cardRef.current.children);
          cardArray.forEach((el) => {
            tl.current = gsap.timeline({
              scrollTrigger: {
                trigger: el,
                start: '0 100%',
                onEnter: () => {
                  el.classList.add('is-active');
                },
              },
            });
          });
        }
      },
    });

    return () => {
      // 언마운트 시 현재 활성 탭에 대한 애니메이션을 종료
      if (tlArray.current[activeTab]) {
        tlArray.current[activeTab].kill();
      }

      ScrollTrigger.getAll().forEach((t) => t.kill());
      tl.current.kill();

      ScrollTrigger.clearMatchMedia();
    };
  }, [activeTab, isLoading, count]);

  // init count value
  useEffect(() => {
    if (isLoading) {
      return;
    }

    const techData =
      activeTab === 0
        ? data.Platform
        : activeTab === 1
        ? data['Home & Health Experience']
        : activeTab === 2
        ? data['Mobile & Screen Experience']
        : activeTab === 3
        ? data.Sustainability
        : [];

    if (techData && isMobile) {
      if (data.length < 7) {
        setCount(data.length);
      } else {
        setCount(6);
      }
    } else if (techData) {
      setCount(data.length);
    }

    setMoreBtn(false);
  }, [activeTab, data]);

  // more button handler
  const onClick = (e, type) => {
    e.preventDefault();
    if (type === 'platform') {
      setCount(more ? 6 : data.Platform.length);
    } else if (type === 'home') {
      setCount(more ? 6 : data['Home & Health Experience'].length);
    } else if (type === 'mobile') {
      setCount(more ? 6 : data['Mobile & Screen Experience'].length);
    } else if (type === 'sustainability') {
      setCount(more ? 6 : data.Sustainability.length);
    }
    setMoreBtn(!more);
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <EventTechSquareMap>
        <EventTopUtil>
          <button type="button" className="link-prev" onClick={goBack}>
            Back to Previous Page
          </button>
        </EventTopUtil>

        <MapContainer>
          <Section title="Tech Square Map">
            <MapWrap>
              <div className="fl-txt">B1F</div>
              <MapImage>
                <MapImageBox className="MapImageBox">
                  {isDesktop ? (
                    <img
                      className="map-img"
                      src={`${process.env.PUBLIC_URL}/images/eventTechSquareMap-b1-A.png`}
                      alt=""
                    />
                  ) : (
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          className="map-img"
                          src={`${process.env.PUBLIC_URL}/images/eventTechSquareMap-b1-A.png`}
                          alt=""
                        />
                      </TransformComponent>
                      <Controls />
                    </TransformWrapper>
                  )}
                </MapImageBox>
              </MapImage>
            </MapWrap>
          </Section>
        </MapContainer>
      </EventTechSquareMap>

      <TechSquare>
        <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
          <TabList>
            <Tab>
              Platform&nbsp;
              <br className="hide-pc" />
              Innovation
            </Tab>
            <Tab>
              Home & Health&nbsp;
              <br className="hide-pc" />
              Experience
            </Tab>
            <Tab>
              Mobile & Screen&nbsp;
              <br className="hide-pc" />
              Experience
            </Tab>
            <Tab>Sustainability</Tab>
          </TabList>

          {/* Platform Innovation > object Key: Platform */}
          <TabPanel>
            <TechSquareTabCon>
              <TechSquareTabConInner ref={cardRef}>
                {(data.Platform || [{}, {}, {}, {}, {}, {}]).map(
                  (item, idx) => (
                    <EventTechSquareCard
                      key={idx}
                      slide={item.slide}
                      listYn
                      video={item.video}
                      title={item.title}
                      desc={item.desc}
                      popupDesc={item.popupDesc}
                      image={item.image}
                      images={item.images}
                      videoUrl={item.videoUrl}
                      // 로딩중일 경우 true로 전송
                      loading={isLoading}
                      hideCard={idx + 1 > count}
                    />
                  )
                )}
              </TechSquareTabConInner>
              {isMobile && data.Platform && data.Platform.length > 6 && (
                <CardMoreBtn>
                  <Button
                    color="white"
                    outline="outline"
                    size="medium"
                    className="moreBtn"
                    onClick={(e) => onClick(e, 'platform')}
                  >
                    {more ? 'Less' : 'More'}
                  </Button>
                </CardMoreBtn>
              )}
            </TechSquareTabCon>
          </TabPanel>
          {/* Home & Health Experience > object Key: Home & Health Experience */}
          <TabPanel>
            <TechSquareTabCon>
              <TechSquareTabConInner ref={cardRef}>
                {(
                  data['Home & Health Experience'] || [{}, {}, {}, {}, {}, {}]
                ).map((item, idx) => (
                  <EventTechSquareCard
                    key={idx}
                    slide={item.slide}
                    listYn
                    video={item.video}
                    title={item.title}
                    desc={item.desc}
                    popupDesc={item.popupDesc}
                    image={item.image}
                    images={item.images}
                    videoUrl={item.videoUrl}
                    // 로딩중일 경우 true로 전송
                    loading={isLoading}
                    hideCard={idx + 1 > count}
                  />
                ))}
              </TechSquareTabConInner>
              {isMobile &&
                data['Home & Health Experience'] &&
                data['Home & Health Experience'].length > 6 && (
                  <CardMoreBtn>
                    <Button
                      color="white"
                      outline="outline"
                      size="medium"
                      className="moreBtn"
                      onClick={(e) => onClick(e, 'home')}
                    >
                      {more ? 'Less' : 'More'}
                    </Button>
                  </CardMoreBtn>
                )}
            </TechSquareTabCon>
          </TabPanel>
          {/* Mobile & Screen Experience > object key: Mobile & Screen Experience */}
          <TabPanel>
            <TechSquareTabCon>
              <TechSquareTabConInner ref={cardRef}>
                {(
                  data['Mobile & Screen Experience'] || [{}, {}, {}, {}, {}, {}]
                ).map((item, idx) => (
                  <EventTechSquareCard
                    key={idx}
                    slide={item.slide}
                    listYn
                    video={item.video}
                    title={item.title}
                    desc={item.desc}
                    popupDesc={item.popupDesc}
                    image={item.image}
                    images={item.images}
                    videoUrl={item.videoUrl}
                    // 로딩중일 경우 true로 전송
                    loading={isLoading}
                    hideCard={idx + 1 > count}
                  />
                ))}
              </TechSquareTabConInner>
              {isMobile &&
                data['Mobile & Screen Experience'] &&
                data['Mobile & Screen Experience'].length > 6 && (
                  <CardMoreBtn>
                    <Button
                      color="white"
                      outline="outline"
                      size="medium"
                      className="moreBtn"
                      onClick={(e) => onClick(e, 'mobile')}
                    >
                      {more ? 'Less' : 'More'}
                    </Button>
                  </CardMoreBtn>
                )}
            </TechSquareTabCon>
          </TabPanel>
          {/* Sustainability > object key: Sustainability */}
          <TabPanel>
            <TechSquareTabCon>
              <TechSquareTabConInner ref={cardRef}>
                {(data.Sustainability || [{}, {}, {}, {}, {}, {}]).map(
                  (item, idx) => (
                    <EventTechSquareCard
                      key={idx}
                      slide={item.slide}
                      listYn
                      video={item.video}
                      title={item.title}
                      desc={item.desc}
                      popupDesc={item.popupDesc}
                      image={item.image}
                      images={item.images}
                      videoUrl={item.videoUrl}
                      // 로딩중일 경우 true로 전송
                      loading={isLoading}
                      hideCard={idx + 1 > count}
                    />
                  )
                )}
              </TechSquareTabConInner>
              {isMobile &&
                data.Sustainability &&
                data.Sustainability.length > 6 && (
                  <CardMoreBtn>
                    <Button
                      color="white"
                      outline="outline"
                      size="medium"
                      className="moreBtn"
                      onClick={(e) => onClick(e, 'sustainability')}
                    >
                      {more ? 'Less' : 'More'}
                    </Button>
                  </CardMoreBtn>
                )}
            </TechSquareTabCon>
          </TabPanel>
        </Tabs>
      </TechSquare>
    </>
  );
};

export default EventTechCont;
