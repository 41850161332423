import React from 'react';
import {
  MapContainer,
  MapInner,
  MapWrap,
  MapText,
  MapTitle,
  MapDesc,
  MapLink,
  MapImage,
} from './ScheduleMapElements';
import Section from '../Section';
import Button from '../Button';

const ScheduleMap = () => {
  return (
    <MapContainer>
      <MapInner>
        <Section title="On-site Map">
          <MapWrap>
            <MapText>
              <MapTitle>SDC23 @ Moscone North</MapTitle>
              <MapDesc>
                Know where to go — See a map of the event venue.
              </MapDesc>
            </MapText>
            <MapImage>
              <img
                src={`${process.env.PUBLIC_URL}/images/img-map.png`}
                alt="map"
              />
            </MapImage>
            <MapLink>
              <Button
                color="white"
                size="medium"
                outline="outline"
                onClick={() =>
                  window.open(
                    'https://www.google.com/maps/place/Moscone+North,+720+Howard+St,+San+Francisco,+CA+94103+%EB%AF%B8%EA%B5%AD/@37.7845708,-122.4040505,17z/data=!3m1!4b1!4m5!3m4!1s0x8085808779037287:0xa045f0ca867cc095!8m2!3d37.7845708!4d-122.4018618',
                    '_blank'
                  )
                }
              >
                View Detailed Map
              </Button>
            </MapLink>
          </MapWrap>
        </Section>
      </MapInner>
    </MapContainer>
  );
};

export default ScheduleMap;
