import React from 'react';
import { SectionContainer, SectionTitle, Sectioncont } from './SectionElements';

const Section = ({ title, children, isMegaSession }) => {
  return (
    <SectionContainer>
      <SectionTitle className={isMegaSession ? 'isMegaSession' : ''}>
        {title}
      </SectionTitle>
      <Sectioncont>{children}</Sectioncont>
    </SectionContainer>
  );
};

export default Section;
