import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useNavigate } from 'react-router-dom';
import {
  ClabWrap,
  ClabInner,
  DetailContainer,
  SessionTopUtil,
  ClabCards,
  ClabCardsInner,
  ClabCardsInnerCon,
} from './EventSamsungCLabElement';
import ClabCont from '../ClabCont';
import EventTechSquareCard from '../EventTechCard';
import { EventSamsungClabData } from '../../sample/EventSamsungClab';

const EventCLabCont = () => {
  // card interaction
  const tl = useRef();
  const cardRef = useRef();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      '(min-width: 768px)': function () {
        const cardArray = gsap.utils.toArray(cardRef.current.children);
        cardArray.forEach((el) => {
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              start: '0 95%',
              onEnter: () => {
                el.classList.add('is-active');
              },
            },
          });
        });
      },
      '(max-width: 767px)': function () {
        const cardArray = gsap.utils.toArray(cardRef.current.children);
        cardArray.forEach((el) => {
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              start: '0 100%',
              onEnter: () => {
                el.classList.add('is-active');
              },
            },
          });
        });
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
      tl.current.kill();

      ScrollTrigger.clearMatchMedia();
    };
  }, []);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <ClabWrap>
      <ClabInner>
        <DetailContainer>
          <SessionTopUtil>
            <button type="button" className="link-prev" onClick={goBack}>
              Back to Previous Page
            </button>
          </SessionTopUtil>
          <ClabCont
            title="Samsung C-Lab"
            desc="C-Lab Inside is a corporate venture program that encourages and nurtures innovative ideas from Samsung employees.<br/>
              It was established in 2012, to discover new ideas and to disperse a creative corporate culture throughout the company.<br/>
              C-Lab Inside projects that have proven marketability will either transfer to an in-house division or turned into spin-off companies supported by Samsung Electronics.<br/><br/>
              C-Lab Outside is a start-up acceleration program designed to encourage competent and prospective entrepreneurs outside Samsung Electronics.<br/>
              The C-Lab Outside program helps start-ups to grow their businesses by providing financial aid, mentoring, consultation, business co-operation, and infrastructure."
            tag=""
            /*
                Live or VOD
              */
            type=""
            released={false}
            btnBlock
            url="https://youtu.be/1O3drPCZlTo"
            // 세션 스케줄 확정 이후 해당 시작 시각으로 변경
            time="14:10 PM PT"
            /*
              Session Room 1 or Session Room 2
              */
            room="<a href=/#/sessionRoom>Session Room 1</a>"
            infoTime=""
            infoPlace=""
            uploadedBefore={false}
            on_Demand={false}
          />
        </DetailContainer>
      </ClabInner>
      <ClabCards>
        <ClabCardsInner>
          <ClabCardsInnerCon ref={cardRef}>
            {EventSamsungClabData.map((item, idx) => (
              <EventTechSquareCard
                key={idx}
                slide={item.slide}
                listYn
                video={item.video}
                title={item.title}
                subtitle={item.subtitle}
                desc={item.desc}
                popupDesc={item.popupDesc}
                image={item.img}
                images={item.images}
                videoUrl={item.videoUrl}
                link={item.link}
                // 로딩중일 경우 true로 전송
                // loading={true}
              />
            ))}
          </ClabCardsInnerCon>
        </ClabCardsInner>
      </ClabCards>
    </ClabWrap>
  );
};

export default EventCLabCont;
