import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import DetailHero from '../../components/DetailHero';
import CodeLabCont from '../../components/CodeLabCont';

const CodeLab = () => {
  return (
    <>
      <DetailContainer>
        <DetailHero
          bgPc="bg-codelab.png"
          bgMo="bg-codelab-mo.png"
          title="Code Lab"
          bgColor="#CA6CEB"
          path="Code Lab"
        />
        <CodeLabCont />
      </DetailContainer>
    </>
  );
};

export default CodeLab;
