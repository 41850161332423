import * as types from '../ActionTypes';

const initialState = {
  isLoading: true,
  isLogin: false,
};

const SessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_SESSION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.UPDATE_USER_SESSION: {
      const {
        isLogin,
        countryCode,
        showGDPRPopup,
        showGDPRModal,
        isCookiePolicy,
        cookieGDPR,
      } = action;
      return {
        ...state,
        isLogin,
        isLoading: false,
        countryCode,
        showGDPRPopup,
        showGDPRModal,
        isCookiePolicy,
        cookieGDPR,
      };
    }
    case types.UPDATE_USER_SESSION_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }

    case types.COOKIE_GDPR_MODAL_CLOSE: {
      return {
        ...state,
        showGDPRModal: false,
      };
    }

    case types.UPDATE_COOKIES_GDPR_REQUEST: {
      return {
        ...state,
        showGDPRModal: false,
        showGDPRPopup: false,
      };
    }

    case types.UPDATE_COOKIES_GDPR: {
      return {
        ...state,
        cookieGDPR: action.cookieGDPR,
      };
    }

    default:
      return state;
  }
};

export default SessionReducer;
