import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  SideMenuContainer,
  SideMenuInner,
  SideMenuTop,
  SideMenuLogo,
  SideMenuBtn,
  SideMenuCont,
  SideMenuLink,
  SideMenu2Deps,
  SideMenuArrow,
  SideMenuBottom,
} from './SideMenuElements';
import Logo from './NavLogo';

const SideMenu = ({ sideMenu, setSideMenu, pathname }) => {
  const isLogin = useSelector((state) => state.userSession.isLogin);
  const phase = useSelector((state) => state.meta.data.phase);

  const [open, setOpen] = useState(false);
  const depthRef = useRef();
  const depthItem = useRef();
  const open2Deps = () => {
    if (depthRef.current.offsetHeight > 0) {
      depthRef.current.style.height = '0';
    } else {
      depthRef.current.style.height = `${depthItem.current.offsetHeight}px`;
    }
    setOpen(!open);
  };

  const menuClose = () => {
    setSideMenu(false);
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    if (pathname === '/sessions' || pathname === '/speakers') {
      setOpen(true);
    }
  }, [pathname]);

  return (
    <SideMenuContainer sideMenu={sideMenu}>
      <SideMenuInner>
        <SideMenuCont>
          {/*
          <SideMenuLink
            className={pathname === '/keynote' ? 'is-active' : null}
          >
            <Link to="/keynote" onClick={menuClose}>
              Keynote
            </Link>
          </SideMenuLink>
          <SideMenuLink>
            <button onClick={open2Deps} type="button">
              Session
              <SideMenuArrow className={open ? 'is-open' : null} />
            </button>
            <SideMenu2Deps ref={depthRef} className={open ? 'is-open' : ''}>
              <div ref={depthItem}>
                <SideMenuLink
                  className={
                    pathname === '/sessions' || pathname.includes('sessions/')
                      ? 'deps-2 is-active'
                      : 'deps-2'
                  }
                >
                  <Link to="/sessions" onClick={menuClose}>
                    Sessions
                  </Link>
                </SideMenuLink>
                <SideMenuLink
                  className={
                    pathname === '/speakers' || pathname.includes('speakers/')
                      ? 'deps-2 is-active'
                      : 'deps-2'
                  }
                >
                  <Link to="/speakers" onClick={menuClose}>
                    Speakers
                  </Link>
                </SideMenuLink>
              </div>
            </SideMenu2Deps>
            <SideMenuLink
              className={pathname === '/schedule' ? 'is-active' : null}
            >
              <Link to="/schedule" onClick={menuClose}>
                Schedule
              </Link>
            </SideMenuLink>
          </SideMenuLink>
          {phase && (phase === 'phase3' || phase === 'phase4') && (
            <SideMenuLink
              className={pathname === '/codelab' ? 'is-active' : null}
            >
              <Link to="/codelab" onClick={menuClose}>
                Code Lab
              </Link>
            </SideMenuLink>
          )}
          <SideMenuLink
            className={
              pathname === '/event' || pathname.includes('event/')
                ? 'is-active'
                : null
            }
          >
            <Link to="/event" onClick={menuClose}>
              Event
            </Link>
          </SideMenuLink>
          <SideMenuLink
            className={pathname === '/support' ? 'is-active' : null}
          >
            <Link to="/support" onClick={menuClose}>
              Support
            </Link>
          </SideMenuLink>
          */}
          <SideMenuLink>
            <a
              className="prev"
              target="_blank"
              href="https://developer.samsung.com/conference/sdc23"
              rel="noreferrer"
            >
              Previous SDC
            </a>
          </SideMenuLink>
          <SideMenuBottom>
            {isLogin ? (
              <>
                <SideMenuLink
                  className={pathname === '/mypage' ? 'is-active' : null}
                >
                  <Link to="/mypage" onClick={menuClose}>
                    My Page
                  </Link>
                </SideMenuLink>
                <SideMenuLink>
                  <a href="/logout">Sign Out</a>
                </SideMenuLink>
              </>
            ) : (
              <>
                <SideMenuLink>
                  <a href={`/login?redirectURL=${window.location.pathname}`}>
                    Sign in
                  </a>
                </SideMenuLink>
              </>
            )}
          </SideMenuBottom>
        </SideMenuCont>
      </SideMenuInner>
    </SideMenuContainer>
  );
};

export default SideMenu;
