import styled from 'styled-components';

export const FilterContainer = styled.div`
  border-radius: 14px;
  background-color: #fff;
`;

export const FilterDim = styled.div`
  position: relative;
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
  }
`;

export const FilterInner = styled.div`
  position: relative;
  padding: 30px 20px 24px 20px;

  @media screen and (max-width: 1024px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    height: 100vh;
    padding: 0;
    border-radius: 0;
    background-color: #fff;
    z-index: 101;
  }
`;

export const FilterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  padding: 0 4px;

  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 20px 24px;
    border-bottom: 1px solid #efefef;
    box-sizing: border-box;
  }
`;

export const FilterTitle = styled.div`
  font-family: 'samsungSharpSans-bold';
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: normal;
  @media screen and (max-width: 1024px) {
    font-size: 20px;
    line-height: 1.2;
  }
`;

export const FilterClose = styled.button`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-close.png`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

export const FilterCont = styled.div`
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    height: calc(100vh - 64px - 80px);
    margin: 64px 0 80px 0;
    padding: 24px;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export const FilterItem = styled.div`
  & + & {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid #efefef;
  }

  @media screen and (max-width: 1024px) {
    & + & {
      margin-top: 24px;
      padding-top: 24px;
    }
  }
`;

export const FilterItemTitle = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding: 0 4px;
  font-family: 'SamsungOne-700';
  font-weight: bold;
  font-size: 18px;
  color: #363636;

  .count {
    padding-left: 6px;
    font-family: 'SamsungOne-600';
    color: #7c7c7c;

    em {
      color: #2088fd;
    }
  }

  .more-btn {
    position: absolute;
    top: 2px;
    right: 0;
    height: 20px;
    &.close {
      img {
        transform: rotate(0deg);
      }
    }
  }

  img {
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
  }

  @media screen and (max-width: 768px) {
    .more-btn {
      top: 5px;
      height: 14px;
    }

    img {
      width: 14px;
      height: 14px;
    }
  }
`;

export const FilterItemCont = styled.div`
  &.topic-filter {
    max-height: 416px;
    overflow: auto;
  }

  &.close {
    display: none;
  }
`;

export const FilterClearWrap = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    top: auto;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 15px 24px;
    border-top: 1px solid #efefef;
    background-color: #fff;
    box-sizing: border-box;
  }
`;

export const FilterClear = styled.button`
  padding: 0 20px;
  border-radius: 32px;
  border: 1px solid #ddd;
  font-family: 'SamsungOne-700';
  font-size: 16px;
  font-weight: bold;
  line-height: 46px;
  letter-spacing: normal;
  color: #1e1e1e;
  box-sizing: border-box;
  transition: 0.3s;
  &:hover:not(.disabled) {
    background-color: #eaeaea;
  }
  &.disabled {
    color: #c4c4c4;
  }

  @media screen and (max-width: 1024px) {
    min-width: 144px;
  }
`;
