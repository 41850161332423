import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {
  MainWelcome,
  WelcomInner,
  WelcomeIbnerBox,
  WelcomeTitle,
  WelcomeTime,
  WelcomeText,
  MainTextAni,
  MainTextAniInner,
  IntroMotionText,
  WelcomeTitleBox,
  WelcomeTimeBox,
} from './MainIntro3Elements';
import Button from '../Button';
import PopupCalendar from '../PopupCalendar';
import './style.scss';
import MainSectionTitle from '../MainSectionTitle';

const MainIntro2 = () => {
  // date: p2 / d-day: 2.5 / countdown: ~ p3 before event start time
  const [Phase2, setPhase2] = useState(true);
  const gapTime = useSelector((state) => state.meta.gapTime);
  const phase = useSelector((state) => state.meta.data.phase);

  const [addCalendar, setAddCalendar] = useState(false);
  const calendarHandler = () => {
    setAddCalendar(true);
    document.body.style.overflow = 'hidden'; // scroll block
  };

  // texts reveal, text move animation
  const introTl = useRef();
  const tlRelease = useRef();
  const mainRef = useRef();
  const introRef = useRef();
  const textRef = useRef();
  const welcomeTextRef = useRef();
  const calendarButtonRef = useRef();
  const textQ = gsap.utils.selector(textRef);
  const tlAfter = useRef();
  const q = gsap.utils.selector(introRef);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    introTl.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: mainRef.current,
          scrub: 0.5,
          start: '25% 55%',
          end: 'center center',
        },
      })
      .fromTo(
        welcomeTextRef.current,
        { y: 30, opacity: 0 },
        { y: 0, opacity: 1 }
      )
      .fromTo(
        calendarButtonRef.current,
        { y: 20, opacity: 0 },
        { y: 0, opacity: 1 },
        '-=0.3'
      );

    const textArray = gsap.utils.toArray(textRef.current.children);
    textArray.forEach((el) => {
      tlAfter.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: el,
            start: 'bottom 95%',
            end: '+=170px',
            scrub: true,
          },
        })
        .to(textQ(el), {
          opacity: 1,
        });
    });

    ScrollTrigger.matchMedia({
      '(min-width: 769px)': function () {
        tlRelease.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: introRef.current,
              start: '25% bottom',
              end: '+=600px',
              scrub: true,
              onUpdate: (self) => {
                if (self.progress > 0.6) {
                  textRef.current.classList.add('active');
                } else {
                  textRef.current.classList.remove('active');
                }
              },
            },
          })
          .fromTo(q('.item-01'), { x: -700 }, { x: 110 })
          .fromTo(q('.item-02'), { x: 700 }, { x: 20 }, '-=0.5')
          .fromTo(q('.item-03'), { x: -700 }, { x: 24 }, '-=0.5');
      },
      '(max-width: 768px)': function () {
        tlRelease.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: introRef.current,
              start: '10% bottom',
              end: '+=450px',
              scrub: true,
              onUpdate: (self) => {
                if (self.progress > 0.8) {
                  textRef.current.classList.add('active');
                } else {
                  textRef.current.classList.remove('active');
                }
              },
            },
          })
          .fromTo(q('.item-01'), { x: -70 }, { x: 0 })
          .fromTo(q('.item-02'), { x: 200 }, { x: 0 }, '-=0.5')
          .fromTo(q('.item-03'), { x: -130 }, { x: 2 }, '-=0.5');
      },
    });

    return () => {
      introTl.current.kill();
      tlAfter.current.kill();
      tlRelease.current.kill();
    };
  }, [q, textQ]);

  return (
    <>
      <MainWelcome ref={mainRef}>
        <WelcomInner>
          <WelcomeIbnerBox className="sloganTitle">
            <MainSectionTitle
              title={
                <>
                  <WelcomeTitle>
                    Welcome to <br />
                    <img
                      src={`${process.env.PUBLIC_URL}/images/txt-sdc24.svg`}
                      alt="SDC24"
                    />
                  </WelcomeTitle>
                </>
              }
              desc={
                <div>
                  <WelcomeTime>
                    <span>Thur</span>
                    <span className="daycolon">.</span>
                    <span>Oct 03</span>
                  </WelcomeTime>
                </div>
              }
            />
            <WelcomeText ref={welcomeTextRef}>
              Now meets next at SDC24 as we explore the untapped potential of
              today's tech. Join us, be <br />
              inspired, and get excited about the latest advancements.
            </WelcomeText>
            <div ref={calendarButtonRef}>
              <Button className="calendarButton" onClick={calendarHandler}>
                Add to Calendar
              </Button>
            </div>
            {/* calendar popup */}
            {addCalendar ? (
              <PopupCalendar
                setOpen={setAddCalendar}
                close
                title="Add to Calendar"
              />
            ) : null}
          </WelcomeIbnerBox>
        </WelcomInner>
      </MainWelcome>
      <MainTextAni
        ref={introRef}
        Phase3={phase === 'phase3'}
        Phase4={phase === 'phase4'}
      >
        <MainTextAniInner>
          <IntroMotionText ref={textRef} className="introMotionText">
            <div className="item-common item-01">
              <span>Open</span>
              <div className="item-typo open">
                <img
                  src={`${process.env.PUBLIC_URL}/images/MainIntro/open-front.svg`}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/images/MainIntro/open-bread.png`}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/images/MainIntro/sdc24-open-back.svg`}
                />
              </div>
            </div>
            <div className="item-common item-02">
              <div className="item-typo explore">
                <svg width="0" height="0">
                  <defs>
                    <path
                      id="blob"
                      d="M0.00390625 172.717C0.176267 68.1744 49.3334 -0.13768 135.801 0.000208358C178.385 0.0691525 208.043 15.6046 233.839 31.14C258.337 46.6639 278.963 62.1878 300.899 62.2223C325.42 62.2567 337.072 42.9294 337.129 5.49276L437.799 5.66511C437.627 110.207 393.629 178.531 302.002 178.382C251.673 178.301 219.441 156.308 189.784 139.474C170.445 127.823 154.979 117.469 135.617 117.446C113.681 117.412 102.03 135.464 101.972 172.889L0.0153969 172.717H0.00390625Z"
                      fill="#756BF1"
                    />
                    <clipPath id="myClip" clipPathUnits="objectBoundingBox">
                      <use
                        href="#blob"
                        transform="scale(0.00228,0.0054) translate(0,0)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div className="exploreBox">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/MainIntro/explore-bread.png`}
                  />
                </div>
              </div>
              <span>Explore</span>
            </div>
            <div className="item-common item-03">
              <span>Build</span>
              <div className="item-typo build">
                <img
                  src={`${process.env.PUBLIC_URL}/images/MainIntro/build-front.svg`}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/images/MainIntro/build-bread.png`}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/images/MainIntro/sdc24-build-back1.svg`}
                />
              </div>
            </div>
          </IntroMotionText>
        </MainTextAniInner>
      </MainTextAni>
    </>
  );
};

export default MainIntro2;
