import styled from 'styled-components';

export const SpeakerDetailWrap = styled.div`
  padding: 91px 0 80px 0;
  > button {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    padding: 51px 0 48px;
  }
`;

export const SpeakerDetailInner = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 40px;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 0 24px;
  }
`;

export const GotoPrevious = styled.div`
  width: 100%;
  max-width: 1280px;
  position: relative;
  margin: 0 auto;
  padding: 0 40px 0 72px;
  box-sizing: border-box;
  margin-bottom: 40px;
  font-family: 'SamsungOne-700';
  font-size: 20px;
  line-height: 1.3;
  color: #000;
  text-align: left;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 40px;
    width: 24px;
    height: 24px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-previous.svg`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  @media screen and (max-width: 768px) {
    padding: 0 24px 0 46px;
    margin-bottom: 29px;
    &:before {
      left: 16px;
    }
  }
`;

export const SpeakerDetailInfo = styled.div`
  width: calc(100% - 282px - 60px);
  margin-right: 60px;
  text-align: left;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 24px 0 0 0;
  }
`;

export const SpeakerDetailTitle = styled.div`
  font-family: 'samsungsharpsans-bold';
  font-size: 42px;
  line-height: 1.28;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const SpeakerDetailSubTitle = styled.div`
  margin-top: 16px;
  font-family: 'SamsungOne-700';
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1e1e1e;
  text-align: left;
  white-space: pre-line;
  @media screen and (max-width: 768px) {
    margin-top: 8px;
    font-size: 16px;
    line-height: 1.5;
  }
`;

export const SpeakerDetailDesc = styled.div`
  margin-top: 28px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #535353;
  text-align: left;
  > div {
    display: block;
  }
  &.is-more {
    height: 65px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  @media screen and (max-width: 768px) {
    margin-top: 20px;
    &.is-more {
      height: 64px;
    }
    &.is-appended {
      height: auto;
      overflow: initial;
    }
  }
`;

export const SpeakerDetailImage = styled.div`
  width: 282px;
  height: 388px;
  background-size: 110%;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-color: #f6f6f6;
  border-radius: 24px;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 111.115vw;
    border-radius: 16px;
    background-size: 115%;
    background-position: 50% 90%;
  }
`;

export const SpeakerDetailMoreWrap = styled.div`
  display: none;
  margin-top: 16px;
  &.block {
    display: block;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
    margin-top: 20px;
  }
`;

export const SpeakerDetailMore = styled.button`
  position: relative;
  order: 4;
  margin: 0 auto;
  padding-right: 26px;
  font-family: 'SamsungOne-700';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 20px;
    height: 20px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-more.svg`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
  }
  &.is-appended:after {
    transform: translate(0, -50%) rotate(180deg);
  }
  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.42;
    padding-right: 20px;

    &:after {
      width: 16px;
      height: 16px;
    }
  }
`;
