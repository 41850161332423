import * as types from '../ActionTypes';

const initialState = {
  checkedTypes: [],
  checkedTopics: [],
  checkedMainCategories: [],
  isClear: false,
};

const SessionFilter = (state = initialState, action) => {
  switch (action.type) {
    case types.CLICK_FILTER_CLEAR: {
      return {
        ...state,
        checkedTypes: [],
        checkedTopics: [],
        checkedMainCategories: [],
        isClear: true,
      };
    }
    case types.SET_FILTER_TYPE: {
      let newTypes = [...state.checkedTypes];
      if (newTypes.indexOf(action.checkedType) === -1) {
        if (typeof action.checkedType === 'object') {
          newTypes = [];
          if (action.checkedType.length > 0) {
            const checkedType = action.checkedType.toString().split(',');
            newTypes.push(...checkedType);
          }
        } else {
          newTypes.push(action.checkedType);
        }
      } else {
        newTypes.splice(newTypes.indexOf(action.checkedType), 1);
      }
      return {
        ...state,
        checkedTypes: newTypes,
        isClear: false,
      };
    }
    case types.SET_FILTER_TOPIC: {
      let newTopics = [...state.checkedTopics];
      if (newTopics.indexOf(action.checkedTopic) === -1) {
        if (typeof action.checkedTopic === 'object') {
          newTopics = [];
          if (action.checkedTopic.length > 0) {
            const checkedTopic = action.checkedTopic.toString().split(',');
            newTopics.push(...checkedTopic);
          }
        } else {
          newTopics.push(action.checkedTopic);
        }
      } else {
        newTopics.splice(newTopics.indexOf(action.checkedTopic), 1);
      }
      return {
        ...state,
        checkedTopics: newTopics,
        isClear: false,
      };
    }
    case types.SET_FILTER_MAIN_CATEGORY: {
      let newMainCategories = [...state.checkedMainCategories];
      if (newMainCategories.indexOf(action.checkedMainCategory) === -1) {
        if (typeof action.checkedMainCategory === 'object') {
          newMainCategories = [];
          if (action.checkedMainCategory.length > 0) {
            const checkedMainCategory = action.checkedMainCategory
              .toString()
              .split(',');
            newMainCategories.push(...checkedMainCategory);
          }
        } else {
          newMainCategories.push(action.checkedMainCategory);
        }
      } else {
        newMainCategories.splice(
          newMainCategories.indexOf(action.checkedMainCategory),
          1
        );
      }
      return {
        ...state,
        checkedMainCategories: newMainCategories,
        isClear: false,
      };
    }
    default:
      return state;
  }
};

export default SessionFilter;
