import { combineReducers } from 'redux';
import sessionList from './SessionListReducer';
import userSession from './UserSessionReducer';
import sessionDetail from './SessionDetailReducer';
import speakersList from './SpeakersListReducer';
import speakerDetail from './SpeakerDetailReducer';
import sessionFilter from './SessionFilterReducer';
import subscribeData from './SubscribeReducer';
import bookmarkList from './UserBookmarkReducer';
import myPageData from './MyPageReducer';
import sessionLikeStatus from './UserLikeReducer';
import reservationData from './ReservationReducer';
import meta from './MetaReducer';
import metaTechSquare from './MetaTechSquareReducer';
import utils from './UtilReducer';

export default combineReducers({
  sessionList,
  userSession,
  sessionDetail,
  speakersList,
  speakerDetail,
  sessionFilter,
  subscribeData,
  bookmarkList,
  myPageData,
  sessionLikeStatus,
  reservationData,
  utils,
  meta,
  metaTechSquare,
});
