import styled from 'styled-components';

export const DetailContContainer = styled.div`
  padding: 120px 0 148px;
  background-color: #f6f6f6;

  .BannerBox {
    background-color: #fff;
  }

  @media screen and (max-width: 768px) {
    padding: 60px 0;
  }
`;

export const DetailContInner = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const SectionWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -12px;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const DetailContSpeaker = styled.div`
  padding-top: 100px;
  margin-bottom: 120px;

  & + & {
    margin-top: -20px;
    padding-top: 0;
  }
  @media (max-width: 1200px) {
    .Card {
      width: calc(33.333% - 24px);
    }
  }

  @media (max-width: 768px) {
    margin: 60px auto 60px;
    padding-top: 0;
    & + & {
      margin-top: 0;
      padding-top: 0;
    }
    .Card {
      width: calc(50% - 4px);
      margin: 0;

      // &.hover {
      .Text {
        .Text-desc {
          margin-top: 0;
          margin-bottom: 2px;
          font-size: 12px;
          line-height: 16px;
        }
        .Text-title {
          font-size: 14px;
          line-height: 20px;
        }
      }
      // }

      + .Card {
        margin-top: 16px;
      }
      &:nth-of-type(2n-1) {
        margin-right: 8px;
      }
      &:nth-of-type(2) {
        margin-top: 0;
      }

      .Text {
        position: absolute;
        z-index: 1;
        top: 0;
        padding: 16px;
      }

      .card_img {
        height: 60vw;
      }
    }
  }
`;

export const DetailContResurce = styled.div`
  position: relative;

  .title {
    margin-bottom: 4px;
  }

  .desc {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    .title {
      margin-bottom: 6px;
      font-size: 16px;
    }

    .desc {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const DetailContTech = styled.div`
  position: relative;

  .title {
    margin-bottom: 4px;
  }

  .desc {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    .title {
      margin-bottom: 6px;
      font-size: 16px;
    }

    .desc {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;
