// Keynote Page
export const KeynoteData = {
  PresentationTitle: 'Keynote',
  AbstractText:
    '<p>Explore the future of a smarter, safer, and more connected lifestyle at SDC23! Join us for an exclusive worldwide livestream as we reveal the latest cutting-edge innovations from Samsung.</p>',
  PresentationDateTimeStart: '10:00 AM PT',
  SessionType: 'keynote',
  Presenters: [
    {
      PresenterID: '01',
      PresenterFirstName: 'JH',
      PresenterLastName: 'Han',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_1_23.png`,
      hoverTitle: '',
      subject: 'Keynote',
      blBg: false,
    },
    {
      PresenterID: '02',
      PresenterFirstName: 'Jaeyeon',
      PresenterLastName: 'Jung',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_2_23.png`,
      hoverTitle:
        'Building meaningful home experiences with enhanced partnerships',
      subject: 'Keynote',
      blBg: false,
    },
    {
      PresenterID: '03',
      PresenterFirstName: 'Cathy',
      PresenterLastName: 'You',
      PresenterOrganization: 'Aqara',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_13_23.png`,
      hoverTitle:
        'Creating a Seamless, Future-Ready Smart Home with Aqara & Samsung SmartThings',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '04',
      PresenterFirstName: 'Anil',
      PresenterLastName: 'Yadav',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_8_23.png`,
      hoverTitle:
        'Bixby: The best, optimal voice platform for the Samsung ecosystem',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '05',
      PresenterFirstName: 'Shin-Chul',
      PresenterLastName: 'Baik',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_6_23.png`,
      hoverTitle:
        'ProvPresenterIDing a safe, secure & convenient cross-device environment',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '06',
      PresenterFirstName: 'Hobum',
      PresenterLastName: 'Kwon',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_11_23.png`,
      hoverTitle: 'Tizen Reboot - for the next 10 years',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '07',
      PresenterFirstName: 'Bongjun',
      PresenterLastName: 'Ko',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_3_23.png`,
      hoverTitle:
        'Tizen Screen -\nthe window connecting you to the world in every way',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '08',
      PresenterFirstName: 'Sally Hyesoon',
      PresenterLastName: 'Jeong',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_5_23.png`,
      hoverTitle: 'Expanding the Galaxy experience with the new One UI',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '09',
      PresenterFirstName: 'Hon',
      PresenterLastName: 'Pak',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_12_23.png`,
      hoverTitle:
        'Building a digital health ecosystem with home-centric connected care',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '10',
      PresenterFirstName: 'Pattie',
      PresenterLastName: 'Maes',
      PresenterOrganization: 'MIT',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_15_23.png`,
      hoverTitle: '',
      subject: 'Keynote',
      blBg: false,
    },
    {
      PresenterID: '11',
      PresenterFirstName: 'Nick',
      PresenterLastName: 'Holzherr',
      PresenterOrganization: 'Whisk, part of Samsung',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/MainSpeaker_14_23.png`,
      hoverTitle: 'Delivering the joy of cooking and eating with Food AI',
      subject: 'Keynote',
      blBg: true,
    },
  ],
};
