import styled, { css } from 'styled-components';

export const MainJoinusContainer = styled.div`
  position: relative;
  padding: 0 24px 160px;
  background-color: #f6f6f6;

  /* Main Tech Session 공통 타이틀 예외스타일 */
  .MainSectionTitleArea {
    br {
      display: block;
    }

    .Link {
      display: none;
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 0 24px 60px;
    .MainSectionTitleArea {
      br {
        display: none;
      }
    }
  }
`;

export const MainJoinusInner = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
`;

export const MainJoinusCardWrap = styled.div`
  position: relative;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: 768px) {
    padding: 0;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const MainJoinusCard = styled.div`
  position: relative;
  min-height: 248px;
  background-color: #fff;
  border-radius: 24px;
  padding: 40px 44px;
  box-sizing: border-box;
  overflow: hidden;

  > div {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right bottom;
    z-index: 0;
  }

  &:first-of-type {
    grid-row: 1 / span 2;
    background-color: #2088fd;
    height: 100%;
  }

  &:first-of-type:before {
    background-image: url(../images/sdc24-main-joinus-breadman.png);
    width: 503px;
    height: 365px;
  }

  &:nth-of-type(2) {
    background-color: #10b77b;
  }

  &:nth-of-type(2):before {
    background-image: url(../images/main-joinus-graphic-1.png);
    width: 236px;
    height: 206px;
  }

  &:nth-of-type(3) {
    background-color: #756bf1;
  }

  &:nth-of-type(3):before {
    background-image: url(../images/main-joinus-graphic-2.png);
    width: 222px;
    height: 206px;
  }

  @media screen and (max-width: 768px) {
    border-radius: 12px;
    padding: 20px;
    min-height: 168px;

    .hidden-m {
      display: none;
    }

    &:first-of-type {
      grid-row: 1;
      background-color: #2088fd;
      height: 275px;
    }

    &:first-of-type:before {
      width: 262px;
      height: 200px;
    }

    &:nth-of-type(2):before {
      width: 150px;
      height: 148px;
    }

    &:nth-of-type(3):before {
      width: 143px;
      height: 148px;
    }
  }
`;

export const MainJoinusCardTitle = styled.div`
  margin-bottom: 32px;
  font-family: 'samsungsharpsans-bold';
  font-size: 32px;
  line-height: 42px;
  color: #fff;

  @media screen and (max-width: 768px) {
    margin-bottom: 6px;
    font-size: 20px;
    line-height: 28px;
  }
`;

export const MainJoinusCardDesc = styled.div`
  font-size: 20px;
  line-height: 1.3;
  color: #fff;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
