import styled from 'styled-components';

export const MainSectionTitleArea = styled.div`
  padding: 140px 0 72px;
  text-align: center;
  // br{
  //   display: none;
  // }
  @media screen and (max-width: 768px) {
    padding: 60px 0 48px;
    br {
      display: block;
    }
  }
`;

export const Title = styled.div`
  margin-bottom: 14px;
  font-family: 'samsungsharpsans-bold';
  font-size: 54px;
  line-height: 1.3;
  @media screen and (max-width: 768px) {
    display: block;
    margin-bottom: 12px;
    font-size: 32px;
    line-height: 42px;
  }
`;

export const Desc = styled.div`
  margin-bottom: 36px;
  font-family: 'SamsungOne-600';
  font-size: 24px;
  line-height: 32px;
  color: #363636;

  > div {
    margin-bottom: 36px;
    font-family: 'SamsungOne-600';
    font-size: 24px;
    line-height: 32px;
    color: #363636;
  }
  @media screen and (max-width: 768px) {
    display: block;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;

    > div {
      display: block;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
