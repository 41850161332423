import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import ReactPlayer from 'react-player/lazy';
import {
  ClabContContainer,
  ClabContInner,
  ClabContentWrap,
  ClabContent,
  ClabContText,
  ClabContTitle,
  ClabContDesc,
  ClabContLink,
  ClabMore,
  SessionDesc,
  ClabbtnWrap,
  ClabContUtil,
} from './ClabContElements';
import './style.scss';

function SlideNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none' }}
      onClick={onClick}
    />
  );
}

function SlidePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none' }}
      onClick={onClick}
    />
  );
}

const ClabCont = ({ title, desc, session }) => {
  // more/less button
  const [more, setMore] = useState(false);
  const textHandler = () => {
    setMore(!more);
  };

  const [btnBlock, setBtnBlock] = useState(false);
  const SessionDescRef = useRef(null);

  useEffect(() => {
    const checkAndSetButtonBlock = () => {
      if (SessionDescRef.current) {
        const { clientHeight } = SessionDescRef.current;

        if (window.innerWidth < 768) {
          setBtnBlock(clientHeight >= 180);
        } else {
          setBtnBlock(clientHeight >= 72);
        }
      }
    };

    // 최초 로드 시와 창 크기 변경 시에도 체크
    checkAndSetButtonBlock();
    window.addEventListener('resize', checkAndSetButtonBlock);

    // Cleanup 함수 등록하여 컴포넌트가 unmount 될 때 리스너 제거
    return () => {
      window.removeEventListener('resize', checkAndSetButtonBlock);
    };
  }, []);

  const playerRef = useRef(null);
  const [nextSlide, setNextSlide] = useState(0);

  const settings = {
    dots: true,
    speed: 800,
    nextArrow: <SlideNextArrow />,
    prevArrow: <SlidePrevArrow />,
    centerMode: true,
    infinite: false,
    centerPadding: '0px',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    cssEase: 'ease-in-out',
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: false,
        },
      },
    ],
  };

  return (
    <ClabContContainer>
      <ClabContInner className="ClabContInner">
        <ClabContentWrap className="clab_slide">
          <Slider {...settings}>
            <ClabContent className="ClabContent">
              <div className="video-thumbnail">
                <iframe
                  id="clabplayer"
                  type="text/html"
                  src="https://www.youtube.com/embed/15oJvusd0Y0?si=T8OEHuF5t9aOn1Z4" // 동영상 URL 사용
                  title="video"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            </ClabContent>
            <ClabContent className="ClabContent">
              <img
                src={`${process.env.PUBLIC_URL}/images/EventClab-img-01.png`}
                alt=""
              />
            </ClabContent>
            <ClabContent className="ClabContent">
              <img
                src={`${process.env.PUBLIC_URL}/images/EventClab-img-02.png`}
                alt=""
              />
            </ClabContent>
            <ClabContent className="ClabContent">
              <img
                src={`${process.env.PUBLIC_URL}/images/EventClab-img-03.png`}
                alt=""
              />
            </ClabContent>
          </Slider>
        </ClabContentWrap>

        {/* Title Area */}
        <ClabContTitle>
          <div className="title">{title}</div>
        </ClabContTitle>

        {/* On the session detail page, use the Sessiondesc component instead of the ClabContDesc */}
        <SessionDesc
          ref={SessionDescRef}
          className={more ? 'SessionDesc is-appended' : 'SessionDesc'}
          dangerouslySetInnerHTML={{ __html: desc }}
        />

        {/* More button in sessionDetail page */}
        <ClabbtnWrap>
          <ClabMore onClick={textHandler} className={more ? 'is-appended' : ''}>
            {more ? 'Less' : 'More'}
          </ClabMore>
        </ClabbtnWrap>

        {/* Type badge, Description */}
        <ClabContText
          className={session ? 'ClabContText is-session' : 'ClabContText'}
        >
          <ClabContUtil>
            <ClabContLink
              className="ClabContLink"
              href="https://samsungclab.com/"
              target="_blank"
            >
              Go to Samsung C-Lab
            </ClabContLink>
          </ClabContUtil>
        </ClabContText>
      </ClabContInner>
    </ClabContContainer>
  );
};

export default ClabCont;
