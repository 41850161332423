import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Lottie from 'lottie-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import arrLottie from '../../lottie/plusbtn_white.json';
import {
  MainEngagementWrap,
  MainEngagementArea,
  MainEngagementBoxBg,
  MainEngagementBox,
  MainEngagementBlur,
  MainEngagementBoxInner,
  MainEngagementBoxInfo,
  MainEngagementTitle,
  MainEngagementDate,
  MainEngagementText,
  Button,
} from './MainEngagementElement';
import ButtonLink from '../ButtonLink';
import MainSectionTitle from '../MainSectionTitle';

const MainEngagementData = [
  {
    id: 1,
    title: 'Tech Square',
    date: 'October 5, 11:10 AM',
    desc: 'Talk with product experts, experience innovations in Tech Square. Catch up on new updates from Samsung platforms and OS like SmartThings, Knox and Tizen, Mobile & Screen Experience, Home & Health Experience, Sustainability.',
    bg: `${process.env.PUBLIC_URL}/images/MainEngagement_1.svg`,
    mobileBg: `${process.env.PUBLIC_URL}/images/MainEngagement_m_1.png`,
    url: '/event/tech-square',
    hideBtn: true,
    showBtn: 'phase3',
    // theme : 'bright'
  },
  {
    id: 2,
    title: 'Samsung C-Lab',
    date: 'October 5, 11:30 AM',
    desc: 'Meet six passionate entrepreneurs and start-ups accelerated by Samsung C-Lab, an in-house venture and start-up acceleration program. These start-ups are making waves in the healthcare and AI industries, and are here to showcase their latest innovations.',
    bg: `${process.env.PUBLIC_URL}/images/MainEngagement_2.svg`,
    mobileBg: `${process.env.PUBLIC_URL}/images/MainEngagement_m_2.png`,
    url: '/event/samsung-clab',
    hideBtn: true,
    showBtn: 'phase3',
    // theme : 'bright'
  },
  {
    id: 3,
    title: 'Hacker’s Playground',
    date: 'October 5, 11:30 AM',
    desc: 'Discover another side of writing secure programs. Hacker’s playground is a casual hacking competition that anyone can try, offering an opportunity to practice while also providing self-study materials geared toward each level.',
    bg: `${process.env.PUBLIC_URL}/images/MainEngagement_3.svg`,
    mobileBg: `${process.env.PUBLIC_URL}/images/MainEngagement_m_3.png`,
    url: 'engagement3',
    hideBtn: true,
    // theme : 'bright'
  },
  {
    id: 4,
    title: 'Bits & Bytes',
    date: 'October 5, 6:00 PM',
    desc: 'After the final mega session, indulge in the official SDC23 after-party. "Bits and Bytes" marks the event’s culmination, featuring an exciting live performance, enjoyable arcade games, and a lucky draw.',
    bg: `${process.env.PUBLIC_URL}/images/MainEngagement_4.svg`,
    mobileBg: `${process.env.PUBLIC_URL}/images/MainEngagement_m_4.png`,
    url: 'engagement4',
    hideBtn: true,
    // theme : 'bright'
  },
];

const EngagementItem = ({ item, idx, hideBtn }) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 769);

  // Arrow lottie button
  const [isHovered, setIsHovered] = useState(false);
  const shouldUseLottie = window.innerWidth >= 768;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <MainEngagementBox
      key={idx}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {hideBtn ? (
        <div className="MainEngagementBoxLink">
          <MainEngagementBlur className="Blur" />
          {isDesktop ? (
            <MainEngagementBoxBg className="MainEngagementBoxBg">
              <img className="MainEngagementImg" src={item.bg} alt="" />
            </MainEngagementBoxBg>
          ) : (
            <MainEngagementBoxBg
              style={{ backgroundImage: `url(${item.mobileBg})` }}
            />
          )}
          <MainEngagementBoxInner>
            <MainEngagementBoxInfo>
              <MainEngagementTitle className={item.theme}>
                {item.title.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index !== item.title.split('\n').length - 1 && <br />}
                  </React.Fragment>
                ))}
              </MainEngagementTitle>
              <MainEngagementDate className={item.theme}>
                {item.date}
              </MainEngagementDate>

              {/* <span className='sdcStack'><img src={process.env.PUBLIC_URL + '/images/more-link.svg'} alt="ico"/></span> */}
            </MainEngagementBoxInfo>
            <MainEngagementText className="MainEngagementText">
              <p>{item.desc}</p>
            </MainEngagementText>
          </MainEngagementBoxInner>

          <Button className={`sdcStack ${hideBtn ? 'hideBtn' : ''}`}>
            {shouldUseLottie && (
              <Lottie
                key={isHovered ? 'hovered' : 'not-hovered'}
                animationData={arrLottie}
                loop={false}
                autoplay={isHovered}
                onComplete={() => {
                  // Lottie 애니메이션이 끝났을 때 호출되는 함수
                }}
              />
            )}
            {!shouldUseLottie && (
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
                alt="ic"
              />
            )}
          </Button>
        </div>
      ) : (
        <Link to={item.url} className="MainEngagementBoxLink">
          {/* <div className='MainEngagementBoxLink'> */}
          <MainEngagementBlur className="Blur" />
          {isDesktop ? (
            <MainEngagementBoxBg className="MainEngagementBoxBg">
              <img className="MainEngagementImg" src={item.bg} alt="" />
            </MainEngagementBoxBg>
          ) : (
            <MainEngagementBoxBg
              style={{ backgroundImage: `url(${item.mobileBg})` }}
            />
          )}
          <MainEngagementBoxInner>
            <MainEngagementBoxInfo>
              <MainEngagementTitle className={item.theme}>
                {item.title.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index !== item.title.split('\n').length - 1 && <br />}
                  </React.Fragment>
                ))}
              </MainEngagementTitle>
              <MainEngagementDate className={item.theme}>
                {item.date}
              </MainEngagementDate>

              {/* <span className='sdcStack'><img src={process.env.PUBLIC_URL + '/images/more-link.svg'} alt="ico"/></span> */}
            </MainEngagementBoxInfo>
            <MainEngagementText className="MainEngagementText">
              <p>{item.desc}</p>
            </MainEngagementText>
          </MainEngagementBoxInner>

          <Button className={`sdcStack ${hideBtn ? 'hideBtn' : ''}`}>
            {shouldUseLottie && (
              <Lottie
                key={isHovered ? 'hovered' : 'not-hovered'}
                animationData={arrLottie}
                loop={false}
                autoplay={isHovered}
                onComplete={() => {
                  // Lottie 애니메이션이 끝났을 때 호출되는 함수
                }}
              />
            )}
            {!shouldUseLottie && (
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
                alt="ic"
              />
            )}
          </Button>
          {/* </div> */}
        </Link>
      )}
      {/* Pahse2 에서는 링크 제거 <Link to={item.url} className='MainEngagementBoxLink'> */}
    </MainEngagementBox>
  );
};

const MainEngagement = () => {
  // phase4 유무 체크
  const phase = useSelector((state) => state.meta.data.phase);
  const [phase4, setPhase4] = useState(false);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 769);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 769);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  // fade effect
  const MainBannerRef = useRef();
  const bannerTl = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // macaron ani
    bannerTl.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: MainBannerRef.current,
          scrub: true,
          start: 'top bottom',
          end: '40% center',
        },
      })
      .fromTo(
        MainBannerRef.current,
        { opacity: '0' },
        { opacity: '1', duration: 1 }
      );

    return () => {
      // ScrollTrigger.getAll().forEach((t) => t.kill());
      bannerTl.current.kill();
    };
  }, []);

  return (
    <>
      <MainEngagementWrap>
        <MainSectionTitle
          title="On-site Events"
          desc={
            <div>Come and join us and experience these events in SDC23</div>
          }
          linkText="Go to Event"
          buttonUrl="/event"
        />
        <MainEngagementArea ref={MainBannerRef}>
          {phase4 ? (
            // phase4에서 Hackers Playgroud 배너 삭제
            <MainEngagementBox>
              <MainEngagementBlur className="Blur" />
              {isDesktop ? (
                <MainEngagementBoxBg
                  style={{
                    backgroundImage: `url(${MainEngagementData[1].bg})`,
                  }}
                />
              ) : (
                <MainEngagementBoxBg
                  style={{
                    backgroundImage: `url(${MainEngagementData[1].mobileBg})`,
                  }}
                />
              )}
              <MainEngagementBoxInner>
                <MainEngagementTitle className={MainEngagementData[1].theme}>
                  {MainEngagementData[1].title}
                </MainEngagementTitle>
                <MainEngagementDate className={MainEngagementData[1].theme}>
                  {MainEngagementData[1].date}
                </MainEngagementDate>
                <div>
                  <ButtonLink
                    className="sdcStack"
                    url={MainEngagementData[1].url}
                    color={
                      MainEngagementData[1].theme === 'dark' ? 'white' : 'black'
                    }
                    size="medium"
                  >
                    {MainEngagementData[1].btnText}
                  </ButtonLink>
                </div>
              </MainEngagementBoxInner>
            </MainEngagementBox>
          ) : (
            MainEngagementData.map((item, idx) => (
              <EngagementItem
                key={idx}
                item={item}
                idx={idx}
                hideBtn={
                  phase &&
                  (phase === 'phase3' || phase === 'phase4') &&
                  item?.showBtn === 'phase3'
                    ? false
                    : item.hideBtn
                }
              />
            ))
          )}
        </MainEngagementArea>
      </MainEngagementWrap>
    </>
  );
};

export default MainEngagement;
