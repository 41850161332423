// get main list
export const GET_MAIN_LIST_REQUEST = 'GET_MAIN_LIST_REQUEST';
export const GET_MAIN_LIST_SUCCESS = 'GET_MAIN_LIST_SUCCESS';
export const GET_MAIN_LIST_ERROR = 'GET_MAIN_LIST_ERROR';

// get userSession
export const GET_USER_SESSION_REQUEST = 'GET_USER_SESSION_REQUEST';
export const UPDATE_USER_SESSION = 'UPDATE_USER_SESSION';
export const UPDATE_USER_SESSION_ERROR = 'UPDATE_USER_SESSION_ERROR';
export const UPDATE_COOKIES_GDPR_REQUEST = 'UPDATE_COOKIES_GDPR_REQUEST';
export const UPDATE_COOKIES_GDPR = 'UPDATE_COOKIES_GDPR';

// get session list
export const GET_SESSION_LIST_REQUEST = 'GET_SESSION_LIST_REQUEST';
export const GET_SESSION_LIST_SUCCESS = 'GET_SESSION_LIST_SUCCESS';
export const GET_SESSION_LIST_ERROR = 'GET_SESSION_LIST_ERROR';
export const SESSION_ROOM_STATUS_UPDATE = 'SESSION_ROOM_STATUS_UPDATE';

// get session detail
export const GET_SESSION_DETAIL_REQUEST = 'GET_SESSION_DETAIL_REQUEST';
export const GET_SESSION_DETAIL_SUCCESS = 'GET_SESSION_DETAIL_SUCCESS';
export const GET_SESSION_DETAIL_ERROR = 'GET_SESSION_DETAIL_ERROR';

// get keynote data
export const GET_KEYNOTE_REQUEST = 'GET_KEYNOTE_REQUEST';
export const GET_KEYNOTE_SUCCESS = 'GET_KEYNOTE_SUCCESS';
export const GET_KEYNOTE_ERROR = 'GET_KEYNOTE_ERROR';

// get Speakers List
export const GET_SPEAKERS_LIST_REQUEST = 'GET_SPEAKERS_LIST_REQUEST';
export const GET_SPEAKERS_LIST_SUCCESS = 'GET_SPEAKERS_LIST_SUCCESS';
export const GET_SPEAKERS_LIST_ERROR = 'GET_SPEAKERS_LIST_ERROR';

// get Speaker Detail
export const GET_SPEAKER_DETAIL_REQUEST = 'GET_SPEAKER_DETAIL_REQUEST';
export const GET_SPEAKER_DETAIL_SUCCESS = 'GET_SPEAKER_DETAIL_SUCCESS';
export const GET_SPEAKER_DETAIL_ERROR = 'GET_SPEAKER_DETAIL_ERROR';

// set Filter
export const SET_FILTER_TYPE = 'SET_FILTER_TYPE';
export const SET_FILTER_TOPIC = 'SET_FILTER_TOPIC';
export const SET_FILTER_MAIN_CATEGORY = 'SET_FILTER_MAIN_CATEGORY';
export const CLICK_FILTER_CLEAR = 'CLICK_FILTER_CLEAR';

// get session filter
export const GET_SESSION_FILTER_REQUEST = 'GET_SESSION_FILTER_REQUEST';
export const GET_SESSION_FILTER_SUCCESS = 'GET_SESSION_FILTER_SUCCESS';
export const GET_SESSION_FILTER_ERROR = 'GET_SESSION_FILTER_ERROR';

// newsletter
export const SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_ERROR = 'SUBSCRIBE_ERROR';

// get bookmark list
export const GET_BOOKMARK_LIST = 'GET_BOOKMARK_LIST';

// add bookmark
export const ADD_BOOKMARK_REQUEST = 'ADD_BOOKMARK_REQUEST';
export const ADD_BOOKMARK_SUCCESS = 'ADD_BOOKMARK_SUCCESS';
export const ADD_BOOKMARK_ERROR = 'ADD_BOOKMARK_ERROR';

// delete bookmark
export const DELETE_BOOKMARK_REQUEST = 'DELETE_BOOKMARK_REQUEST';
export const DELETE_BOOKMARK_SUCCESS = 'DELETE_BOOKMARK_SUCCESS';
export const DELETE_BOOKMARK_ERROR = 'DELETE_BOOKMARK_ERROR';

// bookmark count
export const UPDATE_BOOKMARK_COUNT = 'UPDATE_BOOKMARK_COUNT';
export const UPDATE_KEYNOTE_COUNT = 'UPDATE_KEYNOTE_COUNT';

// my page actions
export const GET_MY_PAGE_REQUEST = 'GET_MY_PAGE_REQUEST';
export const GET_MY_PAGE_SUCCESS = 'GET_MY_PAGE_SUCCESS';
export const GET_MY_PAGE_ERROR = 'GET_MY_PAGE_ERROR';

// session liked status check
export const GET_LIKED_SESSION = 'GET_LIKED_SESSION';

// liked session
export const LIKE_SESSION_REQUEST = 'LIKE_SESSION_REQUEST';
export const LIKE_SESSION_SUCCESS = 'LIKE_SESSION_SUCCESS';
export const LIKE_SESSION_ERROR = 'LIKE_SESSION_ERROR';

// unliked session
export const UNLIKE_SESSION_REQUEST = 'UNLIKE_SESSION_REQUEST';
export const UNLIKE_SESSION_SUCCESS = 'UNLIKE_SESSION_SUCCESS';
export const UNLIKE_SESSION_ERROR = 'UNLIKE_SESSION_ERROR';

// like count
export const UPDATE_LIKE_COUNT = 'UPDATE_LIKE_COUNT';

// get reservation list
export const GET_RESERVATION_LIST = 'GET_RESERVATION_LIST';

// add reservation
export const ADD_RESERVATION_REQUEST = 'ADD_RESERVATION_REQUEST';
export const ADD_RESERVATION_SUCCESS = 'ADD_RESERVATION_SUCCESS';
export const ADD_RESERVATION_ERROR = 'ADD_RESERVATION_ERROR';

// delete reservation
export const DELETE_RESERVATION_REQUEST = 'DELETE_RESERVATION_REQUEST';
export const DELETE_RESERVATION_SUCCESS = 'DELETE_RESERVATION_SUCCESS';
export const DELETE_RESERVATION_ERROR = 'DELETE_RESERVATION_ERROR';

// terms download
export const TERMS_DOWNLOAD_REQUEST = 'TERMS_DOWNLOAD_REQUEST';
export const TERMS_DOWNLOAD_SUCCESS = 'TERMS_DOWNLOAD_SUCCESS';
export const TERMS_DOWNLOAD_ERROR = 'TERMS_DOWNLOAD_ERROR';

// cookie gdpr close button
export const COOKIE_GDPR_MODAL_CLOSE = 'COOKIE_GDPR_MODAL_CLOSE';

// get session room
export const GET_SESSION_ROOM_REQUEST = 'GET_SESSION_ROOM_REQUEST';
export const GET_SESSION_ROOM_SUCCESS = 'GET_SESSION_ROOM_SUCCESS';
export const GET_SESSION_ROOM_ERROR = 'GET_SESSION_ROOM_ERROR';

export const MAIN_STATUS_UPDATE = 'MAIN_STATUS_UPDATE';
export const GET_MAIN_STATUS_REQUEST = 'GET_MAIN_STATUS_REQUEST';

// online form > unregister
export const ONLINE_UNREGISTER_REQUEST = 'ONLINE_UNREGISTER_REQUEST';
export const ONLINE_UNREGISTER_SUCCESS = 'ONLINE_UNREGISTER_SUCCESS';
export const ONLINE_UNREGISTER_ERROR = 'ONLINE_UNREGISTER_ERROR';
export const ONLINE_UNREGISTER_RESET = 'ONLINE_UNREGISTER_RESET';

// get reservation item
export const GET_RESERVATION_ITEM = 'GET_RESERVATION_ITEM';

// reset motion data
export const RESET_RESERVATION_MOTION = 'RESET_RESERVATION_MOTION';
export const RESET_BOOKMARK_MOTION = 'RESET_BOOKMARK_MOTION';
export const RESET_LIKE_MOTION = 'RESET_LIKE_MOTION';

// update like data
export const UPDATE_LIKE_LIST = 'UPDATE_LIKE_LIST';

// reservation notification
export const OPEN_RESERVATION_NOTI_DATA = 'OPEN_RESERVATION_NOTI_DATA';
export const CLOSE_RESERVATION_NOTI_DATA = 'CLOSE_RESERVATION_NOTI_DATA';

// meta data
export const GET_META_LIVE_REQUEST = 'GET_META_LIVE_REQUEST';
export const GET_META_LIVE_ERROR = 'GET_META_LIVE_ERROR';
export const GET_META_LIVE_SUCCESS = 'GET_META_LIVE_SUCCESS';

export const GET_META_TECH_SQUARE_REQUEST = 'GET_META_TECH_SQUARE_REQUEST';
export const GET_META_TECH_SQUARE_ERROR = 'GET_META_TECH_SQUARE_ERROR';
export const GET_META_TECH_SQUARE_SUCCESS = 'GET_META_TECH_SQUARE_SUCCESS';
