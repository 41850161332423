import React from 'react';
import { Link } from 'react-router-dom';
import {
  DetailHeroContainer,
  DetailHeroInner,
  DetailHeroText,
  DetailHeroTitle,
  DetailHeroSamsung,
} from './DetailHeroElements';

const DetailHero = ({
  bgPc,
  bgMo,
  bgColor,
  title,
  desc,
  path,
  depth,
  depth2,
  is2Depth,
}) => {
  return (
    <DetailHeroContainer bgColor={bgColor} bgPc={bgPc} bgMo={bgMo}>
      <DetailHeroInner>
        <DetailHeroText>
          <DetailHeroTitle>{title}</DetailHeroTitle>
          {is2Depth ? (
            <DetailHeroSamsung className="is-2depth">
              <Link to="/">Home</Link>
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-breadcrumb.svg`}
                alt="breadcrumb"
              />
              <Link to="/sessions">Session</Link>
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-breadcrumb.svg`}
                alt="breadcrumb"
              />
              <Link to="#" className="current">
                {depth2}
              </Link>
            </DetailHeroSamsung>
          ) : (
            <DetailHeroSamsung>
              <Link to="/">Home</Link>
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-breadcrumb.svg`}
                alt="breadcrumb"
              />
              <Link to="#" className="current">
                {path}
              </Link>
            </DetailHeroSamsung>
          )}
        </DetailHeroText>
      </DetailHeroInner>
    </DetailHeroContainer>
  );
};

export default DetailHero;
