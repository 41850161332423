import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from 'react-redux';
import {
  ScheduleContainer,
  ScheduleInner,
  ScheduleTitle,
  ScheduleBanner,
  ScheduleWrap,
  ScheduleGrid,
  EngagementBox,
  EngagementBoxBg,
  EngagementBoxInner,
  EngagementTitle,
  EngagementText,
  GridInfo,
  GridInfoList,
  GridInfoItem,
  GridInfoMore,
} from './ScheduleContElements';
import Button from '../Button';
import withRouter from '../Router/withRouter';
import { convertURL } from '../../utils/Functions';

const GridItem = ({
  title,
  time,
  speaker,
  bHall,
  w50,
  w33,
  isFirst,
  disabledLink,
  h190,
}) => {
  const classNames = `GridInfoItem ${bHall ? 'is-Bhall' : ''} ${
    w50 ? 'w-50' : ''
  } ${w33 ? 'w-33' : ''} ${isFirst ? 'first' : ''} ${h190 ? 'h-190' : ''}`;

  return (
    <GridInfoItem className={classNames}>
      <div className="item_title">{title}</div>
      <div className="item_desc">
        <span className="item_time">{time}</span>
        {speaker ? <span className="item_speaker">{speaker}</span> : null}
      </div>
      {!disabledLink ? (
        <Link
          to={`/sessions/${convertURL(title.replace(/\[.*] /, ''))}`}
          className="item_link"
        >
          View More
          <span className="icon" />
        </Link>
      ) : null}
    </GridInfoItem>
  );
};

const ScheduleCont = (props) => {
  // Phase2.5부터 true로 변경
  const [after, setAfter] = useState(true);
  const phase = useSelector((state) => state.meta.data.phase);

  // More button handler - PC
  const [more, setMore] = useState(false);
  const textHandler = () => {
    setMore(!more);
  };

  const [more2, setMore2] = useState(false);
  const textHandler2 = () => {
    setMore2(!more2);
  };

  // AHall More button handler - MO
  const [moreA, setMoreA] = useState(true);
  const moreAHandler = () => {
    setMoreA(!moreA);
  };

  // BHall More button handler - MO
  const [moreB, setMoreB] = useState(false);
  const moreBHandler = () => {
    setMoreB(!moreB);
  };

  // CHall More button handler - MO
  const [moreC, setMoreC] = useState(false);
  const moreCHandler = () => {
    setMoreC(!moreC);
  };

  const [moreTheater, setMoreTheater] = useState(false);
  const moreTheaterHandler = () => {
    setMoreTheater(!moreTheater);
  };

  // test data
  const sessionDataA = [
    {
      id: 1,
      title: 'Works With SmartThings 2.0',
      time: '11:30 - 12:00',
      speaker: 'Daniel Moneta',
    },
    {
      id: 2,
      title: 'Revolutionizing App Design and Development: PRISM',
      time: '12:10 - 12:40 ',
      speaker: 'Hyunseok Cha, Donghun Shin',
    },
    {
      id: 3,
      title: 'Advanced Professional Video Codec',
      time: '12:50 - 13:20',
      speaker: 'Kwang Pyo Choi, Madhukar Budagavi, Rajan Joshi',
    },
    {
      id: 4,
      title: 'Relumino for People with Low Vision',
      time: '13:30 - 14:00',
      speaker: 'Jongho Kim',
    },
    {
      id: 5,
      title: 'Bringing AI/ML into Production: Where do we stand?',
      time: '14:50 - 15:20',
      speaker: 'Ben Asaf',
    },
    {
      id: 6,
      title:
        'The Role of AI & IoT in making Classrooms Smarter & enabling Personalized Education',
      time: '15:30 - 16:00',
      speaker: 'Pankaj Agarwai',
    },
    {
      id: 7,
      title: 'Next-Gen Pet Care with Dr.Tail',
      time: '16:10 - 16:40 ',
      speaker: 'Daehwa Rayer Lee',
    },
  ];

  const sessionDataB = {
    id: 2,
    title: 'Tizen everywhere',
    time: '11:30 - 11:55',
  };

  const sessionDataA02 = [
    {
      id: 'A1',
      title:
        'Open Source On-Device AI SW Platform for Optimized Executions, Personalization, Pipelines, and MLOps',
      time: '11:30 - 12:00',
      speaker: 'Hyeonseok Lee',
    },
    {
      id: 'A2',
      title: 'Developer Center Announcement',
      time: '12:10 - 12:40',
      speaker: 'Aaron Swift',
    },
    {
      id: 'A3',
      title: 'SmartThings Intelligence Platform',
      time: '13:30 - 14:00',
      speaker: 'Dongwan Kang, Dongyeon Jeong, Erik Jorgensen',
    },
    {
      id: 'A4',
      title: 'SmartThings and Matter',
      time: '14:10 - 14:40',
      speaker: 'Charles Kim, Zach Michel',
    },
    {
      id: 'A5',
      title: 'SmartThings Find service update',
      time: '14:50 - 15:20',
      speaker: 'Jiman Cho, Dongsun Lim',
    },
    {
      id: 'A7',
      title: 'SmartThings Enterprise API for Multifamily',
      time: '16:10 - 16:40',
      speaker: 'Steve Herbert, Joan Soskin',
    },
  ];

  const sessionDataB02 = [
    {
      id: 'B1',
      title: "What's new in Tizen 8.0",
      time: '11:30 - 12:00',
      speaker: 'Seikwon Kim, Jihoon Lee',
    },
    {
      id: 'B2',
      title: 'Tizen SDK',
      time: '12:10 - 12:40',
      speaker: 'Euna Ko, Dongkuyun Son, Markus Höckner',
    },
    {
      id: 'B3',
      title: 'What’s new in Tizen Enterprise Platform',
      time: '13:30 - 14:00',
      speaker: 'Ryan Yunil Kim, Junho Lee',
    },
    {
      id: 'B4',
      title: 'Why Tizen Licensing?',
      time: '14:10 - 14:40',
      speaker: 'Charles Lim, Olivier Li',
    },
    {
      id: 'B5',
      title: 'Experience Interactive Advertisement on Samsung TV Plus',
      time: '14:50 - 15:20',
      speaker: 'Youngjun Kim',
    },
    {
      id: 'B6',
      title: 'Open License of SolarCell Remote Tech',
      time: '16:10 - 16:40',
      speaker: 'KwanYoung Kim',
    },
    {
      id: 'B7',
      title:
        'Generative AI for coding: Improving productivity in SW development',
      time: '16:50 - 17:20',
      speaker: 'Kangwook Lee',
      h190: 'h190',
    },
    {
      id: 'B8',
      title: 'AI & MLOps infrastructure for enterprise-grade LLMs',
      time: '17:30 - 18:00',
      speaker: 'Yongseon Lee',
      h190: 'h190',
    },
  ];

  const sessionDataC02 = [
    {
      id: 'C1',
      title: 'What’s new in foldables',
      time: '11:30 - 12:00',
      speaker: 'Byungwoo Min',
    },
    {
      id: 'C2',
      title: "What's New and Next in Watch Face Studio 2023",
      time: '12:10 - 12:40',
      speaker: 'Changwoo Kim',
    },
    {
      id: 'C3',
      title: 'Samsung Wallet: Expanding the Ecosystem',
      time: '13:30 - 14:40',
      speaker: 'Robert White, Rajiv Kavuri',
    },
    {
      id: 'C4',
      title: 'Programmable Picture Quality Enhancement Architecture',
      time: '14:50 - 15:20 ',
      speaker: 'Sanghun Kim',
    },
    {
      id: 'C5',
      title: 'S/W Platform for Digital Appliance: Part I. TizenRT',
      time: '16:10 - 16:40',
      speaker: 'Seongil Hahm',
    },
    {
      id: 'C6',
      title: 'S/W Platform for Digital Appliance: Part II. Tizen',
      time: '16:50 - 17:20',
      speaker: 'Kyungyeon Won',
      h190: 'h190',
    },
    {
      id: 'C7',
      title:
        'Trends & challenges in AI/LLM business, and how Samsung co-operates with Start-ups',
      time: '17:30 - 18:00',
      speaker: 'Michael Hwang',
      h190: 'h190',
    },
  ];

  const MainEngagementData = [
    {
      id: 1,
      title: 'Code Lab & Samsung Developers Forum',
      discription:
        'Visit Samsung Developer Portal: Learn more about our latest tools and SDKs at CodeLab, or ask questions and get involved with the developer community at the Samsung Developers Forums!',
      bg: `${process.env.PUBLIC_URL}/images/Engagement.png`,
      mobileBg: `${process.env.PUBLIC_URL}/images/Engagement_mo.png`,
      url1: '',
      url2: '',
      btnText1: 'Go to Code Lab',
      btnText2: 'Go to Q&A Forum',
      theme: 'dark',
    },
  ];

  const keynoteStageData = [
    {
      title: 'Gamepad on Tizen TV',
      time: '11:30 - 12:00',
      speaker: 'Rongchun Wu',
    },
    {
      title: 'HDR10+ Gaming',
      time: '12:10 - 12:40',
      speaker: 'Seongnam Oh, Suzie Hyun, Gennady Petrov',
    },
    {
      title: 'Games with Samsung Galaxy',
      time: '13:30 - 15:20',
      speaker:
        'Michael Barnes, David Berger, Dongmin Chwa, Jong Hyuk Woo, Sangchul Song',
    },
    {
      title:
        'Exploring the Digital Health Ecosystem: Samsung Health as Digital Front Door',
      time: '16:10 - 18:00',
      speaker: 'Hon Pak, Alex Gao, James Geraci, Sungchull Lee, Eric Song',
    },
  ];

  const [isDesktop, setDesktop] = useState(window.innerWidth > 769);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 769);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  const hoverEnter = (e) => {
    e.target.classList.add('is-active');
  };
  const hoverLeave = (e) => {
    e.target.classList.remove('is-active');
  };

  // gsap interaction
  const tl = useRef();
  const tabRef = useRef();
  const gridRef = useRef();

  // const [tab, setTab] = useState(false);
  // const tabHandler = () => {
  //   setTab(!tab);
  // };

  const { location } = props;
  const { hash } = location;

  const [tabIdx, setTab] = useState(0);
  const tabHandler = (index) => {
    const { navigate } = props;
    setTab(index);
    if (index === 1) {
      navigate('#online');
    } else {
      navigate('');
    }
  };

  useLayoutEffect(() => {
    if (hash === '#online') {
      setTab(1);
    } else {
      setTab(0);
    }
  }, [hash]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    tl.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: gridRef.current,
        },
      })
      .fromTo(
        gridRef.current,
        { opacity: 0, y: 40 },
        { opacity: 1, y: 0, duration: 0.3 }
      );

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
      tl.current.kill();
    };
  }, [tabIdx]);

  return (
    <ScheduleContainer>
      <ScheduleInner>
        <Tabs onSelect={(index) => tabHandler(index)} selectedIndex={tabIdx}>
          <TabList>
            <Tab>On-site</Tab>
            <Tab>Online</Tab>
          </TabList>

          {/* On-Site Tab */}
          <TabPanel ref={tabRef}>
            <ScheduleWrap ref={gridRef}>
              <ScheduleGrid
                className="on-site"
                p3={phase === 'phase3' || phase === 'phase4'}
              >
                <div className="index">
                  <div className="index_text">08:30 PT</div>
                </div>
                <div className="item_wrap item-01">
                  <div className="box">
                    <div className="title">Registration</div>
                    <div className="time">08:30 - 10:00</div>
                  </div>
                </div>
                <div className="index">
                  <div className="index_text">10:00 PT</div>
                </div>
                <div className="item_wrap item-02">
                  <Link
                    to="/keynote"
                    className="box item-02"
                    onMouseEnter={hoverEnter}
                    onMouseLeave={hoverLeave}
                  >
                    <div className="title">Keynote</div>
                    <div className="time">10:00 - 11:10</div>
                  </Link>
                </div>
                <div className="index">
                  <div className="index_text">11:10 PT</div>
                </div>
                <div className="item_wrap item-07">
                  <Link
                    className="box"
                    to="/event"
                    onMouseEnter={hoverEnter}
                    onMouseLeave={hoverLeave}
                  >
                    <div className="title">Tech Square + Networking</div>
                    <div className="time">11:10 - 18:00</div>
                  </Link>
                </div>

                <div className="index">
                  <div className="index_text">11:30 PT</div>
                </div>
                <div className="item_wrap item-mega">
                  <Link
                    to="/sessions?filter=Mega"
                    className="box"
                    onMouseEnter={hoverEnter}
                    onMouseLeave={hoverLeave}
                  >
                    <div className="title">Mega Session</div>
                    <div className="time">
                      11:30 - 18:00
                      <div className="group-line" />
                    </div>
                  </Link>
                  {after ? (
                    <GridInfo>
                      <div className="inner h-auto">
                        <GridInfoList className="w-100">
                          <div className="title">Keynote Stage</div>
                          <div className="grid_session_flex h-auto">
                            {keynoteStageData.map((item, idx) => (
                              <GridItem
                                key={item.id}
                                title={item.title}
                                time={item.time}
                                speaker={item.speaker}
                                disabledLink={item.disabledLink}
                                w50
                              />
                            ))}
                          </div>
                        </GridInfoList>
                      </div>
                    </GridInfo>
                  ) : null}
                </div>

                <div className="index">
                  <div className="index_text" />
                </div>
                <div className="item_wrap item-mega">
                  <Link
                    to="/sessions?filter=Tech"
                    className="box"
                    onMouseEnter={hoverEnter}
                    onMouseLeave={hoverLeave}
                  >
                    <div className="title">Tech Session</div>
                    <div className="time">
                      11:30 - 18:00
                      <div className="group-line" />
                    </div>
                  </Link>
                  {after ? (
                    <GridInfo className={more ? 'is-appended' : ''}>
                      <div className="inner has-first">
                        <GridInfoList
                          className={`w-33 ${moreA ? 'is-appended' : ''}`}
                        >
                          <button className="title" onClick={moreAHandler}>
                            Session Room 1
                            <span className="info_btn_icon" />
                          </button>
                          <div className="grid_session">
                            {sessionDataA02.map((item, idx) => (
                              <GridItem
                                isFirst={idx === 0}
                                h190={idx === 6}
                                key={item.id}
                                title={item.title}
                                time={item.time}
                                speaker={item.speaker}
                                disabledLink={item.disabledLink}
                                w33
                              />
                            ))}
                          </div>
                        </GridInfoList>
                        <GridInfoList
                          className={`w-33 ${moreB ? 'is-appended' : ''}`}
                        >
                          <button className="title" onClick={moreBHandler}>
                            Session Room 2
                            <span className="info_btn_icon" />
                          </button>
                          <div className="grid_session">
                            {sessionDataB02.map((item, idx) => (
                              <GridItem
                                isFirst={idx === 0}
                                h190={item.h190}
                                key={item.id}
                                title={item.title}
                                time={item.time}
                                bHall
                                speaker={item.speaker}
                                disabledLink={item.disabledLink}
                                w33
                              />
                            ))}
                          </div>
                        </GridInfoList>
                        <GridInfoList
                          className={`w-33 has-bigCard ${
                            moreC ? 'is-appended' : ''
                          }`}
                        >
                          <button className="title" onClick={moreCHandler}>
                            Session Room 3
                            <span className="info_btn_icon" />
                          </button>
                          <div className="grid_session">
                            {sessionDataC02.map((item, idx) => (
                              <GridItem
                                isFirst={idx === 0}
                                h190={item.h190}
                                key={item.id}
                                title={item.title}
                                time={item.time}
                                bHall
                                speaker={item.speaker}
                                disabledLink={item.disabledLink}
                                w33
                              />
                            ))}
                          </div>
                        </GridInfoList>
                      </div>
                      <GridInfoMore
                        onClick={textHandler}
                        className={more ? 'is-appended' : ''}
                      >
                        {more ? 'Less' : 'More'}
                      </GridInfoMore>
                    </GridInfo>
                  ) : null}
                </div>

                {isDesktop ? null : <div className="index" />}
                {/**
                  <div className='item_wrap item-04'>
                    <div className='box'>
                      <div className='title'>Tech Sqaure (Exhibition)</div>
                      <div className='time'>11:30 - 17:30</div>
                    </div>
                  </div>
                   */}
                {isDesktop ? <div className="index" /> : null}

                <div className="item_wrap item-03">
                  <Link
                    to="/sessions?filter=Open%20Theater"
                    className="box"
                    onMouseEnter={hoverEnter}
                    onMouseLeave={hoverLeave}
                  >
                    <div className="title">Open Theater</div>
                    <div className="time">
                      11:30 - 16:40
                      <div className="group-line" />
                    </div>
                  </Link>

                  {after ? (
                    <GridInfo className={more2 ? 'is-appended' : ''}>
                      <div className="inner">
                        <GridInfoList
                          className={`w-100 ${
                            moreTheater ? 'is-appended' : ''
                          }`}
                        >
                          <button
                            className="title"
                            onClick={moreTheaterHandler}
                          >
                            Open Theater
                            <span className="info_btn_icon" />
                          </button>
                          <div className="grid_session_flex">
                            {sessionDataA.map((session) => (
                              <GridItem
                                key={session.id}
                                title={session.title}
                                time={session.time}
                                w50
                                speaker={session.speaker}
                              />
                            ))}
                          </div>
                        </GridInfoList>
                      </div>
                      <GridInfoMore
                        onClick={textHandler2}
                        className={more2 ? 'is-appended' : ''}
                      >
                        {more2 ? 'Less' : 'More'}
                      </GridInfoMore>
                    </GridInfo>
                  ) : null}
                </div>

                <div className="index">
                  <div className="index_text" />
                </div>
                {phase === 'phase3' || phase === 'phase4' ? (
                  <div className="item_wrap item-07-1">
                    <Link
                      className="box"
                      to="/codelab"
                      onMouseEnter={hoverEnter}
                      onMouseLeave={hoverLeave}
                    >
                      <div className="title">
                        Hacker’s Playground + Code Lab
                      </div>
                      <div className="time">11:30 - 18:00</div>
                    </Link>
                  </div>
                ) : (
                  <div className="item_wrap item-07-1">
                    <div className="box">
                      <div className="title">
                        Hacker’s Playground + Code Lab
                      </div>
                      <div className="time">11:30 - 18:00</div>
                    </div>
                  </div>
                )}
                <div className="index last-index">
                  <div className="index_text">18:00 PT</div>
                  <div className="index_text">20:00 PT</div>
                </div>
                <div className="item_wrap item-09 last-item">
                  <Link
                    className="box"
                    to="/event"
                    onMouseEnter={hoverEnter}
                    onMouseLeave={hoverLeave}
                  >
                    <div className="title">Bits and Bytes</div>
                    <div className="time">18:00 - 20:00</div>
                  </Link>
                </div>
              </ScheduleGrid>
            </ScheduleWrap>
          </TabPanel>
          {/* Online Tab */}
          <TabPanel>
            <ScheduleWrap ref={gridRef}>
              <ScheduleGrid p3={phase === 'phase3' || phase === 'phase4'}>
                <div className="index">
                  <div className="index_text">
                    Oct 5th
                    <br />
                    10:00 PT
                  </div>
                </div>
                <div className="item_wrap item-02">
                  <Link
                    to="/keynote"
                    className="box"
                    onMouseEnter={hoverEnter}
                    onMouseLeave={hoverLeave}
                  >
                    <div className="title">Keynote Live</div>
                    <div className="time">10:00 - 11:10</div>
                  </Link>
                </div>
                <div className="index last-index">
                  <div className="index_text">11:30 PT</div>
                </div>
                <div className="item_wrap item-03">
                  <Link
                    to="/sessions?filter=Mega"
                    className="box"
                    onMouseEnter={hoverEnter}
                    onMouseLeave={hoverLeave}
                  >
                    <div className="title">Mega Session Live</div>
                    <div className="time">
                      11:30 - 18:00
                      <div className="group-line" />
                    </div>
                  </Link>
                  {after ? (
                    <GridInfo>
                      <div className="inner h-auto">
                        <GridInfoList className="w-100">
                          <div className="grid_session_flex h-auto">
                            {keynoteStageData.map((item, idx) => (
                              <GridItem
                                key={item.id}
                                title={item.title}
                                time={item.time}
                                speaker={item.speaker}
                                disabledLink={item.disabledLink}
                                w50
                              />
                            ))}
                          </div>
                        </GridInfoList>
                      </div>
                    </GridInfo>
                  ) : null}
                </div>
                <div className="index">
                  <div className="index_text" />
                </div>
                <div className="item_wrap item-03-1">
                  {phase === 'phase3' || phase === 'phase4' ? (
                    <Link
                      to="/codelab"
                      className="box"
                      onMouseEnter={hoverEnter}
                      onMouseLeave={hoverLeave}
                    >
                      <div className="title">Online Code Lab</div>
                      <div className="time">11:30 - 18:00</div>
                    </Link>
                  ) : (
                    <div className="box">
                      <div className="title">Online Code Lab</div>
                      <div className="time">11:30 - 18:00</div>
                    </div>
                  )}
                </div>
                <div className="index last-index">
                  <div className="index_text">Oct 6th</div>
                </div>
                <div className="item_wrap item-05">
                  <div className="box">
                    <div className="title">
                      On-Demand (Keynote, Mega Session, Tech Session, Open
                      Theater)
                    </div>
                    <div className="time">Oct 6th</div>
                  </div>
                </div>
              </ScheduleGrid>
            </ScheduleWrap>
          </TabPanel>
        </Tabs>
      </ScheduleInner>
    </ScheduleContainer>
  );
};

export default withRouter(ScheduleCont);
