import styled from 'styled-components';

export const MyDownloadWrap = styled.div`
  padding: 24px 0 0 0;
  @media screen and (max-width: 768px) {
    padding: 16px 0 0 0;
  }
`;

export const MyDownloadInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;

  @media screen and (max-width: 768px) {
    padding: 0px 24px;
  }
`;

export const DownloadTitle = styled.div`
  color: #000;
  font-family: 'samsungSharpSans-bold';
  font-size: 24px;
  line-height: 1.35;

  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 16px;
    line-height: 1.25;
    padding-bottom: 16px;
    border-bottom: 1px solid #ddd;
  }
`;

export const MyDownloadDetail = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 24px;
  padding: 40px;

  .download-btn {
    min-width: 172px;
    font-size: 16px;
    line-height: 1.25;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;

    .download-btn {
      min-width: auto;
      width: 100%;
      margin-top: 20px;
    }
  }
`;

export const InfoPopup = styled.div`
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const InfoPopupInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 620px;
  max-width: 620px;
  padding: 56px 40px 32px 40px;
  border-radius: 20px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;

  &.scrollInner {
    max-height: 862px;
    height: calc(100% - 100px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 0;
  }

  .SaverImageWrap {
    margin-bottom: 32px;
    border-radius: 16px;
    overflow: hidden;
  }

  .TicketImageWrap {
    margin-bottom: 32px;
  }

  .SaverImg {
    display: block;
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    min-width: 312px;
    max-width: calc(100% - 48px);
    padding: 40px 24px 24px 24px;

    &.scrollInner {
      max-height: none;
      height: auto;
      overflow-x: hidden;
      overflow-y: ;
      padding: 40px 24px 24px 24px;
    }

    .SaverImageWrap {
      margin-bottom: 24px;
      border-radius: 8px;
    }
    .TicketImageWrap {
      margin-bottom: 24px;
    }
  }
`;

export const ScrollArea = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 40px;

  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

export const InfoPopupTitle = styled.div`
  margin-bottom: 20px;
  font-family: 'SamsungOne-700';
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  text-align: left;
  color: #000;

  @media (max-width: 768px) {
    font-size: 20px;
    letter-spacing: -0.2px;
  }
`;

export const PopupDesc = styled.div`
  color: #535353;
  font-family: 'SamsungOne-400';
  font-size: 20px;
  line-height: 1.5;
  text-align: left;
  margin: -12px 0 20px 0;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const InfoPopupClose = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  background-image: url(${`${process.env.PUBLIC_URL}/images/ico-close.png`});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    top: 14px;
    right: 14px;
    width: 20px;
    height: 20px;
  }
`;

export const InfoPopuBtn = styled.div`
  @media (max-width: 768px) {
    button {
      width: 100%;
    }
  }
`;
