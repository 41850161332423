import React from 'react';
import classNames from 'classnames';
import '../Button/style.scss';

function ALink({
  className,
  children,
  size,
  color,
  outline,
  fullWidth,
  href,
  onClick,
}) {
  return (
    <a
      href={href}
      target="_blank"
      className={classNames('aLink', className, size, color, {
        outline,
        fullWidth,
      })}
      rel="noreferrer"
      onClick={onClick}
    >
      {children}
    </a>
  );
}

ALink.defaultProps = {
  size: 'medium',
  color: 'black',
};

export default ALink;
