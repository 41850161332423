import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import DetailHero from '../../components/DetailHero';
import ScheduleCont from '../../components/ScheduleCont';
import ScheduleMap from '../../components/ScheduleMap';

const Schedule = () => {
  return (
    <>
      <DetailContainer>
        <DetailHero
          bgPc="sdc23-bg-schedule.png"
          bgMo="sdc23-bg-schedule-mo.png"
          bgColor="#10B77B"
          title="Schedule"
          path="Schedule"
        />
        <ScheduleCont />
        <ScheduleMap />
      </DetailContainer>
    </>
  );
};

export default Schedule;
