import styled, { keyframes } from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const ClabContContainer = styled.div`
  padding: 0 0 120px 0;

  @media screen and (max-width: 768px) {
    padding: 0 0 60px 0;
  }
`;

export const ClabContInner = styled.div`
  .is-session {
    .tag_wrap {
      position: relative;
      display: flex;
      width: 100%;
      margin: 24px -5px -5px -5px;

      @media screen and (max-width: 768px) {
        position: relative;
        overflow: auto;
        margin: 0 -5px -5px -5px;
        padding-top: 52px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  /* tag in ClabContText */
  .tag_wrap {
    display: none;
    position: absolute;
  }
`;

export const ClabContentWrap = styled.div`
  @media screen and (max-width: 768px) {
  }
`;

export const ClabContent = styled.div`
	position: relative;
	border-radius: 24px;
	overflow: hidden;

	img{
		width: 100%;
		height: 100%:
	}

	.video-thumbnail{
		width: 100%;
    display: block;
    height: auto;
    padding-bottom: 56.25%;

		iframe{
			position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      transition: 0.3s;
      object-fit: cover;
		}
	}


  @media screen and (max-width: 768px){
		border-radius: 14px;
	}
`;

export const ClabContText = styled.div`
  @media screen and (max-width: 768px) {
    margin-top: 12px;
  }
`;

export const ClabContTitle = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 24px 0 0 0;
  .title {
    font-family: 'samsungSharpSans-bold';
    font-size: 48px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000;
  }

  @media screen and (max-width: 768px) {
    margin: 20px 0 0 0;
    .title {
      font-size: 24px;
      line-height: 1.34;
    }
  }
`;

export const ClabContDesc = styled.div`
  p {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: normal;
    color: #1e1e1e;
  }
  margin-top: 20px;
  padding-bottom: 28px;
  border-bottom: 1px solid #efefef;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  color: #1e1e1e;
  @media screen and (max-width: 768px) {
    p {
      font-size: 14px;
    }
    font-size: 14px;
    margin-top: 8px;
    padding-bottom: 24px;
  }
`;

export const ClabContLink = styled.a`
  position: relative;
  display: inline-block;
  padding: 0 36px;
  font-family: 'SamsungOne-700';
  font-size: 18px;
  font-weight: bold;
  line-height: 56px;
  color: #fff;
  letter-spacing: normal;
  border-radius: 36px;
  background-color: #1e1e1e;
  @media screen and (min-width: 769px) {
    transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    &:hover {
      background-color: #535353;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 20px;
    font-size: 16px;
    line-height: 48px;
  }
`;

export const ClabContUtil = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;

  .btn {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 3px;
    font-size: 0;
  }

  .util-btn-num {
    padding-left: 8px;
    color: #1e1e1e;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;

    .ClabContLink {
      order: 1;
    }

    .btn {
      order: -1;
    }
    .btn-calendar {
      margin-top: 32px;
    }
  }
`;

const circleFade = keyframes`
  0% {
    opacity: 0.2;
  }
  100%{
    opacity: 1;
  }
`;

export const ClabbtnWrap = styled.div`
  display: block;
  padding-bottom: 30px;
  border-bottom: 1px solid #efefef;

  @media screen and (max-width: 768px) {
    text-align: center;
    padding-bottom: 24px;
  }
`;

export const ClabMore = styled.button`
  position: relative;
  margin: 16px auto 0;
  padding-right: 26px;
  font-family: 'SamsungOne-700';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #363636;
  &:after {
    content: '';
    position: absolute;
    top: 3px;
    right: 0;
    width: 20px;
    height: 20px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-more.svg`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
  }
  &.is-appended:after {
    transform: rotate(180deg);
  }

  @media screen and (max-width: 768px) {
    margin: 20px auto 0;
    padding-right: 20px;
    font-size: 14px;
    line-height: 20px;
    &:after {
      content: '';
      top: 2px;
      width: 16px;
      height: 16px;
    }
  }
`;

export const SessionDesc = styled.div`
  margin-top: 24px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #535353;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  &.is-appended {
    height: auto;
    overflow: initial;
    text-overflow: initial;
    display: block;
    -webkit-line-clamp: none;
    -webkit-box-orient: vertical;
  }

  p {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: normal;
    color: #535353;
  }
  @media screen and (max-width: 768px) {
    p {
      font-size: 14px;
    }
    font-size: 14px;
    margin-top: 14px;
  }
`;

export const SessionRoomLink = styled.div`
  color: blue;
  @media screen and (max-width: 768px) {
  }
`;

export const KeynoteBadge = styled.div`
  display: inline-block;
  .inner {
    position: relative;
    padding: 8px 12px 8px 12px;
    border-radius: 18px;
    background-color: #fa5347;
    &::before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 6px;
      background-color: #fff;
      border-radius: 100%;
      animation: ${circleFade} 0.8s infinite linear alternate;
      margin-bottom: 0.6px;
    }
  }
  span {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #fff;
  }
  @media screen and (max-width: 768px) {
    .inner {
      padding: 6px 12px 6px 12px;
    }
  }
`;
