import styled, { css } from 'styled-components';

export const MainSession = styled.div`
  position: relative;
  padding: 0 24px 120px;
  background-color: #f5f5f5;

  &.is-p4 {
    background-color: #f5f7fb;
  }

  /* Main Tech Session 공통 타이틀 예외스타일 */
  .MainSectionTitleArea {
    br {
      display: block;
    }

    .desc {
      margin-bottom: 0;
    }

    // .Link {
    //   display: none;
    // }
  }
  @media screen and (max-width: 1024px) {
    padding: 0 24px 60px;
    &.is-p4 {
      padding: 0 24px 40px;
    }
  }
`;

export const MainSessionTop = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 16px 60px;
  &.is-p4 {
    padding: 40px 16px 60px;
  }

  @media screen and (max-width: 768px) {
    display: block;
    max-width: 100%;
    padding: 60px 0 40px;
    &.is-p4 {
      padding: 20px 0 40px;
    }
  }
`;

export const MainSessionInner = styled.div`
  position: relative;
  max-width: 1200px;
  margin: -8px auto 0;
  padding: 0 16px;
  display: grid;
  gap: 1.2em;
  @media screen and (min-width: 769px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.66em;
  }
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const MainSessionCardWrap = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  > img {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    transition: 350ms cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 0;
  }
  overflow: hidden;

  @media screen and (max-width: 768px) {
    border-radius: 12px;
    > img {
      display: none;
    }
  }
`;

export const MainSessionCard = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 27.154em;
  background-color: #fff;
  border-radius: 24px;
  cursor: pointer;

  .motion-bg {
    display: none;
  }

  &:first-of-type {
    grid-column: span 2;
    background-color: #0f42cf;
    .motion-img {
      width: 100%;
      object-fit: cover;
    }
  }

  &:nth-of-type(2) {
    grid-row: span 2;
    height: 100%;

    .motion-img {
      width: 100%;
      left: 0;
      right: 0;
      top: 10%;
    }

    .CardInner {
      height: calc(100% - 658px);
    }
  }

  &:nth-of-type(3) {
    .motion-bg {
      display: block;
    }

    .motion-img {
      height: auto;
      top: 2%;
    }
  }

  &:nth-of-type(4) {
    .motion-img {
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      object-fit: cover;
    }
  }

  &:nth-of-type(6) {
    .motion-img {
    }
  }

  .CardInner {
    transition: 350ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  /* pc hover style */
  @media screen and (min-width: 769px) {
    background-image: none !important;

    &:hover {
      img {
        transform: scale(1.25);
      }
      .Blur,
      .More,
      .Btns {
        opacity: 1;
        button:not(.close_btn) {
          transform: translateY(0);
          opacity: 1;
        }
      }

      /* .CardInner {
          transform: translateY(-10%)
        } */

      .Info {
        margin-bottom: 10px;
        /* transform: scale(0.9); */
      }

      .More {
        position: relative;
        min-height: 22px;
        height: auto;
      }

      .Text {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  .MainSessionLink {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 33;
  }

  /* w-100 클래스가 있는 경우 */
  ${(props) =>
    props.hasW100 &&
    css`
      .Bookmark {
        background-image: url(${`${process.env.PUBLIC_URL}/images/Bookmark_icon.svg`});
        background-repeat: no-repeat;
        background-position: center center;
      }

      .Bookmark-checked {
        background-image: url(${`${process.env.PUBLIC_URL}/images/Bookmark_icon_checked.svg`}) !important;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .Btns {
        @media screen and (min-width: 769px) {
          &:hover:not(.Bookmark-checked) {
            .Bookmark {
              background-image: url(${`${process.env.PUBLIC_URL}/images/Bookmark_icon-hover.svg`});
            }
          }
        }
      }
    `}

  @media screen and (max-width: 1024px) {
    &:first-of-type {
      .motion-img {
        height: 110%;
        width: 110%;
      }
    }

    &:nth-of-type(2) {
      .motion-img {
        width: 120%;
        left: -12%;
        top: 15%;
      }
    }

    &:nth-of-type(3) {
      .motion-img {
      }
    }

    &:nth-of-type(4) {
      .motion-img {
        width: 100%;
        height: 85%;
        top: 0;
        right: 0%;
        object-fit: cover;
      }
    }

    &:nth-of-type(5) {
      .motion-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:nth-of-type(6) {
    }
  }

  @media screen and (max-width: 768px) {
    height: 220px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 12px;
    > img {
      display: none;
    }

    .ic-mo {
      width: auto !important;
    }

    &:first-of-type {
      grid-column: auto;
    }

    &:nth-of-type(2) {
      grid-row: auto;
      height: 220px;
    }

    &:nth-of-type(3) {
      .motion-bg {
        display: none;
      }

      .motion-img {
        display: none;
      }
    }

    &:last-of-type {
      .motion-bg {
        display: none;
      }
    }
  }
`;

export const MainSessionBlur = styled.div`
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(240, 246, 255, 0.2);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  transition: all 430ms cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
  z-index: 3;
  /* w-100 클래스가 있는 경우 */
  ${(props) =>
    props.hasW100 &&
    css`
      background-color: rgba(0, 0, 0, 0.2);
    `}
  @media screen and (max-width: 768px) {
    border-radius: 12px;
  }
`;

export const MainSessionCardInner = styled.div`
  position: relative;
  height: calc(100% - 254px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  z-index: 4;
  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;

export const MainSessionCardInfo = styled.div`
  /* position: absolute;
  bottom: 40px; */
  height: 100%;
  transform: scale(1);
  transform-origin: top left;
  transition: all 390ms cubic-bezier(0.215, 0.61, 0.355, 1);
  /* transform: translateY(74%); */
  z-index: 2;

  // .more-link {
  //   position: absolute;
  //   left: 44px;
  //   bottom: 0;
  //   display: block;
  //   width: 48px;
  //   height: 48px;
  //   border-radius: 100%;
  //   background-color: rgba(0,0,0,0.2);
  //   background-image: url(${`${process.env.PUBLIC_URL}/images/more-link.svg`});
  //   background-position: center center;
  //   background-repeat: no-repeat;
  //   transition: none;
  //   &.hasW100 {
  //     background-color: rgba(255, 255, 255, 0.2);
  //   }
  // }

  .Date {
    display: none;
  }

  @media screen and (max-width: 768px) {
    bottom: 24px;
    transform: none;

    // .more-link {
    //   width: 32px;
    //   height: 32px;
    //   left: 20px;
    //   bottom: 18px;
    // }
  }
`;

export const MainSessionCardTitle = styled.div`
  max-width: 73.469%;
  margin-bottom: 16px;
  padding: 40px 44px 0;
  font-family: 'SamsungOne-700';
  font-size: 36px;
  line-height: 1.33;
  color: #000;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 62px;
  }

  /* w-100 클래스가 있는 경우 */
  ${(props) =>
    props.hasW100 &&
    css`
      color: #fff;
    `}

  @media screen and (max-width: 768px) {
    max-width: 64.333%;
    margin-bottom: 8px;
    padding: 20px 20px 0;
    font-size: 20px;
    line-height: 26px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      height: 60px;
    }
  }
`;

export const MainSessionCardDate = styled.div`
  padding: 0 44px;
  font-family: 'SamsungOne-700';
  font-size: 20px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
  font-feature-settings: 'clig' off, 'liga' off;

  /* w-100 클래스가 있는 경우 */
  ${(props) =>
    props.hasW100 &&
    css`
      color: rgba(255, 255, 255, 0.6);
    `}

  @media screen and (max-width: 768px) {
    padding: 0 20px;
    font-size: 1em;
    line-height: 1.43;
  }
`;

export const MainSessionCardOverlay = styled.div`
  opacity: 0;
  height: 0;
  transition: all 450ms cubic-bezier(0.215, 0.61, 0.355, 1);
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const MainSessionSubText = styled.div`
  opacity: 0;
  width: 80%;
  padding: 0 40px;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  transform: translateY(30%);
  transition: all 450ms cubic-bezier(0.215, 0.61, 0.355, 1);
  /* w-100 클래스가 있는 경우 */
  ${(props) =>
    props.hasW100 &&
    css`
      color: rgba(255, 255, 255, 0.8);
    `}

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MainSessionCardBtnArea = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  opacity: 0;
  z-index: 4;
  transition: opacity 0.2s ease-in-out;

  @media screen and (max-width: 768px) {
    top: 14px;
    right: 14px;
    opacity: 1;
  }
`;

export const BookmarkToggle = styled.button`
  display: none;
  // display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border-radius: 8px;
  background-image: url(${`${process.env.PUBLIC_URL}/images/Bookmark_icon_black.svg`});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateY(13px);
  opacity: 0;
  &.Bookmark-checked {
    background-image: url(${`${process.env.PUBLIC_URL}/images/Bookmark_icon_checked_black.svg`});
    background-repeat: no-repeat;
    background-position: center center;
  }
  @media screen and (min-width: 769px) {
    &:hover:not(.Bookmark-checked) {
      background-image: url(${`${process.env.PUBLIC_URL}/images/Bookmark_icon_black-hover.svg`});
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 768px) {
    display: block;
    width: 34px;
    height: 34px;
    transform: none;
    background-size: 34px 34px;
    opacity: 1;
    transition: none;
  }
`;

export const BookmarkTooltip = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 24px;
  top: -85px;
  z-index: 99;
  width: 210px;
  padding: 14px 40px 14px 14px;
  border-radius: 8px;
  transform: translateX(50%);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  background-color: #2088fd;
  text-align: left;
  font-family: 'SamsungOne-600';
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: -0.29px;
  color: #fff;
  box-sizing: border-box;
  transition: opacity 0.3s;
  &:after {
    content: '';
    left: 50%;
    bottom: -12px;
    transform: translateX(-50%);
    position: absolute;
    border-top: 12px solid #2088fd;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
  }
  &.is-open {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s;
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
  .close_btn {
    opacity: 0.7;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 16px;
    height: 16px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-close-grey.svg`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1370px) {
    right: 75px;
    &:after {
      left: auto;
      right: 43px;
      transform: none;
    }
  }
  @media screen and (max-width: 768px) {
    right: 90px;
    &:after {
      right: 20px;
    }
  }
`;

export const LoadingCard = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 27.154em;
  border-radius: 24px;
  background-color: #f3f5f8;
  @media screen and (max-width: 768px) {
    border-radius: 12px;
    height: 320px;
  }
`;

export const LoadingCardInner = styled.div`
  width: 100%;
  padding: 40px;
  @media screen and (max-width: 768px) {
    padding: 24px;
  }
`;

export const LoadingCardTop = styled.div`
  position: relative;
  height: 194px;
  @media screen and (max-width: 768px) {
    height: 204px;
  }
`;

export const LoadingCardIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    animation-name: blink;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    & + .dot {
      margin-left: 10px;
    }
    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }
  @keyframes blink {
    0% {
      opacity: 0.08;
    }
    35% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.08;
    }
  }
`;

export const LoadingCardBottom = styled.div`
  width: 100%;
  margin-top: 20px;
  div {
    width: 100%;
    height: 32px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.04);
    & + div {
      margin-top: 8px;
    }
    &:first-of-type {
      width: 50%;
    }
    &:last-of-type {
      width: 27.559%;
      height: 24px;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 24px;
    div {
      height: 20px;
      & + div {
        margin-top: 4px;
      }
      &:last-of-type {
        display: none;
      }
    }
  }
`;

export const CardWrap = styled.div`
  max-width: 1232px;
  padding: 0 16px;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const CardInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -12px;
  @media (max-width: 768px) {
    margin: -8px;
  }
`;

export const Button = styled.div`
  &.more-link {
    position: absolute;
    left: 44px;
    bottom: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    z-index: 11;
    &.hasW100 {
      background-color: rgba(255, 255, 255, 0.2);
    }

    svg {
      display: block;
      width: 18px !important;
      height: 18px !important;
    }
  }

  @media (max-width: 768px) {
    &.more-link {
      width: 32px;
      height: 32px;
      left: 20px;
      bottom: 20px;

      img {
        display: block !important;
        height: 12px;
        transform: inherit;
      }
    }
  }
`;
