/* eslint-disable import/named */
import React, { useState, useEffect, useRef } from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Lottie from 'lottie-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import arrLottie from '../../lottie/plusbtn_white.json';
import {
  MainCodeLabWrap,
  MainCodeLabArea,
  MainCodeLabBoxBg,
  MainCodeLabBox,
  MainCodeLabBlur,
  MainCodeLabBoxInner,
  MainCodeLabBoxInfo,
  MainCodeLabTitle,
  MainCodeLabDate,
  MainCodeLabText,
  Button,
  MainCodeLabBoxLink,
} from './MainCodeLabElement';
import MainSectionTitle from '../MainSectionTitle';

const MainCodeLabData = [
  {
    id: 1,
    title: 'Code Lab',
    date: 'October 5, 11:30 AM',
    desc: 'Get a hands-on education on recent SDKs and tools at the Code Lab\nwhere you can test your knowledge on the exclusive Samsung\nDevelopers learning platform that is made for anyone, no matter the\nskill level or interest.',
    bg: `${process.env.PUBLIC_URL}/images/MainCodeLab_1.svg`,
    mobileBg: `${process.env.PUBLIC_URL}/images/MainCodeLab_1_m.png`,
    url: 'codelab',
    hideBtn: true,
  },
];

const EngagementItem = ({ item, idx, hideBtn }) => {
  const phase = useSelector((state) => state.meta.data.phase);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 769);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 769);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  // Arrow lottie button
  const [isHovered, setIsHovered] = useState(false);
  const shouldUseLottie = window.innerWidth >= 768;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Link to={phase === 'phase3' ? '/codelab' : ''}>
      <MainCodeLabBox
        key={idx}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {hideBtn ? (
          <div className="MainCodeLabBoxLink">
            <MainCodeLabBlur className="Blur" />
            {isDesktop ? (
              <MainCodeLabBoxBg className="MainCodeLabBoxBg">
                <img className="MainCodeLabImg" src={item.bg} alt="" />
              </MainCodeLabBoxBg>
            ) : (
              <MainCodeLabBoxBg
                style={{ backgroundImage: `url(${item.mobileBg})` }}
              />
            )}
            <MainCodeLabBoxInner>
              <MainCodeLabBoxInfo>
                <MainCodeLabTitle className={item.theme}>
                  {item.title.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index !== item.title.split('\n').length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </MainCodeLabTitle>
                <MainCodeLabDate className={item.theme}>
                  {item.date}
                </MainCodeLabDate>

                {/* <span className='sdcStack'><img src={process.env.PUBLIC_URL + '/images/more-link.svg'} alt="ico"/></span> */}
              </MainCodeLabBoxInfo>
              <MainCodeLabText className="MainCodeLabText">
                <p>{item.desc}</p>
              </MainCodeLabText>
            </MainCodeLabBoxInner>

            <Button
              className={`sdcStack ${hideBtn ? 'hideBtn' : ''}`}
              activate={phase === 'phase3' || phase === 'phase4'}
            >
              {shouldUseLottie && (
                <Lottie
                  key={isHovered ? 'hovered' : 'not-hovered'}
                  animationData={arrLottie}
                  loop={false}
                  autoplay={isHovered}
                  onComplete={() => {
                    // Lottie 애니메이션이 끝났을 때 호출되는 함수
                  }}
                />
              )}
              {!shouldUseLottie && (
                <img
                  src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
                  alt="ic"
                />
              )}
            </Button>
          </div>
        ) : (
          <Link to={item.url} className="MainCodeLabBoxLink">
            {/* <div className='MainCodeLabBoxLink'> */}
            <MainCodeLabBlur className="Blur" />
            {isDesktop ? (
              <MainCodeLabBoxBg className="MainCodeLabBoxBg">
                <img className="MainCodeLabImg" src={item.bg} alt="" />
              </MainCodeLabBoxBg>
            ) : (
              <MainCodeLabBoxBg
                style={{ backgroundImage: `url(${item.mobileBg})` }}
              />
            )}
            <MainCodeLabBoxInner>
              <MainCodeLabBoxInfo>
                <MainCodeLabTitle className={item.theme}>
                  {item.title.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index !== item.title.split('\n').length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </MainCodeLabTitle>
                <MainCodeLabDate className={item.theme}>
                  {item.date}
                </MainCodeLabDate>

                {/* <span className='sdcStack'><img src={process.env.PUBLIC_URL + '/images/more-link.svg'} alt="ico"/></span> */}
              </MainCodeLabBoxInfo>
              <MainCodeLabText className="MainCodeLabText">
                <p>{item.desc}</p>
              </MainCodeLabText>
            </MainCodeLabBoxInner>

            <Button
              className={`sdcStack ${hideBtn ? 'hideBtn' : ''}`}
              activate={phase === 'phase3' || phase === 'phase4'}
            >
              {shouldUseLottie && (
                <Lottie
                  key={isHovered ? 'hovered' : 'not-hovered'}
                  animationData={arrLottie}
                  loop={false}
                  autoplay={isHovered}
                  onComplete={() => {
                    // Lottie 애니메이션이 끝났을 때 호출되는 함수
                  }}
                />
              )}
              {!shouldUseLottie && (
                <img
                  src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
                  alt="ic"
                />
              )}
            </Button>
            {/* </div> */}
          </Link>
        )}
      </MainCodeLabBox>
    </Link>
  );
};

const MainCodeLab = () => {
  const phase = useSelector((state) => state.meta.data.phase);
  // fade effect
  const MainBannerRef = useRef();
  const bannerTl = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // macaron ani
    bannerTl.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: MainBannerRef.current,
          scrub: true,
          start: 'top bottom',
          end: '40% center',
        },
      })
      .fromTo(
        MainBannerRef.current,
        { opacity: '0' },
        { opacity: '1', duration: 1 }
      );

    return () => {
      // ScrollTrigger.getAll().forEach((t) => t.kill());
      bannerTl.current.kill();
    };
  }, []);

  return (
    <>
      <MainCodeLabWrap activate={phase === 'phase3' || phase === 'phase4'}>
        <MainSectionTitle
          title="Code Lab"
          desc={
            <div>
              Experience Samsung's latest technologies and services through
              hands-on activities
            </div>
          }
          linkText="Go to Code Lab"
          buttonUrl="/codelab"
        />
        <MainCodeLabArea ref={MainBannerRef}>
          {MainCodeLabData.map((item, idx) => (
            <EngagementItem
              key={idx}
              item={item}
              idx={idx}
              hideBtn={
                phase === 'phase3' || phase === 'phase4' ? false : item.hideBtn
              }
            />
          ))}
        </MainCodeLabArea>
      </MainCodeLabWrap>
    </>
  );
};

export default MainCodeLab;
