import React, { useState, forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import arrLottie from '../../lottie/plusbtn_white.json';
import { convertURL } from '../../utils/Functions';
import {
  Card,
  CardImage,
  CardInfo,
  CardTitle,
  CardText,
  LoadingCardEmptyBox,
  LoadingCard,
  LoadingCardInner,
  LoadingCardTop,
  LoadingCardTopInner,
  LoadingCardIcon,
  LoadingCardBottom,
  HoverTitle,
  CardHover,
  HoverSubject,
  Button,
} from './CardSpeakerElements';

const CardSpeaker = forwardRef(
  (
    {
      id,
      personImage,
      title,
      description,
      slide,
      border,
      listYn,
      mainslide,
      loading,
      hover,
      card,
      hoverTitle,
      subject,
      phase4,
      blBg,
      StaticIcon,
      isKeynote,
    },
    ref
  ) => {
    // const imgError = (e) => {
    //   e.target.src = `${process.env.PUBLIC_URL}/images/img-speaker-default.png`;
    //   setAnimationLoaded(true);
    // };

    const [isHovered, setIsHovered] = useState(false);
    const [animationLoaded, setAnimationLoaded] = useState(false);
    const [shouldUseLottie, setShouldUseLottie] = useState(
      window.innerWidth >= 768
    );

    // 이미지 미리로드
    useEffect(() => {
      const image = new Image();
      image.src = personImage; // 이미지 경로 설정
      image.onload = () => {
        // 이미지 로드 완료 시
        setAnimationLoaded(true);
      };
      image.onerror = () => {
        image.src = `${process.env.PUBLIC_URL}/images/MainSpeaker_empty_23.png`;
        setAnimationLoaded(true);
      };
    }, [personImage]);

    // Handle window resize event
    useEffect(() => {
      const handleResize = () => {
        setShouldUseLottie(window.innerWidth >= 768);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const handleMouseEnter = () => {
      if (hover) {
        setIsHovered(true);
      }
    };

    const handleMouseLeave = () => {
      if (hover) {
        setIsHovered(false);
      }
    };

    return (
      <Card
        ref={ref}
        className={`Card ${slide ? 'is-slide' : ''} ${
          loading ? 'loading' : ''
        } ${hover ? 'hover' : ''}`}
        border={border}
        listYn={listYn}
        mainslide={mainslide}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        phase4={phase4}
      >
        {loading ? ( // 로딩 활성화시 props ture로 넘겨 받음
          <LoadingCard>
            <LoadingCardInner>
              <LoadingCardTop listYn={listYn}>
                <LoadingCardTopInner>
                  <LoadingCardEmptyBox>
                    <div />
                    <div />
                  </LoadingCardEmptyBox>
                  <LoadingCardIcon>
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </LoadingCardIcon>
                </LoadingCardTopInner>
              </LoadingCardTop>
            </LoadingCardInner>
          </LoadingCard>
        ) : hover ? (
          <>
            {/* 퍼블 : 동일한 스피터 디테일 페이지로 이동시킴 */}
            <Link to={`/speakers/${convertURL(title)}`}>
              {/* <Link to="/keynote"> */}
              <CardInfo className="Text" listYn={listYn}>
                <CardTitle className="Text-title" listYn={listYn}>
                  {title}
                </CardTitle>
                <CardText className="Text-desc" listYn={listYn}>
                  {description}
                </CardText>
              </CardInfo>
              <CardImage className="card_img" listYn={listYn}>
                <img
                  src={
                    personImage === ''
                      ? `${process.env.PUBLIC_URL}/images/MainSpeaker_empty_23.png`
                      : personImage
                  }
                  alt={description}
                  // onError={imgError}
                />
              </CardImage>

              <CardHover className="cardHover" listYn={listYn}>
                <HoverSubject className="subject">{subject}</HoverSubject>
                <HoverTitle className="text" listYn={listYn}>
                  {hoverTitle}
                </HoverTitle>
                <CardTitle className="name" listYn={listYn}>
                  {title}
                </CardTitle>
              </CardHover>
              {
                /* Detail Page없을시 StaticIcon true */
                !StaticIcon ? (
                  <Button
                    className="more-link"
                    to={`/speakers/${convertURL(title)}`}
                    blBg={blBg}
                  >
                    {shouldUseLottie && animationLoaded && (
                      <Lottie
                        key={isHovered ? 'hovered' : 'not-hovered'}
                        animationData={arrLottie}
                        loop={false}
                        autoplay={isHovered}
                        onComplete={() => {
                          // Lottie 애니메이션이 끝났을 때 호출되는 함수
                        }}
                      />
                    )}
                    {!shouldUseLottie && (
                      <img
                        className="icon-static-img"
                        src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
                        alt="ic"
                      />
                    )}
                  </Button>
                ) : (
                  <Button className="more-link icon-static" blBg={blBg}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/ico-more-link.svg`}
                      alt="ic"
                    />
                  </Button>
                )
              }
            </Link>
          </>
        ) : (
          <>
            {/* 퍼블 : 동일한 스피터 디테일 페이지로 이동시킴 */}
            <Link to={`/speakers/${convertURL(title)}`}>
              <CardInfo className="Text" listYn={listYn}>
                <CardTitle className="Text-title" listYn={listYn}>
                  {title}
                </CardTitle>
                <CardText className="Text-desc" listYn={listYn}>
                  {description}
                </CardText>
              </CardInfo>
              <CardImage className="card_img" listYn={listYn}>
                <img
                  src={
                    personImage === ''
                      ? `${process.env.PUBLIC_URL}/images/MainSpeaker_empty_23.png`
                      : personImage
                  }
                  alt={description}
                  // onError={imgError}
                />
              </CardImage>
              {
                /* Detail Page없을시 StaticIcon true */
                !isKeynote && !StaticIcon ? (
                  <Button className="more-link" blBg={blBg}>
                    {shouldUseLottie && animationLoaded && (
                      <Lottie
                        key={isHovered ? 'hovered' : 'not-hovered'}
                        animationData={arrLottie}
                        loop={false}
                        autoplay={isHovered}
                        onComplete={() => {
                          // Lottie 애니메이션이 끝났을 때 호출되는 함수
                        }}
                      />
                    )}
                    {!shouldUseLottie && (
                      <img
                        className="icon-static-img"
                        src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
                        alt="ic"
                      />
                    )}
                  </Button>
                ) : (
                  !isKeynote && (
                    <Button className="more-link icon-static" blBg={blBg}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ico-arrow-right-white.svg`}
                        alt="ic"
                      />
                    </Button>
                  )
                )
              }
            </Link>
          </>
        )}
      </Card>
    );
  }
);

export default CardSpeaker;
