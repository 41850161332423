import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  TechContainer,
  TechInner,
  TechContent,
  TechTitle,
  TechCardArea,
  TechCardBox,
  TechCardBoxBlur,
  TechCardBoxBg,
  TechCardBoxInner,
  TechBoxInfo,
  TechBoxTitle,
  TechBoxDate,
  TechBoxText,
  Button,
} from './EventContElements';
import arrLottie from '../../lottie/plusbtn_white.json';
import ToastPopup from '../ToastPopup';
import ScheduleMap from '../ScheduleMap';
import PopupTechSquare from '../PopupTechSquare';
import EventMap from '../EventMap';

const techSquareCardData = [
  {
    id: 1,
    title: 'Tech Square',
    date: 'October 5, 11:10 AM',
    desc: 'Talk with product experts, experience innovations in Tech Square. Catch up on new updates from Samsung platforms and OS like SmartThings, Knox and Tizen, Mobile & Screen Experience, Home & Health Experience, Sustainability.',
    bg: `${process.env.PUBLIC_URL}/images/sdc23-TechSquareCard-1.png`,
    url: '/event/tech-square',
    singleLine: false,
    hideBtn: false,
    // theme : 'bright'
  },
  {
    id: 2,
    title: 'Samsung C-Lab',
    date: 'October 5, 11:30 AM',
    desc: 'Meet six passionate entrepreneurs and start-ups accelerated by Samsung C-Lab, an in-house venture and start-up acceleration program. These start-ups are making waves in the healthcare and AI industries, and are here to showcase their latest innovations.',
    bg: `${process.env.PUBLIC_URL}/images/sdc23-TechSquareCard-2.png`,
    url: '/event/samsung-clab',
    singleLine: false,
    hideBtn: false,
    // theme : 'bright'
  },
  {
    id: 3,
    title: 'Hacker’s Playground',
    date: 'October 5, 11:30 AM',
    desc: 'Discover another side of writing secure programs. Hacker’s playground is a casual hacking competition that anyone can try, offering an opportunity to practice while also providing self-study materials geared toward each level.',
    bg: `${process.env.PUBLIC_URL}/images/sdc23-TechSquareCard-3.png`,
    url: '',
    singleLine: false,
    hideBtn: true,
    // theme : 'bright'
  },
  {
    id: 4,
    title: 'Bits & Bytes',
    date: 'October 5, 6:00 PM',
    desc: 'After the final mega session, indulge in the official SDC23 after-party. "Bits and Bytes" marks the event’s culmination, featuring an exciting live performance, enjoyable arcade games, and a lucky draw.',
    // desc: 'Discover another side of writing secure programs. Hacker’s playground is a casual hacking competition that anyone can try, offering an opportunity to practice while also providing self-study materials geared toward each level.',
    bg: `${process.env.PUBLIC_URL}/images/sdc23-TechSquareCard-4.png`,
    url: '',
    singleLine: false,
    hideBtn: true,
    // theme : 'bright'
  },
];

// Card component
const TechCardItem = ({
  item,
  idx,
  setModal,
  setModalFalse,
  hideBtn,
  singleLine,
  url,
}) => {
  const phase = useSelector((state) => state.meta.data.phase);

  // Tech Square popup
  const [openTechPopup, setOpenTechPopup] = useState(false);
  const [openClabPopup, setOpenClabPopup] = useState(false);

  // Arrow lottie button
  const [isHovered, setIsHovered] = useState(false);
  const [shouldUseLottie, setShouldUseLottie] = useState(
    window.innerWidth >= 768
  );

  // Handle window resize event
  useEffect(() => {
    const handleResize = () => {
      setShouldUseLottie(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const popupHandler = () => {
    if (item.id === 1) {
      setOpenTechPopup(true);
    } else if (item.id === 2) {
      setOpenClabPopup(true);
    }
  };

  return (
    <>
      {phase === 'phase3' || phase === 'phase4' ? (
        <>
          {/* phase3에서 상세 페이지로 이동 */}
          <Link to={url}>
            <TechCardBox
              key={idx}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="TechSquareBoxLink">
                <TechCardBoxBlur className="Blur" />
                <TechCardBoxBg>
                  <img className="TechSquareImg" src={item.bg} alt="" />
                </TechCardBoxBg>

                <TechCardBoxInner>
                  <TechBoxInfo>
                    <TechBoxTitle className={item.theme}>
                      {item.title.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          {index !== item.title.split('\n').length - 1 && (
                            <br />
                          )}
                        </React.Fragment>
                      ))}
                    </TechBoxTitle>
                    <TechBoxDate>{item.date}</TechBoxDate>
                  </TechBoxInfo>

                  <TechBoxText
                    className={`TechBoxText ${singleLine ? 'singleLine' : ''}`}
                  >
                    <p>{item.desc}</p>
                  </TechBoxText>
                </TechCardBoxInner>

                <Button className={`moreLink ${hideBtn ? 'hideBtn' : ''}`}>
                  {shouldUseLottie && (
                    <Lottie
                      key={isHovered ? 'hovered' : 'not-hovered'}
                      animationData={arrLottie}
                      loop={false}
                      autoplay={isHovered}
                      onComplete={() => {
                        // Lottie 애니메이션이 끝났을 때 호출되는 함수
                      }}
                    />
                  )}
                  {!shouldUseLottie && (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
                      alt="ic"
                    />
                  )}
                </Button>
              </div>
            </TechCardBox>
          </Link>
        </>
      ) : (
        <>
          {/* phase2.5에서 팝업 제공 */}
          <TechCardBox
            key={idx}
            onClick={popupHandler}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="TechSquareBoxLink">
              <TechCardBoxBlur className="Blur" />
              <TechCardBoxBg>
                <img className="TechSquareImg" src={item.bg} alt="" />
              </TechCardBoxBg>

              <TechCardBoxInner>
                <TechBoxInfo>
                  <TechBoxTitle className={item.theme}>
                    {item.title.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        {index !== item.title.split('\n').length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </TechBoxTitle>
                  <TechBoxDate>{item.date}</TechBoxDate>
                </TechBoxInfo>

                <TechBoxText
                  className={`TechBoxText ${singleLine ? 'singleLine' : ''}`}
                >
                  <p>{item.desc}</p>
                </TechBoxText>
              </TechCardBoxInner>

              <Button className={`moreLink ${hideBtn ? 'hideBtn' : ''}`}>
                {shouldUseLottie && (
                  <Lottie
                    key={isHovered ? 'hovered' : 'not-hovered'}
                    animationData={arrLottie}
                    loop={false}
                    autoplay={isHovered}
                    onComplete={() => {
                      // Lottie 애니메이션이 끝났을 때 호출되는 함수
                    }}
                  />
                )}
                {!shouldUseLottie && (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
                    alt="ic"
                  />
                )}
              </Button>
            </div>
          </TechCardBox>

          {/* Tech Exhibition popoup */}
          {openTechPopup ? (
            <PopupTechSquare isLinkCont={false} setOpen={setOpenTechPopup} />
          ) : null}

          {/* Samsung C-Lab popoup */}
          {openClabPopup ? (
            <PopupTechSquare isLinkCont setOpen={setOpenClabPopup} />
          ) : null}
        </>
      )}
    </>
  );
};

const EventCont = () => {
  const phase = useSelector((state) => state.meta.data.phase);
  const [modal, setModal] = useState(false);
  const [modalFalse, setModalFalse] = useState(false);

  return (
    <TechContainer>
      <TechInner>
        {phase === 'phase3' || phase === 'phase4' ? (
          <>
            <TechContent>
              <TechTitle>Main Events at SDC23</TechTitle>
              <TechCardArea>
                {techSquareCardData.map((item, idx, id) => (
                  <TechCardItem
                    key={idx}
                    item={item}
                    idx={idx}
                    setModal={setModal}
                    hideBtn={item.hideBtn}
                    singleLine={item.singleLine}
                    setModalFalse={setModalFalse}
                    url={item.url}
                  />
                ))}
              </TechCardArea>
            </TechContent>
            <EventMap />
          </>
        ) : (
          <>
            <TechContent>
              <TechTitle>Main Events at SDC23</TechTitle>
              <TechCardArea>
                {techSquareCardData.map((item, idx, id) => (
                  <TechCardItem
                    key={idx}
                    item={item}
                    idx={idx}
                    setModal={setModal}
                    hideBtn={item.hideBtn}
                    singleLine={item.singleLine}
                    setModalFalse={setModalFalse}
                    url={item.url}
                  />
                ))}
              </TechCardArea>
            </TechContent>
          </>
        )}
      </TechInner>
      <ScheduleMap />
    </TechContainer>
  );
};

export default EventCont;
