import styled, { css } from 'styled-components';

export const MainHighlightsContainer = styled.div`
  position: relative;
  padding: 0 24px 120px;
  background-color: #fff;

  /* Main Tech Session 공통 타이틀 예외스타일 */
  .MainSectionTitleArea {
    br {
      display: block;
    }

    .Link {
      display: none;
    }

    .desc,
    .desc > div {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 24px 60px;

    .MainSectionTitleArea {
      padding-bottom: 32px;

      br {
        display: none;
      }
    }
  }
`;

export const MainHighlightsVideo = styled.div`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  max-width: 1200px;
  width: 100%;
  height: 710px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    border-radius: 6px;
    height: 50vw;
  }
`;
