/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
import React, { useState, useLayoutEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardImage,
  VideoIcon,
  CardInfo,
  CardTitle,
  CardTagWrap,
  CardTag,
  CardMore,
  CardType,
  CardTypeInner,
  LoadingCard,
  LoadingCardInner,
  LoadingCardTop,
  LoadingCardTopInner,
  LoadingCardIcon,
  LoadingCardBottom,
  LinkBox,
} from './CardCodeLabElements';

// id : 아이디
// col : card 너비
// border : border 유무
// bookmark : 북마크 유무
// img : 이미지 URL
// title : 제목
// description : 내용
// video : 비디오인 경우
// tag : 태그
// type : 세션 타입
// listYn : 리스트 페이지인 경우
// loading : 로딩 유무
// timeTaken : 소요시간

const CardCodeLab = ({
  id,
  col,
  border,
  image,
  title,
  url,
  description,
  video,
  tag,
  type,
  listYn,
  live,
  loading,
  timeTaken,
}) => {
  const [phase2, setPhase2] = useState(true);
  const [phase3, setPhase3] = useState(false);

  const tagLive = live;
  const [isLive, setIsLive] = useState(false);

  const tagWrapRef = useRef();
  const tagInner = useRef();

  let tagData = [...tag];
  const [wrapWidth, setWrapWidth] = useState(0);
  const [innerWidth, setInnerWidth] = useState(0);

  if (wrapWidth < innerWidth) {
    // 태그가 넘치는 경우
    // tag slice
    if (tag && tag.length > 2) {
      tagData = tagData.slice(0, 1);
    }
  } else {
    // tag slice
    // eslint-disable-next-line no-lonely-if
    if (tag && tag.length > 2) {
      tagData = tagData.slice(0, 2);
    }
  }

  useLayoutEffect(() => {
    if (loading) {
      return;
    }

    if (tagLive === 'on') {
      setIsLive(true);
    }

    const tagWrapWidth = tagWrapRef.current.offsetWidth;
    const tagInnerWidth = tagInner.current.offsetWidth;
    setWrapWidth(tagWrapWidth);
    setInnerWidth(tagInnerWidth);
  }, []);

  return (
    <Card
      className={loading ? `loading ${col}` : col}
      border={border}
      listYn={listYn}
    >
      {loading ? (
        <LoadingCard>
          <LoadingCardInner>
            <LoadingCardTop>
              <LoadingCardTopInner>
                <LoadingCardIcon>
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </LoadingCardIcon>
              </LoadingCardTopInner>
            </LoadingCardTop>
            <LoadingCardBottom>
              <div />
              <div />
              <div />
            </LoadingCardBottom>
          </LoadingCardInner>
        </LoadingCard>
      ) : (
        <>
          {/* 퍼블 : 동일한 세션 디테일 페이지로 이동시킴 */}
          <LinkBox href={url} target="_blank">
            {/* <Link to={"/Sessions/" + id}> */}
            <CardImage className={video ? 'card_img has-dim' : 'card_img'}>
              {video ? <VideoIcon /> : null}
              <img src={image} alt={description} />
            </CardImage>

            <CardInfo className="Text">
              {type === 'LIVE' ? (
                <CardTitle className="is-live">{title}</CardTitle>
              ) : (
                <CardTitle>{title}</CardTitle>
              )}
              {tag ? (
                <CardTagWrap ref={tagWrapRef}>
                  <div className="tag_inner" ref={tagInner}>
                    {tagData &&
                      tagData.map((item, idx) => (
                        <CardTag key={idx}>{item}</CardTag>
                      ))}
                    <CardMore className={tag.length > 2 ? 'is-active' : null}>
                      + {tag.length - tagData.length}
                    </CardMore>
                  </div>
                </CardTagWrap>
              ) : null}
            </CardInfo>

            {type ? (
              phase2 || phase3 ? (
                type === 'LIVE' ? (
                  /* LIVE On is provided only in 3 (live red tag on the first card is a sample)  */
                  <CardType className={isLive ? 'onLive' : ''}>
                    <CardTypeInner className="inner">
                      <span className="tag">{timeTaken}</span>
                    </CardTypeInner>
                  </CardType>
                ) : (
                  <CardType className={isLive ? 'onLive' : ''}>
                    <CardTypeInner className="inner">
                      <span className="tag">{timeTaken}</span>
                    </CardTypeInner>
                  </CardType>
                )
              ) : (
                <CardType>
                  <CardTypeInner className="inner">
                    <span className="tag">{timeTaken}</span>
                  </CardTypeInner>
                </CardType>
              )
            ) : null}
          </LinkBox>
        </>
      )}
    </Card>
  );
};

export default CardCodeLab;
