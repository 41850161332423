import React from 'react';
import { useSelector } from 'react-redux';
import {
  SpeakerContainer,
  SpeakerInner,
  SpeakerTitle,
  CardWrap,
  CardInner,
} from './KeynoteSpeakerElements';
import '../MainSlide/style.scss';
import Card from '../CardSpeaker';
import { MainSpeakers } from '../../sample/MainSlideData';

const Speaker = () => {
  const keynoteData = useSelector((state) => state.sessionList.keynoteData);

  return (
    <SpeakerContainer>
      <SpeakerInner>
        <SpeakerTitle>Keynote Speakers</SpeakerTitle>
        <CardWrap className="KeynoteCards">
          <CardInner>
            {(keynoteData?.Presenters || [{}, {}, {}, {}]).map((item, idx) => (
              <Card
                key={item.PresenterID}
                id={item.PresenterID}
                title={`${item.PresenterFirstName} ${item.PresenterLastName}`}
                description={`${item.PresenterOrganization}`}
                personImage={item.PresenterPhotoFileName}
                hoverTitle={item.PresenterCustomField3}
                subject="Keynote"
                hover
                StaticIcon={false}
                blBg={MainSpeakers[idx].blBg}
                isKeynote
              />
            ))}
            {/* {MainSpeakers.map((item, idx) => (
              <Card
                key={idx}
                hover
                id={item.id}
                title={item.title}
                description={item.description}
                personImage={item.personImage}
                hoverTitle={item.hoverTitle}
                subject={item.subject}
                StaticIcon
                blBg={item.blBg}
                isKeynote
              />
            ))} */}
          </CardInner>
        </CardWrap>
      </SpeakerInner>
    </SpeakerContainer>
  );
};

export default Speaker;
