import {
  call,
  put,
  takeLatest,
  all,
  fork,
  select,
  delay,
} from 'redux-saga/effects';
import moment from 'moment-timezone';
import * as types from '../ActionTypes';
import { Get } from '../../utils/Requester';
import { convertURL, diffTime, markEventVersion } from '../../utils/Functions';
import { getSessionFilter } from './SessionFilterSaga';
import { getMetaLive } from './MetaSaga';
import { sessionData as sampleSession } from '../../sample/SessionData';

function* getSessions(action) {
  try {
    yield call(getMetaLive);

    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      const phase = yield select((state) => state.meta.data.phase);
      yield delay(3000);
      // data handling
      yield put({ type: types.GET_BOOKMARK_LIST, data: [] });
      yield put({
        type: types.GET_SESSION_LIST_SUCCESS,
        data: {
          sessionList: sampleSession.map((x, idx) => {
            // turning on that live badge for session card when p3
            if (idx === 0 && phase === 'phase3') {
              x.status = 'live';
            }

            x.CustomPresField1 = x.PresentationImage;
            x.CustomPresField2 = x.PresentationTag;
            x.CustomPresField65 = x.MainCategory;
            return x;
          }),
        },
      });
      return;
    }

    let sessionList = yield select((state) => state.sessionList.sessionList);

    const { phase, mega } = yield select((state) => state.meta.data);

    const gapTime = yield select((state) => state.meta.gapTime);
    const d =
      gapTime === 0 ? moment.utc().valueOf() : moment.utc().valueOf() - gapTime;

    // get current time
    const currentTime = moment
      .tz(d, 'America/Los_Angeles')
      .format('YYYY-MM-DD[T]HH:mm:ss');

    let checkedTypes = yield select(
      (state) => state.sessionFilter.checkedTypes
    );
    let checkedMainCategories = yield select(
      (state) => state.sessionFilter.checkedMainCategories
    );
    let checkedTopics = yield select(
      (state) => state.sessionFilter.checkedTopics
    );

    if (action?.query !== undefined && action.query?.length > 0) {
      const queryString = action.query.toString().split(',');

      const typeFilter = [];
      const mainCateFilter = [];
      const topicFilter = [];
      queryString.forEach((x) => {
        if (x === 'Mega' || x === 'Tech' || x === 'Open Theater') {
          typeFilter.push(x);
        } else if (
          x === 'Platform Innovation' ||
          x === 'Mobile Experience' ||
          x === 'Screen Experience' ||
          x === 'Home Experience' ||
          x === 'Health Experience' ||
          x === 'Sustainability / Innovation'
        ) {
          mainCateFilter.push(x);
        } else {
          topicFilter.push(x);
        }
      });
      checkedTypes = typeFilter;
      checkedMainCategories = mainCateFilter;
      checkedTopics = topicFilter;
    }
    yield put({ type: types.SET_FILTER_TYPE, checkedType: checkedTypes });
    yield put({ type: types.SET_FILTER_TOPIC, checkedTopic: checkedTopics });
    yield put({
      type: types.SET_FILTER_MAIN_CATEGORY,
      checkedMainCategory: checkedMainCategories,
    });

    // call API
    if (sessionList?.length === 0) {
      const { data, headers, error } = yield call(Get, {
        url: `/api/event/session`,
      });

      if (error || typeof data === 'string') {
        console.error('SessionListSaga', error || data);
        yield put({
          type: types.GET_SESSION_LIST_ERROR,
          error: error || 'UNKNOWN_ERROR',
        });
        return;
      }
      if (headers['mark-event-version']) {
        markEventVersion(headers['mark-event-version']);
      }
      sessionList = [...data.sessionList];
    }

    const liveData = [];
    const techData = [];
    const theaterData = [];
    sessionList.forEach((x) => {
      if (
        x.SessionType.indexOf('Keynote') < 0 &&
        x.PresentationTitle !== 'SDC2023' &&
        x.PresentationTitle !== 'SDC2023 - Press'
      ) {
        if (x.SessionType.indexOf('Mega') > -1) {
          liveData.push(x);
        } else if (x.SessionType.indexOf('Tech') > -1) {
          techData.push(x);
        } else {
          theaterData.push(x);
        }
        if (typeof x.CustomPresField2 === 'string' && x.CustomPresField2) {
          x.CustomPresField2 = x.CustomPresField2.split(',');
        }
        if (x.CustomPresField65.indexOf('Others') > -1) {
          x.CustomPresField65 = 'Sustainability / Innovation';
        }
      }
    });

    const diffAry = [];
    let megaRoomStatus = '';

    if (phase === 'phase3') {
      // get live badge status
      liveData.forEach((x, idx) => {
        const { status, diff } = diffTime(
          currentTime,
          x.PresentationDateTimeStart,
          x.PresentationDateTimeEnd
        );

        x.status = status;
        if (diff !== -1) {
          x.diff = diff;
          diffAry.push(x.diff);
        }
      });

      if (mega?.liveBadgeStart && mega?.liveBadgeEnd) {
        const { status, diff } = diffTime(
          currentTime,
          mega?.liveBadgeStart,
          mega?.liveBadgeEnd
        );
        megaRoomStatus = status;
        if (diff !== -1) {
          diffAry.push(diff);
        }
      }
    }

    const sessionData = [...liveData, ...techData, ...theaterData];

    const result = {
      sessionList: sessionData.length !== 0 ? sessionData : [],
      megaRoomStatus,
    };

    if (
      checkedTypes.length > 0 ||
      checkedTopics.length > 0 ||
      checkedMainCategories.length > 0
    ) {
      yield call(getSessionFilter, {
        sessionList: sessionData,
        checkedTypes,
        checkedTopics,
        checkedMainCategories,
      });
    } else {
      yield put({
        type: types.GET_SESSION_LIST_SUCCESS,
        data: { ...result, filterChecked: false },
      });
    }

    // re-call function
    const minDiff = Math.min(...diffAry);
    if (diffAry.length > 0) {
      yield delay(minDiff);
      yield call(getSessions);
    }
  } catch (error) {
    if (error) {
      console.log('error', error);
      yield put({ type: types.GET_SESSION_LIST_ERROR, error });
    }
  }
}

// function* getMainList() {
//   try {
//     const { data, headers, error } = yield call(Get, {
//       url: `/api/event/session`,
//     });

//     if (error || typeof data === 'string') {
//       console.error('SessionListSaga', error || data);
//       yield put({
//         type: types.GET_MAIN_LIST_ERROR,
//         error: error || 'UNKNOWN_ERROR',
//       });
//       return;
//     }

//     if (headers['mark-event-version']) {
//       markEventVersion(headers['mark-event-version']);
//     }

//     const sessions = [];
//     let keynoteId = '';
//     let keynoteData = {};

//     // get keynote data
//     data.sessionList.forEach((x) => {
//       if (x.SessionType.indexOf('Keynote') > -1) {
//         keynoteId = convertURL(x.PresentationTitle);
//       } else {
//         sessions.push(x);
//       }
//     });

//     // has keynote data
//     if (keynoteId) {
//       const result = yield call(Get, {
//         url: `/api/event/session/${keynoteId}`,
//       });

//       if (result.error || typeof result.data === 'string') {
//         console.error('SessionListSaga', result.error || result.data);
//         yield put({
//           type: types.GET_MAIN_LIST_ERROR,
//           error: error || 'UNKNOWN_ERROR',
//         });
//         return;
//       }

//       keynoteData = result.data.session;
//     }

//     const sessionData = sessions
//       .filter((x) => x.SessionID !== '0')
//       .sort((a, b) => parseInt(a.SessionID) - parseInt(b.SessionID));

//     const result = {
//       keynoteData: keynoteId ? keynoteData : null,
//       mainSession:
//         sessionData.length !== 0
//           ? sessionData
//           : // [TEMP CODE]
//             sessions
//               .filter((x) => x.PresentationDate === '10/5/2023')
//               .slice(0, 5),
//       sessionList: sessions.length !== 0 ? sessions : [],
//     };

//     yield put({ type: types.GET_MAIN_LIST_SUCCESS, data: result });
//   } catch (error) {
//     if (error) {
//       console.log('error', error);
//       yield put({ type: types.GET_MAIN_LIST_ERROR, error });
//     }
//   }
// }

function* watchListAction() {
  yield takeLatest(types.GET_SESSION_LIST_REQUEST, getSessions);
  // yield takeLatest(types.GET_MAIN_LIST_REQUEST, getMainList);
}

export default function* rootSaga() {
  yield all([fork(watchListAction)]);
}
