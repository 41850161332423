import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import DetailHero from '../../components/DetailHero';
import EventCont from '../../components/EventCont';

const Event = () => {
  return (
    <>
      <DetailContainer>
        <DetailHero
          bgPc="bg-techsquare.png"
          bgMo="bg-techsquare-mo.png"
          bgColor="#0F42CF"
          title="On-site Events"
          path="Event"
        />
        <EventCont />
      </DetailContainer>
    </>
  );
};

export default Event;
