import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { MainSectionTitleArea, Title, Desc } from './MainSectionTitleElement';
import ButtonLink from '../ButtonLink';

const MainSectionTitle = (props) => {
  const { title, desc, buttonUrl, linkText } = props;
  const mainRef = useRef();
  const tl = useRef();
  const titleRef = useRef();
  const descRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    tl.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: mainRef.current,
          scrub: 0.5,
          start: '20% 75%',
          end: 'center center',
        },
      })
      .fromTo(titleRef.current, { y: 30, opacity: 0 }, { y: 0, opacity: 1 })
      .fromTo(
        descRef.current,
        { y: 20, opacity: 0 },
        { y: 0, opacity: 1 },
        '-=0.3'
      );

    return () => {
      tl.current.kill();
    };
  });
  return (
    <MainSectionTitleArea className="MainSectionTitleArea" ref={mainRef}>
      <Title ref={titleRef}>{title}</Title>
      <Desc className="desc" ref={descRef}>
        {desc}
      </Desc>
      <ButtonLink url={buttonUrl} color="black" size="medium">
        {linkText}
      </ButtonLink>
    </MainSectionTitleArea>
  );
};

export default MainSectionTitle;
