import styled, { keyframes } from 'styled-components';

export const MainWelcome = styled.div`
  position: relative;
  padding: 160px 40px 140px;
  @media (max-width: 768px) {
    padding: ${(props) =>
      props.Phase3 || props.Phase4 ? ' 30.811vh 24px 0' : ' 11.333vh 24px 0'};
  }
`;

export const WelcomInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1240px;
  margin: 0 auto;

  @media (max-width: 768px) {
  }
`;

export const WelcomeIbnerBox = styled.div`
  position: relative;
  text-align: center;

  .Button {
    position: relative;
    z-index: 2;
  }
`;

export const WelcomeTitleBox = styled.div`
  height: 120px;
  // overflow: hidden;

  @media (max-width: 768px) {
    height: 105px;
  }
`;

export const WelcomeTimeBox = styled.div`
  height: 120px;
  // overflow: hidden;

  @media (max-width: 768px) {
    height: 62px;
  }
`;

export const WelcomeTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'samsungsharpsans-bold';
  font-size: 92px;
  line-height: 1.3;
  z-index: 1;

  span {
    padding-left: 18px;
    line-height: 86px;
  }

  br {
    display: none;
  }

  img {
    display: inline-block;
    margin-left: 21px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    font-size: 48px;

    br {
      display: block;
    }

    span {
      padding-top: 8px;
      padding-left: 0;
      padding-bottom: 4px;
      line-height: 37px;

      img {
        width: 161px;
      }
    }

    img {
      margin-left: 0;
      width: 163px;
    }
  }
`;

export const WelcomeTime = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  font-family: 'samsungsharpsans-bold';
  font-size: 92px;
  line-height: 1.3;
  color: #2088fd;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'clig' off, 'liga' off;

  .colon {
    padding: 0 23px;
  }

  .daycolon {
    padding: 0 10px 0 5px;
  }

  @media (max-width: 768px) {
    margin-bottom: 16px;
    font-size: 48px;

    .colon {
      padding: 0 14px;
    }

    .daycolon {
      padding: 0 4px;
    }
  }
`;

export const WelcomeDay = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  font-family: 'samsungsharpsans-bold';
  font-size: 92px;
  line-height: 1.3;
  color: #2088fd;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'clig' off, 'liga' off;

  .dash {
    position: relative;
    padding-left: 71px;
    &:after {
      content: '';
      width: 37px;
      height: 11px;
      background-color: #2088fd;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 19px;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 16px;
    font-size: 48px;

    .dash {
      padding-left: 32px;
      &:after {
        content: '';
        width: 20px;
        height: 8px;
        background-color: #2088fd;
        position: absolute;
        left: 8px;
      }
    }
  }
`;

export const WelcomeText = styled.div`
  position: relative;
  margin-bottom: 48px;
  margin-top: 48px;
  font-size: 24px;
  color: #7c7c7c;
  line-height: 32px;
  text-align: center;
  z-index: 2;

  @media (max-width: 768px) {
    margin-bottom: 40px;
    margin-top: 16px;
    padding: 0 4px;
    font-size: 16px;
    line-height: 24px;
    color: #535353;

    br {
      display: none;
    }
  }
`;

/*
 MainText Anu
*/
const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
`;
export const MainTextAni = styled.div`
  position: relative;
  display: block;
  padding: 0 0 200px;

  @media (max-width: 768px) {
    padding: 29.167vw 0 25vw;
  }
`;

export const MainTextAniInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  @media (max-width: 768px) {
  }
`;

export const IntroMotionText = styled.div`
  @media (max-width: 1280px) {
    transform: scale(0.67);
  }
  @media (max-width: 768px) {
    transform: none;
  }

  .item-common {
    display: flex;
    align-items: center;

    span {
      line-height: 1;
    }
  }

  .item-typo {
    position: relative;
    line-height: 1;
    height: 185px;
    img {
      height: 100%;
      transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }

  .item-01,
  .item-02,
  .item-03 {
    font-family: 'samsungsharpsans-bold';
    font-size: 160px;
    line-height: 185px;
    color: #000;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    opacity: 0.5;
  }

  .item-01 {
    transform: translateX(-3.555vw);
    gap: 22px;
  }

  .item-02 {
    position: relative;
    transform: translateX(7.555vw);
    z-index: 2;
    margin-top: 39px;
    gap: 38px;
  }

  .item-03 {
    transform: translateX(-1.555vw);
    margin-top: 48px;
    gap: 43px;
  }

  @media (max-width: 768px) {
    .item-typo {
      height: 16.667vw;
    }
    .item-01,
    .item-02,
    .item-03 {
      font-size: 14.444vw;
      line-height: 18.889vw;
    }

    .item-01 {
      justify-content: center;
      gap: 2.222vw;
    }

    .item-02 {
      transform: translateX(7.555vw);
      gap: 3.333vw;
      margin-top: 3.889vw;
    }

    .item-03 {
      transform: translateX(-1.23vw);
      gap: 4.444vw;
      margin-top: 3.056vw;
    }
  }

  @media (max-width: 380px) {
    .item-typo {
      height: 60px;
    }
    .item-01 {
      gap: 8px;
    }
    .item-02 {
      gap: 12px;
      margin-top: 14px;
    }
    .item-03 {
      gap: 16px;
      margin-top: 11px;
    }
    .item-01,
    .item-02,
    .item-03 {
      font-size: 52px;
      line-height: 68px;
    }
  }
`;
