import styled from 'styled-components';

export const MainEngagementWrap = styled.div`
  padding: 100px 40px;
  background-color: #f6f6f6;

  .MainSectionTitleArea {
    padding-top: 0;
    padding-bottom: 72px;
  }

  @media screen and (max-width: 768px) {
    padding: 60px 24px;

    .MainSectionTitleArea {
      padding-bottom: 28px;
    }
  }
`;

export const MainEngagementArea = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 24px;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

/*
 * Elements with Link BG hover Animation
 */
export const MainEngagementBox = styled.div`
  position: relative;
  width: 100%;
  height: 27.1444em;
  margin: 0 auto;
  border-radius: 24px;
  background-color: #fff;
  cursor: pointer;

  &:first-of-type {
  }

  &:nth-of-type(3) {
    .MainEngagementBoxBg {
      top: auto;
      bottom: 0;
    }
  }

  &:nth-of-type(4) {
    .MainEngagementBoxBg {
      top: auto;
      bottom: 0;
    }
  }

  /*
  * Link BG hover Animation
  */
  .MainEngagementBoxLink {
    position: relative;
    display: block;
    height: 100%;
    border-radius: 24px;
    overflow: hidden;
    @media screen and (min-width: 769px) {
      &:hover {
        .Blur {
          opacity: 1;
        }

        .MainEngagementImg {
          transform: scale(1.2);
        }

        .MainEngagementText {
          position: relative;
          min-height: 22px;
          height: auto;
          opacity: 1;
          p {
            transform: translateY(0);
            opacity: 1;
          }
        }

        .sdcStack {
          &.hideBtn {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &:first-of-type {
      .MainEngagementBoxBg {
        top: 10%;
        left: auto;
        right: -10%;
      }
    }

    &:nth-of-type(2) {
      .MainEngagementImg {
        width: auto;
      }
    }

    &:nth-of-type(3) {
      .MainEngagementBoxBg {
        right: -10%;
      }
    }

    &:nth-of-type(4) {
      .MainEngagementBoxBg {
        right: -10%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    height: 220px;
    border-radius: 12px;

    & + & {
      margin-top: 16px;
    }

    .MainEngagementImg {
      width: 100%;
    }

    &:nth-of-type(2) {
      .MainEngagementImg {
        width: 100%;
      }
    }
  }
`;

export const MainEngagementBlur = styled.div`
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(240, 246, 255, 0.2);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  transition: all 430ms cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
  z-index: 1;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MainEngagementBoxBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  .MainEngagementImg {
    display: block;
    height: 100%;
    transition: all 430ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  @media screen and (max-width: 1024px) {
    .MainEngagementImg {
      width: 90%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    right: auto;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .MainEngagementImg {
      width: 100%;
    }
  }
`;

export const MainEngagementBoxInner = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  // height: calc(100% - 44px);
  height: calc(100% - 248px);
  padding: 0 44px 0;
  text-align: left;
  z-index: 2;

  /*
  * Link
  */

  @media screen and (max-width: 768px) {
    display: block;
    height: calc(100% - 36px);
    padding: 20px;
    text-align: left;
  }
`;

export const MainEngagementBoxInfo = styled.div`
  padding-top: 40px;
  @media screen and (max-width: 768px) {
    padding-top: 0;
    z-index: 2;
  }
`;

export const MainEngagementTitle = styled.div`
  margin-bottom: 16px;
  font-family: 'SamsungOne-700';
  font-size: 36px;
  line-height: 1.28;
  color: #000;

  @media screen and (max-width: 768px) {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 26px;
  }
`;

export const MainEngagementDate = styled.div`
  max-width: 760px;
  // margin-bottom: 40px;
  margin-bottom: 20px;
  font-family: 'SamsungOne-700';
  font-size: 20px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
  white-space: pre-line;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    font-size: 1em;
    line-height: 1.57;
  }
`;

export const MainEngagementText = styled.div`
  height: 0;
  opacity: 0;
  transition: all 450ms cubic-bezier(0.215, 0.61, 0.355, 1);

  p {
    font-size: 16px;
    line-height: 24px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.6);
    transform: translateY(30%);
    transition: all 450ms cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Button = styled.div`
  position: absolute;
  left: 44px;
  bottom: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  z-index: 11;

  svg {
    display: block;
    width: 18px !important;
    height: 18px !important;
  }

  @media screen and (max-width: 768px) {
    width: 32px;
    height: 32px;
    left: 20px;
    bottom: 20px;
    &.hideBtn {
      display: none;
    }

    img {
      height: 12px;
    }
  }
`;
