import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  DetailContContainer,
  DetailContInner,
  SectionWrap,
  DetailContSpeaker,
  DetailContResurce,
  DetailContTech,
} from './SessionDetailContElements';
import Section from '../Section';
import CardSpeaker from '../CardSpeaker';
import CardSession from '../CardSession';
import CardLink from '../CardLink';
import Codelab from '../Codelab';
import Banner from '../Banner';
import ToastPopup from '../ToastPopup';

import Config from '../../Config';

function getTechnicalData(data) {
  const url = [];
  const text = [];

  data.forEach((x) => {
    url.push(x.url);
    text.push(x.text);
  });

  return { url, text };
}

const SessionDetailCont = () => {
  const { data } = useSelector((state) => state.sessionDetail);
  const {
    SessionBuilderCode,
    Presenters,
    codelab,
    relatedVideo,
    resources,
    technical,
    relatedSession,
    noDetailCont,
    subSessions,
  } = data;

  const technialData = useMemo(() => {
    if (technical) {
      return getTechnicalData(technical.url);
    }
    return { url: [], text: [] };
  }, [technical]);

  const [modal, setModal] = useState(false);
  const [modalFalse, setModalFalse] = useState(false);

  return (
    <>
      {SessionBuilderCode === 'M' && subSessions && subSessions.length > 0
        ? subSessions.map((x) => {
            return (
              <DetailContSpeaker>
                <DetailContInner>
                  <Section title={x.PresentationTitle} isMegaSession>
                    <SectionWrap>
                      {x.Presenters.map((item, idx) => (
                        <CardSpeaker
                          key={idx}
                          id={item.PresenterID}
                          title={`${item.PresenterFirstName} ${item.PresenterLastName}`}
                          description={`${item.PresenterOrganization}`}
                          personImage={item.PresenterPhotoFileName}
                          hover={false}
                          blBg={item.blBg}
                          StaticIcon
                          // hover
                          // hoverTitle={item.hoverTitle}
                          // subject={item.subject}
                          // blBg={item.blBg}
                        />
                      ))}
                    </SectionWrap>
                  </Section>
                </DetailContInner>
              </DetailContSpeaker>
            );
          })
        : null}
      {SessionBuilderCode !== 'M' && Presenters && Presenters.length > 0 && (
        <DetailContSpeaker>
          <DetailContInner>
            <Section title="Speakers">
              <SectionWrap>
                {Presenters.map((item, idx) => (
                  <CardSpeaker
                    key={idx}
                    id={item.PresenterID}
                    title={`${item.PresenterFirstName} ${item.PresenterLastName}`}
                    description={`${item.PresenterOrganization}`}
                    personImage={item.PresenterPhotoFileName}
                    hover={false}
                    blBg={item.blBg}
                    StaticIcon
                    // hoverTitle={item.hoverTitle}
                    // subject={item.subject}
                    // blBg={item.blBg}
                  />
                ))}
              </SectionWrap>
            </Section>
          </DetailContInner>
        </DetailContSpeaker>
      )}
      {!noDetailCont && (
        <DetailContContainer>
          <DetailContInner>
            {relatedSession && relatedSession.length > 0 && (
              <Section title="Related Sessions">
                <SectionWrap>
                  {relatedSession.map((item, idx) => (
                    <CardSession
                      key={idx}
                      id={item.PresentationID}
                      col="col-3"
                      title={item.PresentationTitle}
                      date={`${moment(item.PresentationDateTimeStart).format(
                        'MM/DD [Start at] HH:mm'
                      )}`}
                      description={item.AbstractTextShort}
                      image={
                        item.image ||
                        `${process.env.PUBLIC_URL}/images/img-session-01.png`
                      }
                      bookmark
                      tag={
                        item.category && item.mainCategory
                          ? [item.mainCategory, ...item.category]
                          : item.mainCategory
                          ? [item.mainCategory]
                          : null
                      }
                      type={item.SessionType}
                      sessionData={item}
                      setModal={setModal}
                      setModalFalse={setModalFalse}
                      live={item.status === 'live' && 'on'}
                    />
                  ))}
                </SectionWrap>
              </Section>
            )}
            {relatedVideo && relatedVideo.length > 0 && (
              <Section title="Related Videos">
                <SectionWrap>
                  {relatedVideo.map((item, idx) => (
                    <CardLink
                      key={idx}
                      id={item.id}
                      col="col-3"
                      title={item.title}
                      description={item.description}
                      image={`https://img.youtube.com/vi/${item.id}/hqdefault.jpg`}
                      video
                      link={item.url}
                    />
                  ))}
                </SectionWrap>
              </Section>
            )}
            {codelab && codelab.length > 0 && (
              <Section title="Code Lab">
                <SectionWrap>
                  {codelab.map((item, idx) => (
                    <Codelab
                      key={idx}
                      id={item.id}
                      title={item.title}
                      description={item.description}
                      image={item.picture}
                      link={item.link}
                    />
                  ))}
                </SectionWrap>
              </Section>
            )}
            {resources && resources.length > 0 && (
              <Section title="Resources">
                <DetailContResurce>
                  {resources.map((item, idx) => (
                    <Banner
                      key={idx}
                      fullWidth
                      title={item.title}
                      desc={item.description}
                      url={item.linkUrl}
                      linkText={item.linkText || 'Learn more'}
                      border={resources.length === 1 ? 'border' : ''}
                    />
                  ))}
                </DetailContResurce>
              </Section>
            )}
            {technical && technical.title && (
              <Section title="Technical Q&A">
                <DetailContTech>
                  <Banner
                    fullWidth
                    border="border"
                    title={technical.title}
                    desc={technical.description}
                    url={technialData.url}
                    linkText={technialData.text}
                  />
                </DetailContTech>
              </Section>
            )}
          </DetailContInner>
        </DetailContContainer>
      )}

      {/* toast popup */}
      {/* 북마크 설정 팝업 */}
      <ToastPopup
        modal={modal}
        setModal={setModal}
        state="success"
        text="This session is kept as a bookmark."
      />

      {/* 북마크 해제 팝업 */}
      <ToastPopup
        modal={modalFalse}
        setModal={setModalFalse}
        state="success"
        text="This session is no longer bookmarked."
      />
    </>
  );
};

export default SessionDetailCont;
