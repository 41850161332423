import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  Card,
  CardImage,
  VideoIcon,
  CardInfo,
  CardTitle,
  CardText,
  CardTagWrap,
  CardTag,
  CardMore,
  CardBookmark,
  KeynoteContMark,
  CardType,
  CardTypeInner,
  BookmarkTooltip,
  LoadingCard,
  LoadingCardInner,
  LoadingCardTop,
  LoadingCardTopInner,
  LoadingCardIcon,
  LoadingCardBottom,
  CardDeletemark,
  SessionContMark,
} from './CardSessionElements';
import {
  ADD_BOOKMARK_REQUEST,
  DELETE_BOOKMARK_REQUEST,
  RESET_BOOKMARK_MOTION,
  UNLIKE_SESSION_REQUEST,
} from '../../redux/ActionTypes';
import { convertURL } from '../../utils/Functions';

const BookmarkToggleItem = ({
  sessionData,
  setModal,
  setModalFalse,
  tooltipOpen,
  setTooltipOpen,
}) => {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.userSession.isLogin);
  const data = useSelector((state) => state.bookmarkList.data);
  const motion = useSelector((state) => state.bookmarkList.motion);
  const bookmarkStatus = data
    ? data.filter((x) => x.PresentationID === sessionData.PresentationID)
    : [];

  const [Bookmark, setBookmark] = useState(bookmarkStatus.length > 0);

  const onClickHandler = (e) => {
    e.preventDefault();

    if (isLogin) {
      // login user
      if (Bookmark === false) {
        dispatch({
          type: ADD_BOOKMARK_REQUEST,
          sessionData,
        });
      } else {
        dispatch({
          type: DELETE_BOOKMARK_REQUEST,
          sessionId: sessionData.PresentationID,
        });
      }
    } else {
      // not login user, display tooltip
      setTooltipOpen(true);
    }
  };

  // login tooltip
  useEffect(() => {
    const timer = setTimeout(() => {
      setTooltipOpen(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [tooltipOpen, setTooltipOpen]);

  useEffect(() => {
    if (data && motion) {
      const sessionStatus = data.filter(
        (x) => x.PresentationID === sessionData.PresentationID
      );
      if (Bookmark && sessionStatus.length === 0) {
        // delete bookmark
        setModal(false);
        setModalFalse(sessionData.PresentationID);
        setBookmark(false); // ui update state
      } else if (!Bookmark && sessionStatus.length === 1) {
        // add bookmark
        setModalFalse(false);
        setModal(sessionData.PresentationID);
        setBookmark(true); // ui update state
      }
      dispatch({ type: RESET_BOOKMARK_MOTION });
    }
  }, [data, motion]);

  useLayoutEffect(() => {
    if (Bookmark !== bookmarkStatus.length > 0) {
      setBookmark(bookmarkStatus.length > 0);
    }
  }, [data]);

  return (
    <KeynoteContMark
      className={Bookmark ? 'Bookmark-checked' : ''}
      onClick={onClickHandler}
    />
  );
};

const DeleteCardItem = ({ cardType, sessionData }) => {
  const dispatch = useDispatch();

  const [Delete, setDelete] = useState(false);

  const onClickHandler = (e) => {
    // bookmark / like card delete
    e.preventDefault();
    if (cardType === 'bookmark') {
      // call delete actions
      dispatch({
        type: DELETE_BOOKMARK_REQUEST,
        sessionId: sessionData?.PresentationID,
      });
    } else if (cardType === 'like') {
      // call delete actions
      dispatch({
        type: UNLIKE_SESSION_REQUEST,
        sessionId: sessionData?.PresentationID,
        actionPage: 'mypage',
      });
    }
  };

  return (
    <SessionContMark
      className={Delete ? 'delete-checked' : ''}
      onClick={onClickHandler}
    />
  );
};

// id : 아이디
// col : card 너비
// border : border 유무
// bookmark : 북마크 유무
// img : 이미지 URL
// title : 제목
// description : 내용
// video : 비디오인 경우
// tag : 태그
// type : 세션 타입
// listYn : 리스트 페이지인 경우
// setModal : 북마크 설정
// setModalFalse : 북마크 해제
// loading : 로딩 유무
const CardSession = ({
  id,
  col,
  border,
  image,
  title,
  description,
  bookmark,
  video,
  tag,
  type,
  sessionData,
  listYn,
  live,
  setModal,
  setModalFalse,
  loading,
  keynote,
  deleteCard,
  cardType,
  isHide,
}) => {
  // tag slice
  // let tagData = tag;
  // if (tag && tag.length > 2) {
  //   tagData = tagData.slice(0, 2);
  // }

  // const [phase2, setPhase2] = useState(true);
  // const [phase3, setPhase3] = useState(false);
  const phase = useSelector((state) => state.meta.data.phase);

  const tagLive = live;
  const [isLive, setIsLive] = useState(false);

  const tagWrapRef = useRef();
  const tagInner = useRef();

  let tagData = tag ? [...tag] : [];
  const [wrapWidth, setWrapWidth] = useState(0);
  const [innerWidth, setInnerWidth] = useState(0);

  useLayoutEffect(() => {
    if (loading) {
      return;
    }

    // set live badge
    if (tagLive === 'on') {
      setIsLive(true);
    } else {
      setIsLive(false);
    }
    const tagWrapWidth = tagWrapRef.current?.offsetWidth;
    const tagInnerWidth = tagInner.current?.offsetWidth;
    setWrapWidth(tagWrapWidth);
    setInnerWidth(tagInnerWidth);
  }, [col, tagLive]);

  if (wrapWidth < innerWidth) {
    // 태그가 넘치는 경우
    // tag slice
    if (tag && tag.length > 2) {
      tagData = tagData.slice(0, 1);
    }
  } else if (tag && tag.length > 2) {
    tagData = tagData.slice(0, 2);
  }

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const closeTooltip = () => {
    setTooltipOpen(false);
  };

  const imgError = (e) => {
    e.target.src = `${process.env.PUBLIC_URL}/images/sessionDefault.png`;
  };

  const sessionType = sessionData?.SessionType || '';
  const room = sessionData?.PresentationRoom;

  return (
    <Card
      className={loading ? `loading ${col}` : col}
      border={border}
      listYn={!isMobile ? listYn : false}
    >
      {loading ? (
        <LoadingCard>
          <LoadingCardInner>
            <LoadingCardTop>
              <LoadingCardTopInner>
                <LoadingCardIcon>
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </LoadingCardIcon>
              </LoadingCardTopInner>
            </LoadingCardTop>
            <LoadingCardBottom>
              <div />
              <div />
              <div />
            </LoadingCardBottom>
          </LoadingCardInner>
        </LoadingCard>
      ) : (
        <>
          {/* 퍼블 : 동일한 세션 디테일 페이지로 이동시킴 */}
          <Link
            to={
              sessionType.toLowerCase() === 'keynote'
                ? `/keynote`
                : phase === 'phase3' && isLive
                ? `/mega-session`
                : `/sessions/${convertURL(title)}`
            }
          >
            <CardImage className={video ? 'card_img has-dim' : 'card_img'}>
              {video ? <VideoIcon /> : null}
              <img
                src={
                  image === ''
                    ? `${process.env.PUBLIC_URL}/images/sessionDefault.png`
                    : image
                }
                alt={description}
                onError={imgError}
              />
              {type.toLowerCase() === 'mega' ||
              sessionType.toLowerCase() === 'keynote' ? (
                <CardTitle className="is-live">{title}</CardTitle>
              ) : (
                <CardTitle>{title}</CardTitle>
              )}
            </CardImage>
            <CardInfo className="Text">
              <CardText>{description}</CardText>
              {tag ? (
                <CardTagWrap ref={tagWrapRef}>
                  <div className="tag_inner" ref={tagInner}>
                    {tagData &&
                      tagData.map((item, idx) => (
                        <CardTag key={idx}>
                          {item
                            .replace('Contents & Service', 'Contents&Service')
                            .replace('Others', 'Sustainability / Innovation')}
                        </CardTag>
                      ))}
                    <CardMore className={tag.length > 2 ? 'is-active' : null}>
                      + {tag.length - tagData.length}
                    </CardMore>
                  </div>
                </CardTagWrap>
              ) : null}
            </CardInfo>
            {type ? (
              type.toLowerCase() === 'mega' ? (
                /* LIVE On is provided only in 3 (live red tag on the first card is a sample)  */
                <CardType className={isLive ? 'onLive' : ''}>
                  <CardTypeInner className="inner">
                    <span className="tag live-tag">
                      {type.toLowerCase().replace('mega', 'mega session')}
                    </span>
                  </CardTypeInner>
                </CardType>
              ) : (
                <CardType>
                  <CardTypeInner className="inner">
                    <span className="tag">
                      {type.toLowerCase().replace('tech', 'tech session')}
                    </span>
                  </CardTypeInner>
                </CardType>
              )
            ) : null}
          </Link>
          {bookmark ? (
            <CardBookmark>
              <BookmarkToggleItem
                sessionData={sessionData}
                setModal={setModal}
                setModalFalse={setModalFalse}
                tooltipOpen={tooltipOpen}
                setTooltipOpen={setTooltipOpen}
              />
            </CardBookmark>
          ) : null}
          {deleteCard ? (
            <CardDeletemark>
              <DeleteCardItem
                setModal={setModal}
                setModalFalse={setModalFalse}
                tooltipOpen={tooltipOpen}
                setTooltipOpen={setTooltipOpen}
                cardType={cardType}
                sessionData={sessionData}
              />
            </CardDeletemark>
          ) : null}

          {/* Bookmark tooltip */}
          <BookmarkTooltip
            className={
              tooltipOpen ? 'bookmark_tooltip is-open' : 'bookmark_tooltip'
            }
          >
            {'Bookmarks are available after signing in. '}
            <a href={`/login?redirectURL=${window.location.pathname}`}>
              Sign in
            </a>
            <button
              className="close_btn"
              onClick={closeTooltip}
              type="button"
              aria-label="closeButton"
            />
          </BookmarkTooltip>
        </>
      )}
    </Card>
  );
};

export default CardSession;
