import styled from 'styled-components';

export const ErrorWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 60px - 170px - 120px - 60px);
  margin: 0 auto;
  padding: 0;
  background-image: url(${`${process.env.PUBLIC_URL}/images/register-cont-bg.png`});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 80%;
  position: relative;

  &.only-content {
    height: 100vh;
    padding: 0 40px;
  }

  a {
    min-width: 240px;
  }
  @media screen and (max-width: 1280px) {
    background-position: 85% 80%;
  }
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    min-height: calc(100vh - 56px - 218px);
    background-image: url(${`${process.env.PUBLIC_URL}/images/register-cont-bg.s.png`});
    overflow: hidden;

    &.only-content {
      padding: 0 24px;
    }

    a {
      min-width: 180px;
    }
  }
`;

export const ErrorWrapInner = styled.div`
  position: relative;
  text-align: center;
  padding: 130px 0 150px;
  width: 100%;
  min-width: 760px;
  max-width: 1200px;
  max-height: calc(100vh - 40px);
  box-sizing: border-box;
  z-index: 3;
  overflow-y: hidden;
  color: #fff;
  .gotoPage-btn {
    min-width: 185px !important;
  }

  @media screen and (max-width: 768px) {
    display: block;
    min-width: auto;
    padding: 60px 0 70px;
    max-height: none;
    height: auto;
    .gotoPage-btn {
      min-width: 144px !important;
    }
  }
`;

export const ErrorTitle = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: 'samsungsharpsans-bold';
  font-size: 42px;
  line-height: 1.3;
  white-space: pre-line;
  padding: 0 80px;

  @media screen and (max-width: 768px) {
    margin-top: 14px;
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 1.25;
    padding: 0 20px;
  }
`;

export const ErrorDesc = styled.div`
  max-width: 438px;
  margin: 0 auto;
  margin-bottom: 48px;
  font-size: 16px;
  color: #fff;
  line-height: 1.43;
  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
    font-size: 14px;
  }
`;

export const ErrorLogo = styled.div`
  width: 90.8px;
  height: 24px;
  background-image: url(${`${process.env.PUBLIC_URL}/images/sdc24-MainLogo.svg`});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 60px;
    height: 16px;
  }
`;

export const ErrorCharacter = styled.div`
  width: 68px;
  height: 68px;
  background-image: url(${`${process.env.PUBLIC_URL}/images/invited/sdc24.png`});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 auto 30px;

  @media screen and (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

export const ErrorCopyright = styled.div`
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  font-family: 'samsungsharpsans-bold';
  font-size: 20px;
  line-height: 1;
  color: #fff;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    white-space: nowrap;
    bottom: 30px;
  }
`;
