import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../ActionTypes';
import { Get } from '../../utils/Requester';
import { EventTechSquareData } from '../../sample/EventTechSquareData';

export function* getMetaTechSquare() {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield put({
        type: types.GET_META_TECH_SQUARE_SUCCESS,
        data: EventTechSquareData,
      });
      return;
    }
    const { data, error } = yield call(Get, {
      url: `/api/meta/tech-square`,
    });

    if (error || typeof data === 'string') {
      console.error('Meta', error);
      yield put({
        type: types.GET_META_TECH_SQUARE_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
    }

    const techSquareData = {};
    Object.keys(data)
      .filter((y) => y !== 'currentTime')
      .forEach((x) => {
        const techData = data[x];
        const techCards = Object.keys(techData).map((tech) => {
          const techCard = techData[tech];
          const images = (techCard?.images || []).filter((img) => img !== '');
          let videoId = '';

          if (techCard.youtubeUrl && techCard.youtubeUrl !== '') {
            const url = new URL(techCard.youtubeUrl);
            const args = new URLSearchParams(url.search);
            videoId =
              techCard.youtubeUrl.indexOf('www.youtube.com') > -1
                ? new URLSearchParams(args).get('v')
                : techCard.youtubeUrl.split('/').pop().replace(url?.search, '');
          }

          if (techCard.youtubeThumbnail && techCard.youtubeThumbnail !== '') {
            images.unshift(techCard.youtubeThumbnail);
          } else if (techCard.youtubeUrl && videoId) {
            images.unshift(
              `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
            );
          }

          return {
            slide: videoId && images.length === 1 ? true : images.length > 1,
            title: techCard.title,
            desc: techCard.description,
            video: !!(techCard.youtubeUrl && techCard.youtubeUrl !== ''),
            videoUrl:
              techCard.youtubeUrl && techCard.youtubeUrl !== ''
                ? `https://www.youtube.com/embed/${videoId}`
                : '',
            image: images.length === 1 ? images[0] : null,
            images,
          };
        });
        techSquareData[x] = techCards;
      });

    yield put({
      type: types.GET_META_TECH_SQUARE_SUCCESS,
      data: techSquareData,
    });
  } catch (error) {
    if (error) {
      console.error('TechSqquare', error);
      yield put({
        type: types.GET_META_TECH_SQUARE_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_META_TECH_SQUARE_REQUEST, getMetaTechSquare);
}
