import styled from 'styled-components';

export const ActivityContainer = styled.div`
  padding: 100px 0;
  margin-top: 120px;
  background-color: #f6f6f6;

  &.on-site {
    padding: 100px 0 80px;
    + .activity {
      margin-top: 0;
    }
  }

  .react-tabs__tab-list {
    display: flex;
    width: 100%;
    margin: 0 auto;
  }

  .react-tabs__tab {
    width: 50%;
    padding: 20px 0;
    font-family: 'samsungsharpsans-medium';
    font-size: 18px;
    line-height: 1.44;
    color: #9e9e9e;
    border-bottom: 2px solid #efefef;
    text-align: center;
    cursor: pointer;
    &--selected {
      font-family: 'samsungsharpsans-bold';
      color: #2088fd;
      border-bottom: 2px solid #2088fd;
    }
  }

  @media screen and (max-width: 768px) {
    .react-tabs__tab {
      width: 50%;
      padding: 14px 13px 13px;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 60px 0;
    margin-top: 60px;

    &.on-site {
      padding: 60px 0;
    }
  }
`;

export const ActivityInner = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const TabPanelBox = styled.div`
  margin-top: 32px;

  .cardSession {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
  }
  .moreBtn {
    margin: 40px auto 0 auto;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    .cardSession {
      margin: 0;
    }
  }
`;

export const ListBox = styled.div`
  display: grid;

  @media screen and (max-width: 768px) {
  }
`;

export const ReservedBox = styled.div`
  width: 100%;
  height: 288px;
  background-color: #fff;
  border-radius: 24px;

  .box-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .text {
    color: #535353;
    font-size: 16px;
    line-height: 1.5;
    font-family: 'SamsungOne-700';
    margin-bottom: 4px;
  }

  @media screen and (max-width: 768px) {
    height: 138px;
    border-radius: 16px;

    .text {
      font-size: 14px;
      line-height: 1.42;
      margin-bottom: 2px;
      text-transform: uppercase;
    }
  }
`;

export const ReservedLink = styled.div`
  display: inline-block;
  color: #2088fd;
  font-family: 'SamsungOne-700';
  line-height: 1.57;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;

  a {
    color: #2088fd;
  }

  &:hover:not(.dim) {
    color: #2088fd;
    text-decoration: underline;

    svg {
      path {
        stroke: #2088fd;
      }
    }
  }

  &.dim {
    color: #9e9e9e;
    svg {
      path {
        stroke: #9e9e9e;
      }
    }
  }

  svg {
    display: inline-block;
    margin-left: 4px;
    margin-top: -2px;
    vertical-align: middle;
    path {
      stroke: #2088fd;
    }
  }

  & + & {
    margin-left: 24px;
  }
  @media screen and (max-width: 768px) {
    & + & {
      margin-top: 16px;
      margin-left: 0;
    }
  }
`;

export const CardMoreBtn = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  @media screen and (max-width: 768px) {
  }
`;
