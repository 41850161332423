import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import {
  MainHighlightsContainer,
  MainHighlightsVideo,
} from './MainHighlightsElement';
import MainSectionTitle from '../MainSectionTitle';

const MainHighlights = () => {
  const mainRef = useRef();
  const contentRef = useRef();
  const tl = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    tl.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: mainRef.current,
          scrub: 0.5,
          start: '35% 75%',
          end: '40% center',
        },
      })
      .fromTo(contentRef.current, { opacity: 0 }, { opacity: 1 });

    return () => {
      tl.current.kill();
    };
  });
  return (
    <MainHighlightsContainer ref={mainRef}>
      <MainSectionTitle
        title={<>Highlights SDC23</>}
        desc={
          <div>
            Though SDC23 has ended, the innovation lives on! Whether you missed
            the event or just want to <br />
            revisit the highlights, you can watch the excitement on demand.{' '}
          </div>
        }
      />
      <MainHighlightsVideo ref={contentRef}>
        <iframe
          type="text/html"
          src="https://www.youtube.com/embed/Arq4NSKlRbc"
          title="video"
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
        />
      </MainHighlightsVideo>
    </MainHighlightsContainer>
  );
};

export default MainHighlights;
