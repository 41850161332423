// main page > session area data

export const MainSessionData = [
  {
    id: 1,
    title: 'Platform Innovation',
    date: '10/5 at 11:30AM',
    description: 'SmartThings & Matter, Bixby, Knox, Tizen',
    picture: `${process.env.PUBLIC_URL}/images/sdc23-MainSessionCard_1.svg`,
    pictureMo: `${process.env.PUBLIC_URL}/images/MainSessionCard_1_mo.png`,
    sessionLink: 'sessions?filter=Platform Innovation',
  },
  {
    id: 2,
    title: 'Mobile Experience',
    date: '10/5 at 11:30AM',
    description: 'Galaxy & One UI, Gaming, Wearable, Samsung Wallet',
    picture: `${process.env.PUBLIC_URL}/images/sdc23-MainSessionCard_2_0911.svg`,
    pictureMo: `${process.env.PUBLIC_URL}/images/sdc23-MainSessionCard_2_mo_0911.png`,
    sessionLink: 'sessions?filter=Mobile Experience',
  },
  {
    id: 3,
    title: 'Home Experience',
    date: '10/5 at 11:30AM',
    description:
      'SmartThings Energy, Smart Kitchen, Home Connectivity Alliance',
    picture: `${process.env.PUBLIC_URL}/images/sdc23-MainSessionCard_3_A.svg`,
    pictureMo: `${process.env.PUBLIC_URL}/images/MainSessionCard_3_mo.png`,
    sessionLink: 'sessions?filter=Home Experience',
  },
  {
    id: 4,
    title: 'Health Experience',
    date: '10/5 at 11:30AM',
    description:
      'Digital Health Platform, Health SDKs & Partner Ecosystem, Open Innovation',
    picture: `${process.env.PUBLIC_URL}/images/sdc23-MainSessionCard_4_0911.svg`,
    pictureMo: `${process.env.PUBLIC_URL}/images/sdc23-MainSessionCard_4_mo_0911.png`,
    sessionLink: 'sessions?filter=Health Experience',
  },
  {
    id: 5,
    title: 'Screen Experience',
    date: '10/5 at 11:30AM',
    description: 'Smart TV & Tizen, Tizen TV Development Tools, Gaming',
    picture: `${process.env.PUBLIC_URL}/images/sdc23-MainSessionCard_5_0911.svg`,
    pictureMo: `${process.env.PUBLIC_URL}/images/sdc23-MainSessionCard_5_mo_0911.png`,
    sessionLink: 'sessions?filter=Screen Experience',
  },
  {
    id: 6,
    title: 'Sustainability / Innovation',
    date: '10/5 at 11:30AM',
    description: 'Relumino, Programmable Picture Quality, Start-up Projects',
    picture: `${process.env.PUBLIC_URL}/images/MainSessionCard_6_A.svg`,
    pictureMo: `${process.env.PUBLIC_URL}/images/sdc23-MainSessionCard_6_mo.png`,
    sessionLink: 'sessions?filter=Sustainability / Innovation',
  },
];

export default MainSessionData;
