import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DetailContainer from '../../components/DetailContainer';
import DetailHero from '../../components/DetailHero';
import TechCont from '../../components/TechCont';
import {
  GET_SESSION_LIST_REQUEST,
  CLICK_FILTER_CLEAR,
} from '../../redux/ActionTypes';
import withRouter from '../../components/Router/withRouter';

const Tech = (props) => {
  const dispatch = useDispatch();
  const { navigate, searchParams } = props;
  const error = useSelector((state) => state.sessionList.error);

  useEffect(() => {
    dispatch({
      type: GET_SESSION_LIST_REQUEST,
      query: searchParams[0].getAll('filter'),
    });
    // componentWillUnmount
    return () => {
      dispatch({ type: CLICK_FILTER_CLEAR });
    };
  }, []);

  // error handling
  useEffect(() => {
    if (error && navigate) {
      navigate('/internal_server_error');
    }
  }, [error]);

  return (
    <>
      <DetailContainer>
        <DetailHero
          bgPc="sdc23-bg-tech.png"
          bgMo="sdc23-bg-tech-mo.png"
          title="Sessions"
          bgColor="#756BF1"
          depth="Session"
          depth2="Sessions"
          is2Depth
        />
        <TechCont />
      </DetailContainer>
    </>
  );
};

export default withRouter(Tech);
