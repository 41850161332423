import styled from 'styled-components';

export const ClabWrap = styled.div``;

export const ClabInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;

  @media screen and (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const DetailContainer = styled.div``;

export const SessionTopUtil = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 144px auto 35px;
  padding: 10px 0;

  .link-prev {
    position: relative;
    padding-left: 32px;
    font-family: 'samsungOne-700';
    font-size: 20px;
    line-height: 1.3;
    color: #000;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-previous.svg`});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 80px auto 12px;
    padding: 4px 0;

    .link-prev {
      display: flex;
      align-items: center;
      padding-left: 26px;
      font-size: 16px;
      line-height: 20px;
      &:before {
        content: '';
        top: 2px;
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const ClabCards = styled.div`
  padding: 100px 0 120px 0;
  background-color: #f6f6f6;
  @media screen and (max-width: 768px) {
    padding-bottom: 48px;
    padding-top: 48px;
  }
`;

export const ClabCardsInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const ClabCardsInnerCon = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -12px;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;
