import styled from 'styled-components';

export const TicketWrap = styled.div`
  background-image: url(${`${process.env.PUBLIC_URL}/images/commTicket-bg.png`});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 16px;

  @media screen and (max-width: 768px) {
    max-width: 264px;
    margin: 0 auto;
    border-radius: 8px;
  }
`;
export const TicketInner = styled.div`
  padding: 75px 120px 75px 120px;

  @media screen and (max-width: 768px) {
    padding: 16px 49px 16px;
  }
`;

export const TicketDetail = styled.div`
  @media screen and (max-width: 768px) {
    max-width: 168px;
    margin: 0 auto;
  }
`;

export const TicketImg = styled.div`
  position: relative;
  width: 100%;
  height: 284px;
  background-image: url(${`${process.env.PUBLIC_URL}/images/sdc23-commTicket.png`});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  .qrcode {
    display: inline-block;
    position: absolute;
    width: 34px;
    height: 34px;
    bottom: 13px;
    right: 12px;
    padding: 3px;
    background-color: #fff;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    height: 156px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/commTicket_mo.png`});
    background-size: cover;

    .qrcode {
      width: 20px;
      height: 20px;
      bottom: 8px;
      right: 8px;
      padding: 2px;
    }
  }
`;

export const TicketInfo = styled.div`
  background-color: #fff;
  padding: 20px 24px;
  text-align: left;

  .name {
    font-family: 'samsungsharpsans-bold';
    color: #000;
    font-size: 18px;
    line-height: 24px;
  }

  .info-detail {
    margin-top: 8px;

    li {
      font-family: 'samsungsharpsans-medium';
      color: #535353;
      font-size: 12px;
      line-height: 16px;
    }
    li + li {
      margin-top: 2px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 12px;

    .name {
      font-size: 12px;
      line-height: 16px;
    }

    .info-detail {
      margin-top: 6px;

      li {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
`;
