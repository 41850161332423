import React from 'react';
import { ContContainer } from './MainContentElements';
import MainIntro from '../MainIntro';
import MainIntro2 from '../MainIntro2';
import MainSlide from '../MainSlide';
import MainSessionContent from '../MainSession';
import MainSchedule from '../MainSchedule';
import NewsLetter from '../NewsLetter';
import MainEngagement from '../MainEngagement';
import MainBlog from '../MainBlog';
import MainCodeLab from '../MainCodeLab';
import MainIntro3 from '../MainIntro3';
import MainJoinus from '../MainJoinus';
import MainHighlights from '../MainHighlights';

const MainContent = () => {
  return (
    <ContContainer>
      {/* <MainIntro2 /> */}
      <MainIntro3 />
      {/* <MainSlide />
      <MainSessionContent />
      <MainSchedule />
      <MainBlog />
      <MainEngagement />
      <MainCodeLab /> */}
      <MainJoinus />
      <MainHighlights />
      <NewsLetter />
    </ContContainer>
  );
};

export default MainContent;
