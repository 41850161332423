import styled from 'styled-components';

export const TechContainer = styled.div`
  position: relative;
  padding: 100px 0 120px 0;
  background-color: #f6f6f6;
  @media screen and (max-width: 1024px) {
    padding: 40px 0 76px 0;
  }
`;

export const TechInner = styled.div`
  display: flex;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    display: block;
    padding: 0 24px;
  }
`;

export const CardWrap = styled.div``;

export const CardInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -12px;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;
