// Event > samsung-clab Page
export const EventSamsungClabData = [
  {
    title: 'Dr.Tail',
    subtitle: 'AI-Powered Veterinary Telehealth Platform',
    desc: "Today's veterinary landscape faces challenges in the form of limited vet availability and long wait times, leading pet owners to costly emergency rooms. Dr.Tail's online consultation platform aims to revolutionize pet care. It integrates health records with vet expertise for fast, accurate, and affordable consultations. Our vision extends beyond immediate solutions to a future of proactive and informed pet care.",
    img: `${process.env.PUBLIC_URL}/images/EventDetail/CL1_Dr_1.png`,
    link: 'https://drtail.us/',
  },
  {
    title: 'TAGHIVE',
    subtitle: 'Smart Classroom Engagement Platform',
    desc: "Class Saathi helps teachers to instantly assess each student's understanding in real time, without causing fear of judgment. This platform lets teachers ask questions while students respond using handheld clickers. This not only engages learners but also provides real-time analytics that are readily available to parents and school administrators.",
    img: `${process.env.PUBLIC_URL}/images/EventDetail/CL2_TAGHIVE_1_A.png`,
    link: 'https://tag-hive.com/',
  },
  {
    title: 'WELT',
    subtitle: 'Bridging Tech and Healthcare: Digital Therapeutics',
    desc: 'WELT, a Samsung spin-off, builds digital products with therapeutic efficacy, validated in clinical trials and approved by FDA for prescription and consumer use. For better health outcomes, we personalize treatment by leveraging sensor, patient-generated, and real-world data.',
    slide: true,
    video: false,
    images: [
      `${process.env.PUBLIC_URL}/images/EventDetail/CL3_WELT_1.png`,
      // process.env.PUBLIC_URL + '/images/EventDetail/Thumb-MS3-1.png',
      // process.env.PUBLIC_URL + '/images/EventDetail/Thumb-MS3-2.png',
    ],
    videoUrl: 'https://www.youtube.com/embed/M-P1TTWdwP8',
    link: 'https://www.weltcorp.com/',
  },
  {
    title: 'DATUMO',
    subtitle: 'All-in-One Data Platform',
    desc: 'Founded in 2018 under its Korean trademark SelectStar, Datumo has become one of the leading AI training data companies in Korea. Datumo provides an all-in-one data platform for AI models. From collecting and labeling data to analyzing and executing AI models, Datumo is a one-stop service.',
    slide: true,
    // video: true,
    images: [
      `${process.env.PUBLIC_URL}/images/EventDetail/CL4_DATUMO-1.png`,
      // process.env.PUBLIC_URL + '/images/EventDetail/Thumb-MobileEx-3.png',
      // process.env.PUBLIC_URL + '/images/EventDetail/Thumb-MobileEx-1.png',
    ],
    // videoUrl: 'https://www.youtube.com/embed/M-P1TTWdwP8',
    link: 'https://datumo.com/',
  },
  {
    title: 'VESSL AI',
    subtitle:
      'End-to-End MLOps Platform for Building AI Models Faster at Scale',
    desc: 'VESSL AI enables high-performance AI teams to scale their machine learning initiatives without additional engineering overhead. VESSL AI provides a fully managed AI infrastructure critical to training, fine-tuning, and deploying enterprise-scale machine learning models like Large Language Models and Generative AI.',
    slide: true,
    images: [
      `${process.env.PUBLIC_URL}/images/EventDetail/CL5_VESSL_AI_1.png`,
      `${process.env.PUBLIC_URL}/images/EventDetail/CL5_VESSL_AI_2.png`,
      `${process.env.PUBLIC_URL}/images/EventDetail/CL5_VESSL_AI_3.png`,
    ],
    link: 'https://vessl.ai/',
  },
  {
    title: 'CLIKA',
    subtitle:
      "Make AI Fast, Small and Affordable with CLIKA's Auto TinyAI Solution",
    desc: "CLIKA connects AI software to various hardware made available to us. CLIKA's AI compression technique, developed in-house, dramatically reduces the size of AI models while preserving their performance. This helps companies to quickly and reliably commercialize their AI solutions on devices used in our daily lives.",
    slide: true,
    video: true,
    images: [
      `${process.env.PUBLIC_URL}/images/EventDetail/CL6-CLIKA-1.png`,
      `${process.env.PUBLIC_URL}/images/EventDetail/CL6-CLIKA-1.png`,
      // process.env.PUBLIC_URL + '/images/EventDetail/Thumb-MobileEx-2.png',
      // process.env.PUBLIC_URL + '/images/EventDetail/Thumb-MobileEx-1.png',
    ],
    videoUrl: 'https://www.youtube.com/embed/xesWrPyTz4Q?si=MibUqMLjoHgPK7g0',
    link: 'https://www.clika.io/',
  },
];
